import { GeneratePublicToken } from "./GeneratePublicToken";
import axios from "axios";
import Cookies from "js-cookie";

const token = () => Cookies.get("accessToken");

export const getRole = async () => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];
  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ACCOUNT}role`,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

// ======================= fetch Title ======================= //

export const getTitle = async () => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];
  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ACCOUNT}title`,
    };
    const res = await axios(config);
    return await res.data.detail;
  } catch (err) {
    throw err;
  }
};

// ====================== Check Email Status ====================== //
export const CheckEmailStatus = async () => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const body = JSON.stringify({
      email: "",
      is_use_otp: false,
    });

    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API}pos/user/exist`,
      method: "POST",
      data: body,
    };

    const response = await axios(config);
  } catch (error) {}
};

// ====================== fetch Product Details ====================== //
export const fetchProducts = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/inventories?offset=1&limit=12&status=1`,
      method: "GET",
    };

    const response = await axios(config);
    return await response.data.detail;
  } catch (error) {}
};

// ====================== fetch filtering inventory ====================== //
export const fetchPrincipal = async () => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/principal`,
      method: "GET",
    };
    let response = await axios(config);
    return await response.data.detail;
  } catch (error) {}
};

export const fetchKategori = async (limit, offset) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },

      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}category?limit=${limit}&offset=${offset}`,
    };

    const res = await axios(config);

    return res.data.detail;
  } catch (error) {}
};

export const fetchGolongan = async () => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];
  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/label`,
      method: "GET",
    };
    let response = await axios(config);
    return await response.data.detail;
  } catch (error) {}
};

export const fetchUnit = async () => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];
  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}unit/product`,
      method: "GET",
    };
    let response = await axios(config);
    return await response.data.detail;
  } catch (error) {}
};
// ====================== get platform inventory ====================== //

export const fetchPlatform = async () => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];
  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product/platform`,
      method: "GET",
    };
    let response = await axios(config);
    return await response.data.detail;
  } catch (error) {}
};
