import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { IoIosAddCircleOutline } from "react-icons/io";
import AddProductInvoice from "./AddProductInvoice";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getCustomerDetail,
  getCustomerList,
  getPPNStatus,
  setNewInvoice,
} from "../../../services/invoiceApi";
import { useFormik } from "formik";
import moment from "moment";
import * as yup from "yup";

import InvoiceBreadcrumb from "./InvoiceBreadcrumb";
import { getUserProfile, getSipaAndFullname } from "../../../services/authApi";
//import { IGNORE_TYPE } from "../../constants/constant";

const FETCH_LIMIT = 10;
const offset = 1;

const validationSchema = yup.object({
  invoice_date: yup.string(),
  due_date: yup.string(),
  invoice_number: yup.string(),
  product: yup.array().min(1),
  customer_id: yup.number().min(1).required(),
  sales_code: yup.string(),
});

const CreateInvoice = () => {
  const navigate = useNavigate();

  const [isAddProduct, setIsAddProduct] = useState(false);
  const [productArr, setProductArr] = useState([]);
  const [searchCustomer, setSearchCustomer] = useState("");
  const [openCustomer, setOpenCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [grandPriceArr, setGrandPriceArr] = useState([]);

  const totalPrice = (qty, price) => {
    return qty * price;
  };

  const totalDisc = (onUs, offUs, disc) => {
    return onUs + offUs + disc + "%";
  };

  const { data: profile } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getUserProfile(),
  });

  const { data: sipaAndFullnameData } = useQuery({
    queryKey: ["sipa-fullname", profile?.id],
    queryFn: () =>
      getSipaAndFullname(profile && profile !== undefined ? profile.id : 0),
  });

  const generateRandomString = () => {
    const length = 8;
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const formik = useFormik({
    initialValues: {
      invoice_date: moment().format("YYYY-MM-DD"),
      due_date: moment().add(30, "d").format("YYYY-MM-DD"),
      invoice_number:
        "INV/" + moment().format("YYYY") + "/" + generateRandomString(),
      product: [],
      customer_id: 0,
      sales_code: "",
    },
    validationSchema,
    onSubmit: (values) => {
      mutateNewInvoice(values);
      // console.log({
      //   ...values,
      //   product: values.product.map((item) => {
      //     if (item.type_packaging === 1) {
      //       if (IGNORE_TYPE.includes(item.product_units)) {
      //         return {
      //           product_id: item.product_id,
      //           quantity: item.quantity * item.product_units?.terniary_total,
      //           price: item.price,
      //         };
      //       } else {
      //         return {
      //           product_id: item.product_id,
      //           quantity:
      //             item.quantity *
      //             item.product_units?.terniary_total *
      //             item.products_units?.secondary_total,
      //           price: item.price,
      //         };
      //       }
      //     } else if (item.type_packaging === 2) {
      //       if (IGNORE_TYPE.includes(item.product_units)) {
      //         return {
      //           product_id: item.product_id,
      //           quantity: item.quantity,
      //           price: item.price,
      //         };
      //       } else {
      //         return {
      //           product_id: item.product_id,
      //           quantity: item.quantity * item.product_units?.terniary_total,
      //           price: item.price,
      //         };
      //       }
      //     }
      //     return {
      //       product_id: item.product_id,
      //       quantity: item.quantity,
      //       price: item.price,
      //     };
      //   }),
      // });
    },
  });

  const { mutate: mutateNewInvoice, isLoading: loadNewInvoice } = useMutation({
    mutationFn: setNewInvoice,
    onSuccess: () => navigate("/invoice/invoice"),
  });

  const { data: dataCustomer, isFetching } = useQuery({
    queryKey: ["customer-list", offset, searchCustomer],
    queryFn: () => getCustomerList(FETCH_LIMIT, 1, searchCustomer),
  });

  const { data: dataDiscountCustomer } = useQuery({
    queryKey: ["customer-detail", selectedCustomer],
    enabled: selectedCustomer !== undefined && selectedCustomer !== null,
    queryFn: () => getCustomerDetail(selectedCustomer.partner_id),
  });

  const { data: dataPpn } = useQuery({
    queryFn: getPPNStatus,
  });

  useEffect(() => {
    var discCustomer = dataDiscountCustomer?.discount
      ? dataDiscountCustomer.discount
      : 0;

    var temp = productArr.map(
      (item) =>
        item.quantity *
        Math.floor(
          item.price * (1 - (item.on_us + item.off_us + discCustomer) / 100)
        )
    );
    setGrandPriceArr(temp);

    var productTemp = [];
    productArr.map((item) =>
      productTemp.push({
        product_id: item.product_id,
        quantity: item.quantity,
        price: item.price,
        type_uom: item.type_uom,
      })
    );
    formik.setFieldValue("product", productTemp);
  }, [productArr, dataDiscountCustomer]);

  return (
    <div>
      <InvoiceBreadcrumb isProduct={isAddProduct} />
      {!isAddProduct ? (
        <form onSubmit={formik.handleSubmit}>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">
              Invoice Date <span className="text-red-500">*</span>
            </p>
            <div className="w-full">
              <DesktopDatePicker
                className="w-full"
                sx={{ width: "100%" }}
                value={formik.values.invoice_date}
                onChange={(date) =>
                  formik.setFieldValue(
                    "invoice_date",
                    moment(date).format("YYYY-MM-DD")
                  )
                }
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">
              Batas Pembayaran <span className="text-red-500">*</span>
            </p>
            <div className="w-full">
              <DesktopDatePicker
                className="w-full"
                sx={{ width: "100%" }}
                value={formik.values.due_date}
                minDate={moment(formik.values.invoice_date).add(1, "d")}
                onChange={(date) =>
                  formik.setFieldValue(
                    "due_date",
                    moment(date).format("YYYY-MM-DD")
                  )
                }
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">
              Invoice Number <span className="text-red-500">*</span>
            </p>
            <div className="w-full">
              <TextField
                fullWidth
                disabled
                value={formik.values.invoice_number}
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">
              Customer <span className="text-red-500">*</span>
            </p>
            <div className="w-full">
              <Autocomplete
                fullWidth
                loading={isFetching}
                open={openCustomer}
                onOpen={() => setOpenCustomer(true)}
                onClose={() => setOpenCustomer(false)}
                disablePortal
                options={
                  dataCustomer && dataCustomer.data !== null
                    ? dataCustomer.data
                    : []
                }
                value={selectedCustomer}
                onChange={(_, inputValue) => {
                  setSelectedCustomer(inputValue);
                  formik.setFieldValue(
                    "customer_id",
                    inputValue === null ? 0 : inputValue.partner_id
                  );
                }}
                onInputChange={(_, onInputValue, reason) => {
                  if (reason === "input") {
                    setSearchCustomer(onInputValue);
                  }
                }}
                getOptionLabel={(option) => option.partner_name || ""}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.partner_id}>
                      {option.partner_name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isFetching ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">Customer Discount</p>
            <div className="w-full">
              <TextField
                fullWidth
                disabled
                value={
                  dataDiscountCustomer && selectedCustomer
                    ? dataDiscountCustomer.discount
                    : 0
                }
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">Kode Sales</p>
            <div className="w-full">
              <TextField
                fullWidth
                value={formik.values.sales_code}
                onChange={(e) =>
                  formik.setFieldValue("sales_code", e.target.value)
                }
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">Apoteker</p>
            <div className="w-full">
              <TextField
                fullWidth
                disabled
                value={
                  sipaAndFullnameData && sipaAndFullnameData !== undefined
                    ? sipaAndFullnameData.fullname
                    : ""
                }
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">No. SIPA</p>
            <div className="w-full">
              <TextField
                fullWidth
                disabled
                value={
                  sipaAndFullnameData && sipaAndFullnameData !== undefined
                    ? sipaAndFullnameData.sipa_id
                    : ""
                }
              />
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsAddProduct(true)}
              className="flex py-2 px-5 items-center border-2 border-black rounded-md cursor-pointer hover:bg-slate-100"
            >
              <IoIosAddCircleOutline className="mr-2 text-black" />
              Add Product
            </button>
          </div>

          <hr className="my-4" />

          <div className="block overflow-x-auto">
            <table className="w-full">
              <thead className="text-center bg-gray-100">
                <tr>
                  <th className="border font-bold">Product ID</th>
                  <th className="border font-bold">Nama Produk</th>
                  <th className="border font-bold">Qty</th>
                  <th className="border font-bold">No. Batch</th>
                  <th className="border font-bold">Expired</th>
                  <th className="border font-bold">Harga Satuan</th>
                  <th className="border font-bold">Diskon</th>
                  <th className="border font-bold">Promo</th>
                  <th className="border font-bold">Total</th>
                  <th className="border font-bold">Total + Diskon</th>
                </tr>
              </thead>

              <tbody>
                {productArr && productArr.length > 0 ? (
                  productArr.map((item, i) => {
                    return (
                      <tr className="text-center" key={i}>
                        <td className="border py-2">{item.product_id}</td>
                        <td className="border py-2">{item.product_name}</td>
                        {item.product_units !== null ? (
                          <td className="border py-2">
                            {item.type_uom === 0 && (
                              <p>
                                {item.quantity +
                                  " " +
                                  item.product_units.primary_type}
                              </p>
                            )}
                            {item.type_uom === 1 && (
                              <p>
                                {item.quantity +
                                  " " +
                                  item.product_units.secondary_type}
                              </p>
                            )}
                          </td>
                        ) : null}
                        <td className="border py-2">{item.batch_number}</td>
                        <td className="border py-2">{item.expired_date}</td>
                        <td className="border py-2">
                          {item.price.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </td>
                        <td className="border py-2">
                          {totalDisc(
                            item.on_us,
                            item.off_us,
                            dataDiscountCustomer !== undefined
                              ? dataDiscountCustomer.discount
                              : 0
                          )}
                        </td>
                        <td className="border py-2">
                          {item.promo === "" ? "-" : item.promo}
                        </td>
                        <td className="border py-2">
                          {totalPrice(item.quantity, item.price).toLocaleString(
                            "id-ID",
                            {
                              style: "currency",
                              currency: "IDR",
                            }
                          )}
                        </td>
                        <td className="border py-2">
                          {grandPriceArr[i]
                            ? Math.floor(grandPriceArr[i]).toLocaleString(
                                "id-ID",
                                {
                                  style: "currency",
                                  currency: "IDR",
                                }
                              )
                            : 0}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="border text-center py-4 text-gray-300"
                    >
                      Belum ada produk ditambahkan untuk invoice ini
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between mt-4">
            <div className="basis-2/3 w-full"></div>
            <div className="basis-1/3 w-full">
              <div className="flex">
                <p className="w-full font-bold">Jumlah</p>
                <p className="text-right">
                  {productArr.length > 0
                    ? productArr
                        .reduce((acc, num) => acc + num.quantity * num.price, 0)
                        .toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })
                    : 0}
                </p>
              </div>
              <div className="flex">
                <p className="w-full font-bold">Diskon</p>
                <p className="text-right">
                  {Math.floor(
                    (productArr.length > 0
                      ? productArr.reduce(
                          (acc, num) => acc + num.quantity * num.price,
                          0
                        )
                      : 0) -
                      (grandPriceArr.length > 0
                        ? grandPriceArr.reduce((acc, num) => acc + num, 0)
                        : 0)
                  ).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
              <div className="flex">
                <p className="w-full font-bold">Subtotal</p>
                <p className="text-right">
                  {grandPriceArr.length > 0
                    ? grandPriceArr
                        .reduce((acc, num) => acc + num, 0)
                        .toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })
                    : 0}
                </p>
              </div>
              <div className="flex">
                <p className="w-full font-bold">PPN</p>
                <p className="text-right">
                  {grandPriceArr.length > 0
                    ? Math.ceil(
                        grandPriceArr.reduce((acc, num) => acc + num, 0) *
                          (dataPpn && dataPpn.ppn_active ? 0.11 : 0)
                      ).toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })
                    : 0}
                </p>
              </div>

              <div className="flex mt-4 bg-green-100 rounded-md p-2">
                <p className="w-full font-bold">Jumlah Bayar</p>
                <p className="text-right">
                  {(
                    (dataPpn && dataPpn.ppn_active
                      ? Math.ceil(
                          grandPriceArr.length > 0
                            ? grandPriceArr.reduce((acc, num) => acc + num, 0) *
                                0.11
                            : 0
                        )
                      : 0) +
                    (grandPriceArr.length > 0
                      ? grandPriceArr.reduce((acc, num) => acc + num, 0)
                      : 0)
                  ).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 flex gap-2">
            <Button
              variant="outlined"
              fullWidth
              onClick={() => navigate("/invoice/invoice")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={!formik.isValid || loadNewInvoice}
            >
              {loadNewInvoice ? <CircularProgress size={20} /> : "Save"}
            </Button>
          </div>
        </form>
      ) : (
        <AddProductInvoice
          setIsAddProduct={setIsAddProduct}
          productArr={productArr}
          setProductArr={setProductArr}
        />
      )}
    </div>
  );
};

export default CreateInvoice;
