import Cookies from "js-cookie";
import axios from "axios";

const token = () => Cookies.get("accessToken");

export const getCsvTemplate = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}product/get/csv`,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getExistingCsvData = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}product/download/csv`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const createCsv = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product/create/csv`,
      data: formData,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const updateCsv = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product/csv/new-update`,
      data: {
        excel_inventory: file,
      },
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getMultipleOutletOption = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}product/outlet-inventory`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const fetchExistingCsvDataMultiple = async (company_id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product/csv/get-template-for-update`,
      data: company_id,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getXlTemplate = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}product/csv/get-template`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const createXl = async (file) => {
  try {
    const formData = new FormData();
    formData.append("excel_inventory", file);

    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product/csv/new-import`,
      data: formData,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getXlsxData = async (company_id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product/csv/get-template-for-update`,
      data: company_id,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};
