import { useState, useMemo, useRef, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

import { fetchReturList, getReturPdf } from "../../../../services/billingAPI";
import ReturPrintPdf from "./ReturPrintPdf";
import useDebounce from "../../../hooks/useDebounce";

const FETCH_LIMIT = 10;
const toDate = (date) => moment(date).format("DD-MMM-YYYY");
const toApiDate = (date) => moment(date).format("YYYY-MM-DD");

const ReturProduct = () => {
  const navigate = useNavigate();
  const printRef = useRef();
  const [offset, setOffset] = useState(1);
  const [resBody, setResBody] = useState({
    offset: 1,
    limit: FETCH_LIMIT,
    search: "",
    start_date: "",
    end_date: "",
    period: 0,
  });
  const [noteNumber, setNoteNumber] = useState(0);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [periodBuffer, setPeriodBuffer] = useState(0);

  const {
    mutate: mutateFetchReturList,
    data: returListData,
    isLoading: isReturListLoading,
  } = useMutation({
    mutationFn: fetchReturList,
  });

  const debounce = useDebounce(resBody.search, 500);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setNoteNumber(0),
  });

  const { data: returPdfData } = useQuery({
    queryKey: ["retur-pdf", noteNumber],
    queryFn: () => getReturPdf(noteNumber),
    enabled: noteNumber !== 0,
  });

  const pageCount = useMemo(() => {
    if (returListData && returListData !== undefined) {
      return Math.ceil(returListData.count / FETCH_LIMIT);
    } else return 0;
  }, [returListData]);

  useEffect(() => {
    mutateFetchReturList({
      ...resBody,
      search: debounce,
    });
  }, [debounce, fetchReturList, resBody.period]);

  return (
    <div>
      <div className="flex w-full">
        <TextField
          fullWidth
          value={resBody.search}
          label="search"
          onChange={(e) =>
            setResBody((prevValue) => ({
              ...prevValue,
              search: e.target.value,
            }))
          }
        />
        <FormControl sx={{ marginX: 3, width: "50%" }}>
          <InputLabel>Period</InputLabel>
          <Select
            value={periodBuffer}
            label="Period"
            onChange={(e) => {
              setPeriodBuffer(e.target.value);
              if (e.target.value === 2) {
                setResBody((prevValue) => ({
                  ...prevValue,
                  start_date: "",
                  end_date: "",
                }));
                setIsDateModalOpen(true);
                return;
              }
              setResBody((prevValue) => ({
                ...prevValue,
                period: e.target.value,
              }));
            }}
          >
            <MenuItem value={0}>Terbaru</MenuItem>
            <MenuItem value={1}>Terlama</MenuItem>
            <MenuItem value={2}>Pilih Tanggal</MenuItem>
            <MenuItem value={3} disabled sx={{ display: "none" }}>
              {toDate(resBody.start_date) + " - " + toDate(resBody.end_date)}
            </MenuItem>
          </Select>
        </FormControl>
        <button
          className="px-3 py-3 rounded-md bg-[#007AF1] text-white font-bold mx-3 disabled:bg-gray-200 disabled:text-black w-[20%]"
          disabled={noteNumber === 0}
          onClick={handlePrint}
        >
          Print Retur
        </button>
        <button
          className="px-3 py-3 rounded-md bg-[#007AF1] text-white font-bold w-[20%]"
          onClick={() => navigate("/billing/create-retur")}
        >
          Create Retur
        </button>
      </div>
      {returListData &&
      returListData !== undefined &&
      returListData.data !== null ? (
        <div>
          {isReturListLoading ? (
            <div className="flex justify-center mt-3">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper} className="mt-3">
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#007AF1" }}>
                    {/*  <TableCell
                                            align="center"
                                            sx={{ color: "white", fontWeight: "bold" }}
                                          ></TableCell> */}
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Nomor Nota
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Nama Distributor
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Total Harga
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Tanggal Retur
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Print
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {returListData.data && returListData.data !== undefined
                    ? returListData.data?.map((data, index) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={index}
                        >
                          {/* <TableCell component="th" scope="col" align="center">
                            <Checkbox />
                          </TableCell> */}
                          <TableCell
                            align="center"
                            onClick={() =>
                              navigate(
                                `/billing/detail-retur/${data.note_number}`
                              )
                            }
                          >
                            <p className="cursor-pointer hover:underline text-blue-500">
                              {data.note_number}
                            </p>
                          </TableCell>
                          <TableCell align="center">
                            {data.distributor_name}
                          </TableCell>
                          <TableCell align="center">
                            {data.total_price.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </TableCell>
                          <TableCell align="center">
                            {toDate(data.created_at)}
                          </TableCell>
                          <TableCell align="center">
                            <Checkbox
                              checked={noteNumber === data.note_number}
                              onChange={() => {
                                if (noteNumber === data.note_number) {
                                  setNoteNumber(0);
                                } else {
                                  setNoteNumber(data.note_number);
                                }
                              }}
                            />
                            {/*<button
                            className="text-white bg-[#007AF1] p-3 rounded-md"
                            onClick={() => {
                              setNoteNumber(data.note_number);
                            }}
                          >
                            <IoPrintSharp className="text-[25px]" />
                          </button> */}{" "}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {isReturListLoading ? null : (
            <div className="flex justify-center mt-[10px]">
              <Pagination
                count={pageCount}
                page={offset}
                onChange={(_, value) => setOffset(value)}
                shape="rounded"
                size="large"
                color="primary"
              />
            </div>
          )}
        </div>
      ) : null}
      <div className="hidden">
        <ReturPrintPdf
          returPdfData={returPdfData}
          toDate={toDate}
          printRef={printRef}
        />
      </div>
      <Dialog
        open={isDateModalOpen}
        onClose={() => {
          setIsDateModalOpen(false);
        }}
      >
        <DialogTitle>Filter</DialogTitle>
        <DialogContent>
          <DatePicker
            className="border-2 border rounded-md p-2"
            selectsRange={true}
            startDate={resBody.start_date}
            endDate={resBody.end_date}
            onChange={(dates) => {
              const [start, end] = dates;
              setResBody((prevValue) => ({
                ...prevValue,
                start_date: start,
                end_date: end,
                //period: 2,
              }));
            }}
            inline
          />
          <button
            className="bg-red-500 text-white rounded-md p-2"
            onClick={() => {
              mutateFetchReturList({
                ...resBody,
                start_date: toApiDate(resBody.start_date),
                end_date: toApiDate(resBody.end_date),
                period: 2,
              });
              setIsDateModalOpen(false);
              setResBody((prevValue) => ({
                ...prevValue,
                start_date: toApiDate(prevValue.start_date),
                end_date: toApiDate(prevValue.end_date),
                period: 2,
              }));
              setPeriodBuffer(3);
            }}
          >
            OK
          </button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReturProduct;
