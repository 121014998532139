import { useNavigate } from "react-router-dom";

const InventoryNavigation = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full justify-between border-2 mt-3 text-sm lg:text-base grid text-center grid-cols-5 divide-x">
      <button
        onClick={() => navigate("/inventory/produk-aktif")}
        className={
          window.location.pathname === "/inventory/produk-aktif"
            ? "py-2 bg-blue-500 text-white"
            : "py-2"
        }
      >
        Produk Aktif
      </button>
      <button
        onClick={() => navigate("/inventory/produk-habis")}
        className={
          window.location.pathname === "/inventory/produk-habis"
            ? "py-2 bg-blue-500 text-white"
            : "py-2"
        }
      >
        Habis
      </button>
      <button
        onClick={() => navigate("/inventory/produk-hold")}
        className={
          window.location.pathname === "/inventory/produk-hold"
            ? "py-2 bg-blue-500 text-white"
            : "py-2"
        }
      >
        Hold
      </button>
      <button
        onClick={() => navigate("/inventory/produk-expired")}
        className={
          window.location.pathname === "/inventory/produk-expired"
            ? "py-2 bg-blue-500 text-white"
            : "py-2"
        }
      >
        Expired
      </button>
      <button
        className={
          window.location.pathname === "/inventory/produk-inactive"
            ? "py-2 bg-blue-500 text-white"
            : "py-2"
        }
        onClick={() => navigate("/inventory/produk-inactive")}
      >
        Tidak Aktif
      </button>
    </div>
  );
};

export default InventoryNavigation;
