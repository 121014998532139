import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";
import moment from "moment";

import { IGNORE_TYPE } from "../../../constants/constant";

const toDate = (date) => moment(date).format("DD/MM/YYYY");

const CardStockDetail = (props) => {
  const { cardStockDetail } = props;

  return (
    <div>
      {cardStockDetail && cardStockDetail !== undefined ? (
        <div>
          <div className="flex justify-between w-full mb-3">
            <div className="w-full">
              <p>Product ID</p>
              <p>{cardStockDetail[0].product_id}</p>
              <div className="w-full border border-black"></div>
            </div>
            <div className="w-full">
              <p>Nama Produk</p>
              <p>{cardStockDetail[0].product_name}</p>
              <div className="w-full border border-black"></div>
            </div>
          </div>
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#007AF1",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Tanggal
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Stock
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Masuk
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Keluar
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Stock Akhir
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    UOM
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Harga HNA
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Discount
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    PPN
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Harga Akhir
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Harga Jual
                  </TableCell>

                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    User Log
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cardStockDetail.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">
                      {toDate(data.created_at)}
                    </TableCell>
                    <TableCell align="center">{data.stock_current}</TableCell>
                    <TableCell align="center">{data.stock_in}</TableCell>
                    <TableCell align="center">{data.stock_out}</TableCell>
                    <TableCell align="center">
                      {data.stock_in === 0
                        ? data.stock_current - data.stock_out
                        : data.stock_current + data.stock_in}
                    </TableCell>
                    {data.package_detail !== null ? (
                      <TableCell align="center">
                        {IGNORE_TYPE.includes(data.package_detail.type_unit) ? (
                          <p>{data.package_detail.type_strip}</p>
                        ) : (
                          <p>{data.package_detail.type_unit}</p>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell align="center">Rp. 0</TableCell>
                    )}
                    <TableCell align="center">
                      {data.pharmacy_net_price.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {data.pharmacy_net_price_discount}%
                    </TableCell>
                    <TableCell align="center">
                      {data.pharmacy_net_price_ppn ? "Yes" : "No"}
                    </TableCell>
                    <TableCell align="center">
                      {data.pharmacy_net_price_real.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </TableCell>
                    <TableCell align="center">
                      Rp.
                      {data.sell_price.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </TableCell>
                    <TableCell align="center">{data.email}</TableCell>
                    <TableCell align="center">{data.created_by}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null}
    </div>
  );
};

export default CardStockDetail;
