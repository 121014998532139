import { useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import {
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Create as CreateIcon,
} from "@mui/icons-material";

import {
  updateDistributor,
  deleteDistributor,
} from "../../../services/purchaseOrderApi";
import Trash from "../../../assets/icons/trash.svg";

const PurchaseOrderDistributor = (props) => {
  const queryClient = useQueryClient();

  const { distributorListData, searchDist, setSearchDist } = props;

  const [distributor, setDistributor] = useState({
    modalOpen: false,
    isDeleteModal: false,
  });

  const [reqBody, setReqBody] = useState({
    id: 0,
    name: "",
    phone: "",
    address: "",
  });

  const { mutate: mutateUpdateDist } = useMutation({
    mutationFn: updateDistributor,
    onSuccess: () => {
      setDistributor((prevValue) => ({
        ...prevValue,
        modalOpen: false,
      }));
      queryClient.invalidateQueries({ queryKey: ["distributor-list"] });
    },
  });

  const { mutate: mutateDeleteDist } = useMutation({
    mutationFn: deleteDistributor,
    onSuccess: () => {
      setDistributor((prevValue) => ({
        ...prevValue,
        modalOpen: false,
      }));
      queryClient.invalidateQueries({ queryKey: ["distributor-list"] });
    },
  });

  return (
    <div>
      <TextField
        sx={{ width: "100%" }}
        value={searchDist}
        onChange={(e) => setSearchDist(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {distributorListData && distributorListData !== undefined
        ? distributorListData.map((data, index) => (
            <div
              key={index}
              className="w-full flex justify-between border border-black p-3  mt-3 cursor-pointer"
            >
              <div className="flex">
                <div>
                  <p className="font-bold">Nama</p>
                  <p>{data.name}</p>
                </div>
                <div className="mx-3">
                  <p className="font-bold">Nomor Handphone</p>
                  <p>{data.phone}</p>
                </div>
                <div>
                  <p className="font-bold">Alamat</p>
                  <p>{data.address}</p>
                </div>
              </div>
              <div className="flex">
                <button
                  className="bg-blue-500 p-2 text-white rounded-md mr-3"
                  onClick={() => {
                    setDistributor((prevValue) => ({
                      ...prevValue,
                      modalOpen: true,
                      isDeleteModal: false,
                    }));
                    setReqBody({
                      id: data.id,
                      name: data.name,
                      phone: data.phone,
                      address: data.address,
                    });
                  }}
                >
                  <CreateIcon />
                </button>
                <button
                  className="bg-red-500 p-2 text-white rounded-md"
                  onClick={() => {
                    setDistributor((prevValue) => ({
                      ...prevValue,
                      modalOpen: true,
                      isDeleteModal: true,
                    }));
                    setReqBody({
                      id: data.id,
                      name: data.name,
                      phone: data.phone,
                      address: data.address,
                    });
                  }}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ))
        : null}
      <Dialog
        fullWidth
        maxWidth="md"
        open={distributor.modalOpen}
        onClose={() =>
          setDistributor((prevValue) => ({
            ...prevValue,
            modalOpen: false,
          }))
        }
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {distributor.isDeleteModal ? (
            <div className="flex flex-row justify-between items-center">
              <span>
                <span className="animate-ping absolute"></span>
                <img src={Trash} alt="" className=" w-16 animate-pulse" />
              </span>
              <p className="text-gray-500 text-[20px] cursor-pointer">X</p>
            </div>
          ) : (
            "Edit Distributor"
          )}
        </DialogTitle>
        <DialogContent>
          {distributor.isDeleteModal ? (
            <div>
              <p className="font-bold text-[20px]">Hapus Distributor</p>
              <p className="text-start font-medium text-[20px]">
                Apakah anda yakin akan menghapus distributor ini?
              </p>
            </div>
          ) : (
            <div>
              <div>
                <p>Nama</p>
                <TextField
                  sx={{ width: "100%" }}
                  value={reqBody.name}
                  onChange={(e) =>
                    setReqBody((prevValue) => ({
                      ...prevValue,
                      name: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="my-3">
                <p>Nomor Handphone</p>
                <TextField
                  sx={{ width: "100%" }}
                  value={reqBody.phone}
                  onChange={(e) =>
                    setReqBody((prevValue) => ({
                      ...prevValue,
                      phone: e.target.value,
                    }))
                  }
                />
              </div>
              <div>
                <p>Alamat</p>
                <TextField
                  sx={{ width: "100%" }}
                  value={reqBody.address}
                  onChange={(e) =>
                    setReqBody((prevValue) => ({
                      ...prevValue,
                      address: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          )}
          <div className="flex mt-3">
            <button
              className="w-full rounded-md border border-black py-2"
              onClick={() =>
                setDistributor((prevValue) => ({
                  ...prevValue,
                  modalOpen: false,
                }))
              }
            >
              Batal
            </button>
            <button
              className={`w-full rounded-md border border-black py-2 ${
                distributor.isDeleteModal ? "bg-red-500" : "bg-blue-500"
              } text-white font-bold ml-2`}
              onClick={() => {
                if (distributor.isDeleteModal) {
                  mutateDeleteDist(reqBody.id);
                } else {
                  mutateUpdateDist(reqBody);
                }
              }}
            >
              {distributor.isDeleteModal ? "Oke" : "Simpan"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PurchaseOrderDistributor;
