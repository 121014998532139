import { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Select,
  MenuItem,
  InputAdornment,
  Menu,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment";
import { LuFilePlus2 } from "react-icons/lu";

import {
  getDetailCardStock,
  exportXlsxCardStock,
  fetchCardStockNew,
} from "../../../../services/InventoryAPI";

import { inventoryStatus } from "../../../constants/constant";
import useDebounce from "../../../hooks/useDebounce";
import CardStockDetail from "./CardStockDetail";
import { Search } from "@mui/icons-material";
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaSortAlphaDown,
} from "react-icons/fa";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";
import PagePagination from "../../../common/particles.jsx/PagePagination";

const toDate = (date) => moment(date).format("DD MMM YYYY");
const toApiDate = (date) => moment(date).format("YYYY-MM-DD");
const PERIOD = [
  { value: 1, name: "Minggu Berjalan" },
  { value: 2, name: "Minggu Lalu" },
  { value: 3, name: "Bulan Berjalan" },
  { value: 4, name: "Bulan Lalu" },
  { value: 5, name: "Tahun Berjalan" },
  { value: 6, name: "Tahun Lalu" },
  { value: 7, name: "Pilih Tanggal" },
];

const CardStockNew = () => {
  const navigate = useNavigate();
  const companyId = localStorage.getItem("company_id");
  const [productId, setProductId] = useState(0);
  const [relationalId, setRelationalId] = useState("");
  const [isDetailCardStockOpen, setIsDetailCardStockOpen] = useState(false);
  const [selectedStock, setSelectedStock] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [resBody, setResBody] = useState({
    product_name: "",
    start_date: "",
    end_date: "",
    period: 3,
    sort: 1,
    limit: 10,
    offset: 1,
  });
  const [anchorElSort, setAnchorElSort] = useState(null);

  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
    isOpen: false,
    period: 3,
  });

  const debounce = useDebounce(resBody.product_name, 500);

  const handleExport = () => {
    mutateXlsx({
      id: selectedStock,
      sort: resBody.period,
      start_date: resBody.start_date ? toApiDate(resBody.start_date) : "",
      end_date: resBody.end_date ? toApiDate(resBody.end_date) : "",
    });
  };

  const getPeriodName = () => {
    const period = PERIOD.find((item) => item.value === resBody.period);
    return period
      ? period.value === 7
        ? toDate(resBody.start_date) + " - " + toDate(resBody.end_date)
        : period.name
      : "";
  };

  const handleXlsxData = (xlsxData) => {
    const url = xlsxData;
    const a = document.createElement("a");
    a.download = "card-stock-history.xlsx";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const { mutate: mutateXlsx, isLoading: isLoadingXlsx } = useMutation({
    mutationFn: exportXlsxCardStock,
    onSuccess: (data) => handleXlsxData(data),
    onError: (err) =>
      setErrorMessage(err.message.id ? err.message.id : err.message),
  });

  const {
    mutate: mutateCardStockData,
    data: cardStockData,
    isLoading: isCardStockDataLoading,
  } = useMutation({
    mutationFn: fetchCardStockNew,
  });

  const { data: cardStockDetail, isLoading: isCardStockDetailLoading } =
    useQuery({
      queryKey: ["cardstock-history", productId, relationalId],
      queryFn: () => getDetailCardStock(productId, relationalId),
      enabled: productId > 0 && relationalId !== "",
    });

  useEffect(() => {
    mutateCardStockData({
      ...resBody,
      product_name: debounce,
      offset: resBody.offset,
      limit: resBody.limit,
      period: resBody.period,
    });
  }, [
    resBody.sort,
    resBody.period,
    resBody.offset,
    resBody.limit,
    debounce,
    mutateCardStockData,
  ]);

  return (
    <div>
      <LoadingBackdrop isLoading={isLoadingXlsx} />

      <div className="flex justify-between items-center mb-4">
        <div className="flex text-[#007AF1] font-medium">
          <p
            className="hover:underline hover:cursor-pointer"
            onClick={() => navigate("/inventory/produk-aktif")}
          >
            Inventory
          </p>
          <p className="mx-2">/</p>
          <p className="hover:underline hover:cursor-pointer">Kartu Stock</p>
        </div>
      </div>
      <div className="flex items-center gap-2 mb-2">
        <TextField
          fullWidth
          placeholder="Search"
          value={resBody.product_name}
          onChange={(e) =>
            setResBody((prevValue) => ({
              ...prevValue,
              product_name: e.target.value,
            }))
          }
          inputProps={{ style: { padding: 9 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer"
          aria-controls={Boolean(anchorElSort) ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorElSort) ? "true" : undefined}
          onClick={(e) => setAnchorElSort(e.currentTarget)}
        >
          <FaSortAlphaDown />
          <p className="whitespace-nowrap">{getPeriodName()}</p>
          <FaChevronDown />
        </div>
        <button
          className="bg-[#AFECC6]  w-fit p-2 rounded-md border-2 border-[#15803D] text-[#15803D] flex gap-8 items-center hover:bg-[#91c4a4] disabled:bg-gray-300 disabled:border-white disabled:text-white"
          onClick={handleExport}
          disabled={isLoadingXlsx || selectedStock.length === 0}
        >
          <p>Export</p>
          <div
            className={`p-1 border-md text-white rounded-md w-[24px] h-[24px] rounded-md ${
              isLoadingXlsx || selectedStock.length === 0
                ? "bg-gray-500"
                : " bg-[#15803D] "
            }`}
          >
            <LuFilePlus2 />
          </div>
        </button>
      </div>

      <div className="flex justify-between items-center mb-4">
        <FormControlLabel
          control={<Checkbox />}
          label="Pilih Semua Produk"
          disabled={isCardStockDataLoading || !cardStockData}
          checked={
            cardStockData && cardStockData.data
              ? selectedStock.length === cardStockData?.data.length
              : false
          }
          onChange={(e, checked) => {
            setSelectedStock(
              checked
                ? cardStockData && !isCardStockDataLoading && cardStockData.data
                  ? cardStockData.data.map((p) => p.product_id)
                  : []
                : []
            );
          }}
        />
        {!isCardStockDataLoading && cardStockData ? (
          <p className="text-gray-300">
            {cardStockData.count} produk ditemukan
          </p>
        ) : (
          <CircularProgress size={20} />
        )}
      </div>

      <TableContainer sx={{ width: "100%", marginBottom: 2 }}>
        <Table aria-label="simple table">
          <TableHead
            sx={{
              backgroundColor: "#007AF1",
            }}
          >
            <TableRow>
              <TableCell></TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                // colSpan={2}
                align="center"
              >
                Nama Produk
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Beginning Stock
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Stock In
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Stock Out
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Final Stock
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Status Stock
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Last Update
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isCardStockDataLoading &&
            cardStockData &&
            cardStockData.data &&
            cardStockData.data !== undefined ? (
              cardStockData.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    sx={{ border: 1, borderColor: "#000" }}
                  >
                    <Checkbox
                      checked={selectedStock.includes(data.product_id)}
                      onChange={() => {
                        const index = selectedStock.findIndex(
                          (stock) => stock === data.product_id
                        );
                        setSelectedStock(
                          index < 0
                            ? [...selectedStock, data.product_id]
                            : [
                                ...selectedStock.slice(0, index),
                                ...selectedStock.slice(index + 1),
                              ]
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#007AF1", border: 1, borderColor: "#000" }}
                  >
                    <p
                      className="hover:cursor-pointer hover:underline"
                      onClick={() => {
                        navigate(
                          `/inventory/card-stock-detail-new/${data.product_id}`
                        );
                      }}
                    >
                      {data.product_name}
                    </p>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: 1, borderColor: "#000" }}
                  >
                    {data.stock_current}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: 1, borderColor: "#000" }}
                  >
                    {data.stock_in}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: 1, borderColor: "#000" }}
                  >
                    {data.stock_out}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: 1, borderColor: "#000" }}
                  >
                    {data.final_stock}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: 1, borderColor: "#000" }}
                  >
                    {inventoryStatus[data.status]}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: 1, borderColor: "#000" }}
                  >
                    {toDate(data.created_at)}
                  </TableCell>
                </TableRow>
              ))
            ) : isCardStockDataLoading ? (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ border: 1, borderColor: "#000" }}
                  colSpan={8}
                >
                  <CircularProgress size={20} />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ border: 1, borderColor: "#000" }}
                  colSpan={8}
                >
                  Tidak ada produk
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!isCardStockDataLoading && cardStockData && cardStockData.count > 0 ? (
        <PagePagination
          offset={resBody.offset}
          setOffset={(e) => {
            setResBody((prevValue) => ({
              ...prevValue,
              offset: e,
            }));
          }}
          limit={resBody.limit}
          setLimit={(e) => {
            setResBody((prevValue) => ({
              ...prevValue,
              limit: e,
            }));
          }}
          total={cardStockData.count}
        />
      ) : null}

      <Dialog
        open={isDetailCardStockOpen}
        onClose={() => setIsDetailCardStockOpen(false)}
        maxWidth="lg"
      >
        <DialogContent>
          <CardStockDetail cardStockDetail={cardStockDetail} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={date.isOpen}
        onClose={() => {
          setDate((prevValue) => ({
            ...prevValue,
            isOpen: false,
            startDate: null,
            endDate: null,
          }));
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              onChange={(dates) => {
                const [start, end] = dates;
                setDate((prevValue) => ({
                  ...prevValue,
                  startDate: start,
                  endDate: end,
                }));
              }}
              startDate={date.startDate}
              endDate={date.endDate}
              selectsRange={true}
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold disabled:bg-gray-300"
            disabled={!date.startDate || !date.endDate}
            onClick={() => {
              setDate((prevValue) => ({
                ...prevValue,
                isOpen: false,
                period: 8,
              }));
              setResBody((prevValue) => ({
                ...prevValue,
                period: 7,
                start_date: toApiDate(date.startDate),
                end_date: toApiDate(date.endDate),
              }));
              mutateCardStockData({
                ...resBody,
                start_date: toApiDate(date.startDate),
                end_date: toApiDate(date.endDate),
                period: 7,
              });
            }}
          >
            ok
          </button>
        </DialogContent>
      </Dialog>

      <Menu
        anchorEl={anchorElSort}
        open={Boolean(anchorElSort)}
        onClose={() => setAnchorElSort(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        onChange={(e) => {
          if (e.target.value === 7) {
            setDate((prevValue) => ({
              ...prevValue,
              period: e.target.value,
              isOpen: true,
            }));
            return;
          }
          setDate((prevValue) => ({
            ...prevValue,
            period: e.target.value,
          }));
          setResBody((prevValue) => ({
            ...prevValue,
            period: e.target.value,
          }));
        }}
      >
        {PERIOD.map((item) => (
          <MenuItem
            onClick={() => {
              setSelectedStock([]);
              if (item.value === 7) {
                setDate((prevValue) => ({
                  ...prevValue,
                  period: item.value,
                  isOpen: true,
                }));
                setAnchorElSort(null);
                return;
              }
              setDate((prevValue) => ({
                ...prevValue,
                period: item.value,
                startDate: null,
                endDate: null,
              }));
              setResBody((prevValue) => ({
                ...prevValue,
                period: item.value,
              }));
              setAnchorElSort(null);
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>

      <ErrorPopup
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default CardStockNew;
