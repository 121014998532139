import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import {
  getCustomerDetail,
  setCustomerDetail,
} from "../../../services/invoiceApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useState } from "react";
import InvoiceBreadcrumb from "./InvoiceBreadcrumb";

const validationSchema = yup.object({
  partner_name: yup.string().required("Nama customer harus diisi"),
  address: yup.string().required("Alamat customer harus diisi"),
  npwp: yup.string(),
  phone: yup.string().required("No. telepon customer harus diisi"),
  pic: yup.string().required("PIC customer harus diisi"),
  discount: yup.number(),
  // sia: yup.string().required("Nama apoteker harus diisi"),
  // sipa: yup.string().required("Nomor SIPA harus diisi"),
});

const CustomerDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState("");

  const { data, isFetching, error } = useQuery({
    queryKey: ["customer-detail", id],
    enabled: id !== null,
    refetchOnWindowFocus: false,
    queryFn: () => getCustomerDetail(id),
  });

  const formik = useFormik({
    initialValues: {
      partner_id: Number(id),
      partner_name: data ? data.partner_name : "",
      address: data ? data.address : "",
      npwp: data ? data.npwp : "",
      phone: data ? data.phone : "",
      pic: data ? data.pic : "",
      discount: data ? data.discount : 0,
      sia: "",
      sipa: "",
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateDetail(values);
    },
  });

  const { mutate: mutateDetail } = useMutation({
    mutationFn: setCustomerDetail,
    onSuccess: () => {
      navigate("/invoice/customer");
    },
    onError: (err) => setErrorMessage(err.id),
  });

  return (
    <>
      <InvoiceBreadcrumb />
      {data && !isFetching ? (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex gap-3 items-center my-4">
              <div className="w-full">
                <p className="font-bold mb-2">
                  Nama Customer <span className="text-red-500"> *</span>
                </p>
                <div className="w-full">
                  <TextField
                    fullWidth
                    helperText={
                      formik.touched.partner_name && formik.errors.partner_name
                    }
                    value={formik.values.partner_name}
                    onChange={(e) => {
                      if (e.target.value.length <= 50)
                        formik.setFieldValue("partner_name", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="w-full">
                <p className="font-bold mb-2">
                  PIC <span className="text-red-500"> *</span>
                </p>
                <div className="w-full">
                  <TextField
                    fullWidth
                    helperText={formik.touched.pic && formik.errors.pic}
                    value={formik.values.pic}
                    onChange={(e) => {
                      if (e.target.value.length <= 50)
                        formik.setFieldValue("pic", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-3 items-center mb-4">
              <div className="w-full">
                <p className="font-bold mb-2">NPWP</p>
                <div className="w-full">
                  <TextField
                    fullWidth
                    value={formik.values.npwp}
                    onChange={(e) => {
                      if (
                        e.target.value.length <= 20 &&
                        !isNaN(Number(e.target.value))
                      ) {
                        formik.setFieldValue("npwp", e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="w-full">
                <p className="font-bold mb-2">Diskon</p>
                <div className="w-full">
                  <TextField
                    fullWidth
                    value={formik.values.discount}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "discount",
                        !isNaN(Number(e.target.value)) &&
                          Number(e.target.value) <= 100
                          ? Number(e.target.value)
                          : 0,
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-3 items-center mb-4">
              <div className="w-full">
                <p className="font-bold mb-2">
                  No. Telepon <span className="text-red-500"> *</span>
                </p>
                <div className="w-full">
                  <TextField
                    fullWidth
                    helperText={formik.touched.phone && formik.errors.phone}
                    value={formik.values.phone}
                    onChange={(e) => {
                      if (
                        e.target.value.length <= 30 &&
                        !isNaN(Number(e.target.value))
                      )
                        formik.setFieldValue("phone", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="w-full">
                <p className="font-bold mb-2">
                  Alamat <span className="text-red-500"> *</span>
                </p>
                <div className="w-full">
                  <TextField
                    fullWidth
                    helperText={formik.touched.address && formik.errors.address}
                    value={formik.values.address}
                    onChange={(e) => {
                      if (e.target.value.length <= 100)
                        formik.setFieldValue("address", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-3 items-center mb-4">
              {/*

              <div className="w-full">
                <p className="font-bold mb-2">
                  Nama Apoteker <span className="text-red-500"> *</span>
                </p>
                <div className="w-full">
                  <TextField
                    fullWidth
                    helperText={formik.touched.sia && formik.errors.sia}
                    value={formik.values.sia}
                    onChange={(e) => {
                      if (e.target.value.length <= 200)
                        formik.setFieldValue("sia", e.target.value);
                    }}
                  />
                </div>
              </div>
              */}
              {/*
<div className="w-full">
                <p className="font-bold mb-2">
                  Nomor SIPA <span className="text-red-500"> *</span>
                </p>
                <div className="w-full">
                  <TextField
                    fullWidth
                    helperText={formik.touched.sipa && formik.errors.sipa}
                    value={formik.values.sipa}
                    onChange={(e) => {
                      if (
                        e.target.value.length <= 200 &&
                        !isNaN(Number(e.target.value))
                      )
                        formik.setFieldValue("sipa", e.target.value);
                    }}
                  />
                </div>
              </div>

              */}{" "}
            </div>

            <p className="text-red-500 text-center my-4">{errorMessage}</p>
            <div className="w-full flex justify-end">
              <div className="w-1/2 flex gap-2 pl-3">
                <button
                  className="w-full border-2 hover:bg-slate-100 rounded-md p-2"
                  onClick={() => navigate("/invoice/customer")}
                >
                  Cancel
                </button>
                <button
                  className="w-full bg-blue-500 hover:bg-blue-700 text-white rounded-md p-2 disabled:bg-gray-300"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </>
      ) : isFetching ? (
        <CircularProgress sx={{ marginTop: 4 }} />
      ) : (
        <p className="mt-8 text-red-500">{error?.id}</p>
      )}
    </>
  );
};

export default CustomerDetail;
