import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TextField,
  Dialog,
  DialogContent,
  Pagination,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import { AiOutlineExport } from "react-icons/ai";

import {
  getCardStock,
  getDetailCardStock,
  exportXlsxCardStock,
} from "../../../../services/InventoryAPI";

import { inventoryStatus } from "../../../constants/constant";
import useDebounce from "../../../hooks/useDebounce";
import CardStockDetail from "./CardStockDetail";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";

const toDate = (date) => moment(date).format("DD/MM/YY");
const FETCH_LIMIT = 10;

const CardStock = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(1);
  const [productName, setProductName] = useState("");
  const [productId, setProductId] = useState(0);
  const [relationalId, setRelationalId] = useState("");
  const [isDetailCardStockOpen, setIsDetailCardStockOpen] = useState(false);

  const debounce = useDebounce(productName, 500);

  const { data: cardStockData, isLoading: isCardStockDataLoading } = useQuery({
    queryKey: ["cardstock-history", offset, debounce],
    queryFn: () => getCardStock(FETCH_LIMIT, offset, debounce),
  });

  const { data: cardStockDetail, isLoading: isCardStockDetailLoading } =
    useQuery({
      queryKey: ["cardstock-history", productId, relationalId],
      queryFn: () => getDetailCardStock(productId, relationalId),
      enabled: productId > 0 && relationalId !== "",
    });

  const handleXlsxData = (xlsxData) => {
    const url = xlsxData;
    const a = document.createElement("a");
    a.download = "card-history.xlsx";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const {
    refetch: exportXlsxHistory,
    isFetching: isExportingXlsxHistoryLoading,
  } = useQuery({
    queryKey: ["xlsx-card-stock"],
    queryFn: () => exportXlsxCardStock(),
    enabled: false,
    onSuccess: (data) => handleXlsxData(data),
  });

  const pageCount = useMemo(() => {
    if (cardStockData && cardStockData !== undefined)
      return Math.ceil(cardStockData.count / FETCH_LIMIT);
    else return 0;
  }, [cardStockData]);

  return (
    <div>
      <LoadingBackdrop isLoading={isExportingXlsxHistoryLoading} />
      {isCardStockDataLoading ? (
        <div className="flex justify-center h-screen w-full items-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <div className="flex text-[#007AF1] font-medium">
              <p
                className="hover:underline hover:cursor-pointer"
                onClick={() => navigate("/inventory/produk-aktif")}
              >
                Inventory
              </p>
              <p className="mx-2">/</p>
              <p className="hover:underline hover:cursor-pointer">
                Kartu Stock
              </p>
            </div>
          </div>
          <div className="flex my-3">
            <TextField
              sx={{ width: "100%" }}
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              label="Search"
            />
            <button
              className="bg-green-700 disabled:bg-gray-500 rounded-md px-3 text-white font-bold flex items-center ml-3"
              onClick={() => {
                exportXlsxHistory();
                // mutateExportReportingProduct({
                //   ...reqBody,
                //   company_id: reqBody.company_id.map((data) => data.company_id),
                //   limit: 0,
                //   offset: 0,
                // });
              }}
            >
              <AiOutlineExport className="text-[30px] mr-2" />
              <p className="w-full">Export Laporan</p>
            </button>
          </div>
          {cardStockData && cardStockData !== undefined ? (
            <TableContainer component={Paper} sx={{ width: "100%" }}>
              <Table aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: "#007AF1",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      ID
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Nama Produk
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Batch
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Stock In
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Stock Out
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Stock Awal
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Status Stock
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Tanggal Stock Berubah
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cardStockData.data && cardStockData.data !== undefined
                    ? cardStockData.data.map((data, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ color: "#007AF1" }} align="center">
                            <p
                              className="hover:cursor-pointer hover:underline"
                              onClick={() => {
                                setIsDetailCardStockOpen(true);
                                setProductId(data.product_id);
                                setRelationalId(data.relational_id);
                              }}
                            >
                              {data.product_id}
                            </p>
                          </TableCell>
                          <TableCell align="center">
                            {data.product_name}
                          </TableCell>
                          <TableCell align="center">
                            {data.number_batch}
                          </TableCell>
                          <TableCell align="center">{data.stock_in}</TableCell>
                          <TableCell align="center">{data.stock_out}</TableCell>
                          <TableCell align="center">
                            {data.stock_current}
                          </TableCell>
                          <TableCell align="center">
                            {inventoryStatus[data.status]}
                          </TableCell>
                          <TableCell align="center">
                            {toDate(data.created_at)}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className="flex justify-center w-full mt-3">
              <p>Tidak ditemukan</p>
            </div>
          )}
          <Dialog
            open={isDetailCardStockOpen}
            onClose={() => setIsDetailCardStockOpen(false)}
            maxWidth="lg"
          >
            <DialogContent>
              <CardStockDetail cardStockDetail={cardStockDetail} />
            </DialogContent>
          </Dialog>
        </>
      )}
      <div className="flex justify-center mt-3">
        <Pagination
          count={pageCount}
          page={offset}
          onChange={(_, value) => setOffset(value)}
          shape="rounded"
          size="large"
          color="primary"
        />
      </div>
    </div>
  );
};

export default CardStock;
