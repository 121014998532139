import React from "react";
import UpdateStockHabis from "../../../components/screen/inventory/productHabis/UpdateStockHabis";
import SidebarComponents from "../../../components/ui/SidebarComponents";
import Header from "../../../components/ui/Header";
import { SIDEBAR_WIDTH } from "../../../components/constants/constant";

const UpdateProductHabis = () => {
  return (
    <div className="">
      <div className="flex">
          <SidebarComponents />       
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <UpdateStockHabis />
        </div>
      </div>
    </div>
  );
};

export default UpdateProductHabis;
