import {
  Button,
  CircularProgress,
  InputAdornment,
  Pagination,
  TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import EmptyList from "../../ui/EmptyList";
import useDebounce from "../../hooks/useDebounce";
import { getCustomerList } from "../../../services/invoiceApi";
import { RiEditBoxLine } from "react-icons/ri";
import { FaRegTrashAlt } from "react-icons/fa";
import ModalDeleteCustomer from "./ModalDeleteCustomer";
import InvoiceBreadcrumb from "./InvoiceBreadcrumb";

const FETCH_LIMIT = 10;

const CustomerList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(1);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const debounce = useDebounce(search, 500);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["customer-list", offset, debounce, isChanged],
    queryFn: () => getCustomerList(FETCH_LIMIT, offset, debounce),
  });

  useEffect(() => {
    if (isChanged) setIsChanged(false);
  }, [isChanged]);

  return (
    <>
      <InvoiceBreadcrumb />
      {search === "" &&
      data &&
      ((data.data !== null && data.total === 0) || data.data === null) ? (
        <div className="mt-12">
          <EmptyList message="Belum ada customer list saat ini" />
          <div className="w-full flex justify-center">
            <Button
              variant="contained"
              sx={{ paddingX: 8 }}
              onClick={() => navigate("/invoice/customer/create-customer")}
            >
              Create Customer
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex w-full gap-2 items-center my-4">
            <TextField
              value={search}
              label="Nama Customer/PIC"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              fullWidth
              sx={{ height: "100%", flexBasis: "25%", paddingY: 2 }}
              onClick={() => navigate("/invoice/customer/create-customer")}
            >
              Tambah Customer
            </Button>
          </div>

          <>
            {data ? (
              <div>
                <p className="font-bold text-xl mb-4">List of Customer</p>
                <div className="block overflow-x-auto">
                  <table className="w-full border">
                    <thead className="bg-blue-500 text-white text-center">
                      <tr>
                        <th className="py-2 border">Nama Customer</th>
                        <th className="py-2 border">Diskon</th>
                        <th className="py-2 border">PIC</th>
                        <th className="py-2 border">Alamat</th>
                        <th className="py-2 border">Phone</th>
                        <th className="py-2 border">NPWP</th>
                        <th className="py-2 border">Action</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {data.data !== null && data.data.length > 0 ? (
                        <>
                          {data.data.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td className="p-2 border ">
                                  {item.partner_name}
                                </td>
                                <td className="p-2 border">
                                  {item.discount ? item.discount + "%" : "-"}
                                </td>
                                <td className="p-2 border">{item.pic}</td>
                                <td className="p-2 border">{item.address}</td>
                                <td className="p-2 border">{item.phone}</td>
                                <td className="p-2 border">
                                  {item.npwp && item.npwp !== ""
                                    ? item.npwp
                                    : "-"}
                                </td>
                                <td className="p-2 border">
                                  <div className="h-full flex gap-2">
                                    <div
                                      className="bg-blue-500 rounded-md p-2 cursor-pointer hover:bg-slate-500 w-8 h-8"
                                      onClick={() =>
                                        navigate(
                                          `/invoice/customer/detail-customer/${item.partner_id}`
                                        )
                                      }
                                    >
                                      <RiEditBoxLine className="text-white" />
                                    </div>
                                    <div
                                      className="bg-red-500 rounded-md p-2 cursor-pointer hover:bg-slate-500 w-8 h-8"
                                      onClick={() => {
                                        setOpenModalDelete(true);
                                        setSelectedCustomer(item);
                                      }}
                                    >
                                      <FaRegTrashAlt className="text-white" />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={6} className="p-2 border">
                            Tidak ada Customer ditemukan
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : isFetching ? (
              <CircularProgress size={20} />
            ) : (
              <p>Tidak ada Customer ditemukan</p>
            )}
          </>
        </>
      )}

      <div className="flex justify-end mt-4">
        {data && data.data !== null && data.data.length > 0 ? (
          <Pagination
            count={Math.ceil(data.total / FETCH_LIMIT)}
            page={offset}
            onChange={(_, value) => setOffset(value)}
            shape="rounded"
            size="large"
            color="primary"
          />
        ) : null}
      </div>

      <ModalDeleteCustomer
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        selectedCustomer={selectedCustomer}
        refetch={refetch}
        setOffset={setOffset}
      />
    </>
  );
};

export default CustomerList;
