import React from "react";
import deactivateModal from "../../../assets/images/modalnonaktif.png";

const ModalDeactivateProduct = (props) => {
  const { open, setOpen, productId, mutateDeactivateProduct } = props;

  return (
    <>
      {open ? (
        <>
          <div
            className="justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => setOpen(false)}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-1.5">
                {/*body*/}
                <div className="flex justify-center relative flex-auto object-contain">
                  <img src={deactivateModal} alt="" />
                </div>
                {/*footer*/}
                <p className="text-center font-medium text-[25px] mb-3">
                  Apakah anda yakin untuk deaktifasi produk ini?
                </p>

                <div className="flex items-center justify-center p-6 gap-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-red-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-[70px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Batal
                  </button>
                  <button
                    className="bg-orange-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-[45px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() =>
                      mutateDeactivateProduct({
                        product_id: productId,
                        status: "inactive",
                      })
                    }
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalDeactivateProduct;
