import Cookies from "js-cookie";
import axios from "axios";
import { GeneratePublicToken } from "../utils/GeneratePublicToken";

const token = () => Cookies.get("accessToken");

export const getUserList = async (limit, offset, search) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/user?limit=${limit}&offset=${offset}&name=${search}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const userActivation = async ({ email, is_active }) => {
  try {
    const body = {
      email,
      is_active,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/user/activation`,
      method: "POST",
      data: body,
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createUser = async (form) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/user/add`,
      data: form,
    };
    const res = await axios(config);
    return res;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDetailUser = async (id, companyId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/user/info?staff_id=${id}&company_id=${companyId}`,
      method: "GET",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const updateData = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ` + token(),
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/data/change`,
      method: "PUT",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const uploadImage = async (image) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const body = new FormData();
    body.append("type", "public");
    body.append("datasource", "company");

    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
        "Content-Type": "multipart/form-data",
      },
      url: `${process.env.REACT_APP_API_MASTER}upload/image`,
      method: "POST",
      data: {
        image,
        type: "public",
        datasource: "Principal",
      },
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const deleteImage = async (url) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_MASTER}file/remove`,
      method: "DELETE",
      data: {
        url: url,
      },
    };

    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err;
  }
};
