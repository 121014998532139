import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import EmptyList from "../../ui/EmptyList";
import useDebounce from "../../hooks/useDebounce";
import { BsFillPrinterFill, BsPencilFill } from "react-icons/bs";
import EditInvoiceStatusModal from "./EditInvoiceStatusModal";
import { useReactToPrint } from "react-to-print";
import PrintInvoice from "./PrintInvoice";
import { getInvoiceList } from "../../../services/invoiceApi";
import axios from "axios";
import Cookies from "js-cookie";
import InvoiceBreadcrumb from "./InvoiceBreadcrumb";
import { getSipaAndFullname, getUserProfile } from "../../../services/authApi";

const FETCH_LIMIT = 10;
const token = () => Cookies.get("accessToken");

const InvoiceList = () => {
  const navigate = useNavigate();
  const componentRef = useRef();

  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPo, setSelectedPo] = useState(null);
  const [printArr, setPrintArr] = useState([]);
  const [dataInvArr, setDataInvArr] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [status, setStatus] = useState(0);
  const [resLen, setResLen] = useState(0);

  const debounce = useDebounce(search, 500);

  const { data: profile } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getUserProfile(),
  });

  const { data: sipaAndFullnameData } = useQuery({
    queryKey: ["sipa-fullname", profile?.id],
    queryFn: () =>
      getSipaAndFullname(profile && profile !== undefined ? profile.id : 0),
  });

  const handleAddInvoice = (invNum) => {
    var temp = [...printArr];
    if (printArr.includes(invNum))
      temp = temp.filter((item) => item !== invNum);
    else temp.push(invNum);
    setPrintArr(temp);
  };

  const handleSelectAll = (checked) => {
    setPrintArr(
      checked ? [...data.data.map((item) => item.invoice_number)] : []
    );
  };

  window.onerror = function (e) {
    if (e.includes("NotFoundError:")) {
      document.location.reload();
      return true;
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Faktur OLIN ${moment().format("YYYY-MM-DD hh:mm")}`,
  });

  const generatePrintInvoice = async () => {
    setIsPrint(true);
    var temp = [];

    axios
      .all([
        printArr.map((num) =>
          axios({
            headers: {
              Authorization: `Bearer ${token()}`,
            },
            method: "GET",
            url: `${
              process.env.REACT_APP_API_PRODUCT
            }invoice/detail-invoice?invoice_number=${num}&company_user_id=${
              sipaAndFullnameData && sipaAndFullnameData !== undefined
                ? sipaAndFullnameData.id
                : 0
            }`,
          })
        ),
      ])
      .then((res) => {
        res.map((resData) => {
          setResLen(resData.length);
          resData.map((item) => {
            item.then((promiseRes) => {
              temp.push(promiseRes.data.detail);
              if (temp.length === resData.length) {
                setDataInvArr(temp);
              }
            });
          });
        });
      });
  };

  const { data, isFetching, refetch, isRefetching } = useQuery({
    queryKey: ["invoice-list", offset, debounce, status],
    queryFn: () => getInvoiceList(FETCH_LIMIT, offset, debounce, status),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    data &&
    printArr.length ===
      (data.total < 10
        ? data.total
        : offset !== Math.ceil(data.total / FETCH_LIMIT)
        ? FETCH_LIMIT
        : data.total % FETCH_LIMIT)
      ? setSelectAll(true)
      : setSelectAll(false);
  }, [printArr]);

  useEffect(() => {
    if (dataInvArr.length > 0 && isPrint) {
      handlePrint();
      setIsPrint(false);
      setOpenModal(false);
      setDataInvArr([]);
      setResLen(0);
    }
  }, [dataInvArr, resLen, isPrint]);

  useEffect(() => {
    setPrintArr([]);
    setSelectAll(false);
  }, [offset]);

  return (
    <>
      <InvoiceBreadcrumb />
      {search === "" &&
      data &&
      ((data.data !== null && data.data.length === 0) || data.data === null) ? (
        <div className="mt-12">
          <EmptyList message="Belum ada invoice list saat ini" />
          <div className="w-full flex justify-center">
            <Button
              variant="contained"
              sx={{ paddingX: 8 }}
              onClick={() => navigate("/invoice/invoice/create-invoice")}
            >
              Tambah Faktur
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex w-full gap-2 items-center my-4">
            <TextField
              value={search}
              label="No. Faktur"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl fullWidth>
              <InputLabel>Status Faktur</InputLabel>
              <Select
                label="Status Faktur"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={0}>Semua Status</MenuItem>
                <MenuItem value={1}>Lunas</MenuItem>
                <MenuItem value={2}>Belum Lunas</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              fullWidth
              sx={{ flexBasis: "50%", height: "100%", paddingY: 2 }}
              onClick={() => navigate("/invoice/invoice/create-invoice")}
            >
              Tambah Faktur
            </Button>
          </div>

          <>
            {data ? (
              <div>
                <div className="flex justify-between mb-4 items-center">
                  <p className="font-bold text-xl">List of Invoice</p>
                  <div>
                    <button
                      onClick={generatePrintInvoice}
                      className="border-2 border-gray-200 rounded-md hover:bg-slate-100 px-12 py-2 flex items-center gap-2 disabled:bg-gray-300 disabled:text-white"
                      disabled={printArr.length === 0 || isPrint}
                    >
                      {isPrint ? (
                        <CircularProgress size={20} />
                      ) : (
                        <>
                          Print Invoice
                          <BsFillPrinterFill />
                        </>
                      )}
                    </button>
                  </div>
                </div>

                <div className="block overflow-x-auto w-full">
                  <table className="w-full border">
                    <thead className="bg-blue-500 text-white text-center">
                      <tr>
                        <th className="py-2 border">
                          <Checkbox
                            checked={selectAll}
                            onChange={(e, checked) => handleSelectAll(checked)}
                          />
                        </th>
                        <th className="py-2 border">Tanggal Faktur</th>
                        <th className="py-2 border">No. Faktur</th>
                        <th className="py-2 border">Customer</th>
                        <th className="py-2 border">Total Bayar</th>
                        <th className="py-2 border">Jatuh Tempo</th>
                        <th className="py-2 border">Tanggal Pembayaran</th>
                        <th className="py-2 border">Metode Pembayaran</th>
                        <th className="py-2 border">Status</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {data.data !== null &&
                      data.data.length > 0 &&
                      !isFetching &&
                      !isRefetching ? (
                        <>
                          {data.data.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td className="py-2 border">
                                  <Checkbox
                                    onChange={() =>
                                      handleAddInvoice(item.invoice_number)
                                    }
                                    checked={printArr.includes(
                                      item.invoice_number
                                    )}
                                  />
                                </td>
                                <td className="py-2 border whitespace-nowrap">
                                  {moment(item.invoice_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td
                                  className="p-2 border text-blue-500 hover:text-blue-700 cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/invoice/invoice/detail-invoice/${item.invoice_number.replaceAll(
                                        "/",
                                        "="
                                      )}`
                                    )
                                  }
                                >
                                  {item.invoice_number}
                                </td>
                                <td className="p-2 border">
                                  {item.customer_name}
                                </td>
                                <td className="p-2 border">
                                  {item.total_amount.toLocaleString("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                  })}
                                </td>
                                <td className="p-2 border whitespace-nowrap">
                                  {moment(item.due_date).format("DD-MM-YYYY")}
                                </td>
                                <td className="p-2 border whitespace-nowrap">
                                  {item.paid_at === ""
                                    ? "-"
                                    : moment(item.paid_at).format("DD-MM-YYYY")}
                                </td>
                                <td className="p-2 border">
                                  {item.payment_method &&
                                  item.payment_method !== ""
                                    ? item.payment_method
                                    : "-"}
                                </td>
                                <td className="p-2 border">
                                  <div
                                    className={`rounded-md whitespace-nowrap p-2 cursor-pointer ${
                                      item.is_paid
                                        ? "bg-green-200 hover:bg-green-300 text-green-800"
                                        : "bg-red-200 hover:bg-red-300 text-red-800"
                                    }`}
                                    onClick={() => {
                                      setOpenModal(true);
                                      setSelectedPo(item);
                                    }}
                                  >
                                    {item.is_paid ? "Lunas" : "Belum Lunas"}
                                    <BsPencilFill className="inline-block ml-2" />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : isFetching || isRefetching ? (
                        <tr>
                          <td className="p-2 border text-center" colSpan={9}>
                            <CircularProgress size={20} />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className="p-2 border text-center" colSpan={9}>
                            Tidak ada invoice ditemukan
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </>
        </>
      )}

      <div className="flex justify-end mt-4">
        {data && data.data !== null && data.data.length > 0 ? (
          <Pagination
            count={Math.ceil(data.total / FETCH_LIMIT)}
            page={offset}
            onChange={(_, value) => setOffset(value)}
            shape="rounded"
            size="large"
            color="primary"
          />
        ) : null}
      </div>

      {openModal !== undefined && setOpenModal !== undefined && selectedPo ? (
        <EditInvoiceStatusModal
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
          status={selectedPo.is_paid}
          paidDate={selectedPo.paid_at}
          paymentMethod={selectedPo.payment_method}
          invNum={selectedPo.invoice_number}
          invDate={selectedPo.invoice_date}
          refetch={refetch}
        />
      ) : null}

      <div className="p-5" ref={componentRef}>
        <PrintInvoice
          dataInvArr={dataInvArr}
          sipaAndFullnameData={sipaAndFullnameData}
        />
      </div>
    </>
  );
};

export default InvoiceList;
