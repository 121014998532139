import { IoMdAddCircleOutline } from "react-icons/io";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";
import BreadcrumbPatientCare from "../BreadcrumbPatientCare";
import { FaChevronDown, FaChevronLeft } from "react-icons/fa";
import { MdOutlineFilterAlt } from "react-icons/md";
import {
  CircularProgress,
  InputAdornment,
  Popover,
  setRef,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import moment from "moment";
import { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import { IoCalendarOutline } from "react-icons/io5";
import PopupRangeCalendar from "../../../common/particles.jsx/PopupRangeCalendar";
import { useMutation } from "@tanstack/react-query";
import { getRmeList } from "../../../../services/patientCare";
import PagePagination from "../../../common/particles.jsx/PagePagination";

const INITIAL_REQ = {
  limit: 10,
  offset: 1,
  start_date: "",
  end_date: "",
  checkup_type: 0,
};

const CHECKUP_TYPES = [
  { id: 0, name: "All" },
  { id: 1, name: "Initial Analysis" },
  { id: 2, name: "Doctor Analysis" },
];

const MedicalRecordList = () => {
  const [search, setSearch] = useState("");
  const [reqBody, setReqBody] = useState(INITIAL_REQ);
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const debounce = useDebounce(search, 500);

  const {
    mutate: mutateList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: getRmeList,
  });

  useEffect(() => {
    mutateList({
      ...reqBody,
      search: debounce,
    });
  }, [debounce, reqBody]);

  return (
    <>
      <LoadingBackdrop isLoading={false} />
      <BreadcrumbPatientCare />

      <div className="flex items-center gap-2 mb-4">
        <TextField
          fullWidth
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputProps={{ style: { padding: 8 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElCal(e.currentTarget)}
        >
          <IoCalendarOutline />
          <p className="whitespace-nowrap">
            {(reqBody.start_date === "") & (reqBody.end_date === "")
              ? "No Date Choosen"
              : moment(reqBody.start_date).format("DD MMM YYYY") +
                "-" +
                moment(reqBody.end_date).format("DD MMM YYYY")}
          </p>
          <FaChevronDown />
        </div>
        <div
          className="rounded-md p-2 border-2 flex justify-center items-center cursor-pointer hover:bg-gray-300"
          onClick={(e) => setAnchorElFilter(e.currentTarget)}
        >
          <MdOutlineFilterAlt className="w-5 h-5" />
        </div>
        {/* <div className="rounded-md p-2 flex justify-center items-center cursor-pointer hover:bg-gray-300 bg-blue-500 text-white">
          <IoMdAddCircleOutline className="w-5 h-5" />
        </div> */}
      </div>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">Tanggal Check Up</th>
                <th className="p-2 text-center whitespace-nowrap">
                  No Check Up
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Tipe Check Up
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Nama Pasien
                </th>
                <th className="p-2 text-center whitespace-nowrap">Dokter</th>
              </thead>
              <tbody>
                {dataList && !isLoading && dataList.count > 0 ? (
                  dataList.data.map((item, i) => (
                    <tr key={i}>
                      <td className="p-2 text-center border border-black">
                        {item.check_date}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.checkup_number}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.checkup_type}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.patient_name}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.doctor_name}
                      </td>
                    </tr>
                  ))
                ) : isLoading ? (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center border border-black p-2"
                    >
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center border border-black p-2"
                    >
                      Tidak ada rekam medis
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && !isLoading && dataList.count > 0 ? (
        <PagePagination
          offset={reqBody.offset}
          setOffset={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              offset: e,
            }));
          }}
          limit={reqBody.limit}
          setLimit={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              limit: e,
            }));
          }}
          total={dataList.count}
        />
      ) : null}

      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500 cursor-pointer"
              onClick={() => setAnchorElFilter(null)}
            />
            <p className="font-bold text-center">Filter</p>
          </div>
        </div>
        <hr />
        {CHECKUP_TYPES.map((item, i) => (
          <div
            className={`px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 ${
              reqBody.checkup_type === item.id ? "bg-gray-200" : ""
            }`}
            onClick={() =>
              setReqBody((prevValue) => ({
                ...prevValue,
                checkup_type: item.id,
              }))
            }
          >
            {item.name}
          </div>
        ))}
      </Popover>

      <PopupRangeCalendar
        anchorElCal={anchorElCal}
        setAnchorElCal={setAnchorElCal}
        startDate={reqBody.start_date}
        endDate={reqBody.end_date}
        setStartDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
        }}
        setEndDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
        }}
      />
    </>
  );
};

export default MedicalRecordList;
