import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  CircularProgress,
  TextField,
  Collapse,
  Checkbox,
} from "@mui/material";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import Papa from "papaparse";
import { BiPrinter, BiExport, BiImport } from "react-icons/bi";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import BillingNavigation from "../billing/BillingNavigation";
import { getNewBilling } from "../../../services/billingAPI";
import logoOlin from "../../../assets/icons/logoOlin.png";
import BillingBreadcrumb from "../billing/BillingBreadcrumb";
import { exportCsvBilling } from "../../../services/purchaseOrderApi";
import ExportBillingModal from "./ExportBillingModal";
import ImportBillingModal from "./ImportBillingModal";
import { yearFormatBilling } from "../../constants/constant";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";

const BILLING_STATUS = 5;

const yearNow = moment();

const statusBilling = {
  1: {
    color: "bg-yellow-500",
    name: "Unpaid",
  },
  2: {
    color: "bg-red-500",
    name: "Overdue",
  },
  3: {
    color: "bg-green-500",
    name: "Paid",
  },
  4: {
    color: "bg-blue-500",
    name: "Draft",
  },
};

const AllBillingManual = () => {
  const navigate = useNavigate();

  const [openExportModal, setOpenExportModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [year, setYear] = useState(yearNow);
  const [dataBuffer, setDataBuffer] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [billingIdArr, setBillingIdArr] = useState([]);

  const handleJsonToCsvExisting = (csvExisting) => {
    const csv = Papa.unparse(csvExisting, {
      delimiter: ";",
    });

    const blob = new Blob([csv], { type: "application/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.download = "OLIN_Billing_" + moment().format("DD_MM_YYYY_hh:mm") + ".csv";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const { mutate: mutateCsvBilling, isLoading: isCsvBillingLoading } =
    useMutation({
      mutationFn: exportCsvBilling,
      onSuccess: (data) => {
        handleJsonToCsvExisting(
          data.map((data) => ({
            ...data,
            price: "Rp. " + data.price,
            total_price: "Rp. " + data.total_price,
            due_date: moment(data.due_date).format("DD-MMMM-YYYY"),
            po_date: moment(data.po_date).format("DD-MMMM-YYYY"),
            expired_date: moment(data.expired_date).format("DD-MMMM-YYYY"),
            status: statusBilling[data.status].name,
          }))
        );
      },
    });

  const {
    mutate: mutateAllBilling,
    data: allBillingData,
    isLoading: isAllBillingDataLoading,
    error: isUnpaidBillingError,
  } = useMutation({
    mutationFn: getNewBilling,
    onError: (err) => setErrorMessage(err.id),
    onSuccess: (data) => {
      const formattedDate = [
        ...new Set(data.map((data) => data.overdue_date.substr(0, 7))),
      ];
      setDataBuffer(
        data && data !== undefined
          ? formattedDate.map((item) => ({
              date: item,
              isOpen: false,
              isChecked: false,
              buffer: data
                .filter(
                  (value) =>
                    moment(value.overdue_date).format("YYYY-MM") === item
                )
                .map((value) => ({
                  ...value,
                  month: moment(value.overdue_date).format("MMMM"),
                  isCheckedChild: false,
                })),
            }))
          : []
      );
    },
  });

  useEffect(() => {
    mutateAllBilling({
      year: yearFormatBilling(year),
      status: BILLING_STATUS,
    });
  }, [mutateAllBilling, year]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <LoadingBackdrop isLoading={isCsvBillingLoading} />
      <div className="flex justify-between items-center">
        <BillingBreadcrumb />
        <div className="w-full flex justify-end gap-2">
          <Button
            sx={{ paddingX: 2 }}
            variant="outlined"
            onClick={() => setOpenImportModal(true)}
          >
            Import
            <BiImport className="ml-2" />
          </Button>
          <Button
            sx={{ paddingX: 2 }}
            variant="outlined"
            onClick={() => setOpenExportModal(true)}
          >
            Export
            <BiExport className="ml-2" />
          </Button>
          <Button
            sx={{ paddingX: 2 }}
            variant="contained"
            onClick={() => navigate("/manual-billing/list")}
          >
            Create Billing
          </Button>
        </div>
      </div>
      <BillingNavigation />

      <div
        className="offcanvas offcanvas-bottom fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-1/3 max-h-full"
        tabIndex="-1"
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header flex items-center justify-between p-4">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold"
            id="offcanvasBottomLabel"
          >
            Offcanvas bottom
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow p-4 overflow-y-auto small">
          ...
        </div>
      </div>
      <div className="flex items-center">
        {allBillingData && allBillingData !== undefined ? (
          <p>
            Total Amount{" "}
            <span className="text-blue-500">
              {(allBillingData && allBillingData !== undefined
                ? allBillingData.reduce((acc, num) => acc + num.total_amount, 0)
                : 0
              )
                .toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })
                .replaceAll(",", ".")}
            </span>
          </p>
        ) : null}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            fullWidth
            onChange={(newDate) => setYear(newDate)}
            value={year}
            renderInput={(params) => {
              return <TextField {...params} sx={{ marginY: 3, marginX: 2 }} />;
            }}
            label="Select Year"
            views={["year"]}
          />
        </LocalizationProvider>
      </div>

      {allBillingData && allBillingData !== undefined ? (
        <div className="flex items-center">
          <Checkbox
            checked={isCheckedAll}
            onChange={(e) => {
              setIsCheckedAll(e.target.checked);
              if (e.target.checked) {
                setDataBuffer((prevValue) =>
                  prevValue.map((data) => {
                    return {
                      ...data,
                      isChecked: true,
                      buffer: data.buffer
                        .filter(
                          (item) =>
                            item.month === moment(data.date).format("MMMM")
                        )
                        .map((child) => ({
                          ...child,
                          isCheckedChild: true,
                        })),
                    };
                  })
                );
                setBillingIdArr(allBillingData.map((data) => data.billing_id));
              } else {
                setDataBuffer((prevValue) =>
                  prevValue.map((data) => {
                    return {
                      ...data,
                      isChecked: false,
                      buffer: data.buffer
                        .filter(
                          (item) =>
                            item.month === moment(data.date).format("MMMM")
                        )
                        .map((child) => ({
                          ...child,
                          isCheckedChild: false,
                        })),
                    };
                  })
                );
                setBillingIdArr([]);
              }
            }}
          />
          <button
            className="disabled:bg-gray-300 disabled:text-white text-blue-500 border-2 border-blue-500 rounded-md hover:bg-slate-100 w-1/4 flex items-center justify-center py-2"
            onClick={() => {
              mutateCsvBilling(billingIdArr);
            }}
            disabled={isCsvBillingLoading || billingIdArr.length <= 0}
          >
            Print All Billing
            <BiPrinter className="ml-4" />
          </button>
        </div>
      ) : null}
      <div className="mb-8">
        {allBillingData && allBillingData !== undefined ? (
          <div>
            {dataBuffer.map((data, index) => (
              <div
                className="w-full border border-black my-3 rounded-md"
                key={index}
              >
                <div className="flex p-2">
                  <div className="flex items-center font-medium w-full">
                    <Checkbox
                      checked={data.isChecked}
                      onChange={() => {
                        if (data.isChecked) {
                          setDataBuffer((prevValue) => {
                            const newDataBuffer = prevValue.map((item, idx) => {
                              return index === idx
                                ? {
                                    ...item,
                                    isChecked: false,
                                    buffer: item.buffer
                                      .filter(
                                        (item) =>
                                          item.month ===
                                          moment(data.date).format("MMMM")
                                      )
                                      .map((child) => ({
                                        ...child,
                                        isCheckedChild: false,
                                      })),
                                  }
                                : item;
                            });
                            const allChecked = newDataBuffer.every(
                              (data) => data.isChecked
                            );

                            setIsCheckedAll(allChecked);

                            return newDataBuffer;
                          });
                          const newArr = data.buffer
                            .filter(
                              (item) =>
                                item.month === moment(data.date).format("MMMM")
                            )
                            .map((item) => item.billing_id);
                          setBillingIdArr((prevValue) =>
                            prevValue.filter((item) => !newArr.includes(item))
                          );
                        } else {
                          setDataBuffer((prevValue) => {
                            const newDataBuffer = prevValue.map((item, idx) => {
                              return index === idx
                                ? {
                                    ...item,
                                    isChecked: true,
                                    buffer: item.buffer
                                      .filter(
                                        (item) =>
                                          item.month ===
                                          moment(data.date).format("MMMM")
                                      )
                                      .map((child) => ({
                                        ...child,
                                        isCheckedChild: true,
                                      })),
                                  }
                                : item;
                            });
                            const allChecked = newDataBuffer.every(
                              (data) => data.isChecked
                            );

                            setIsCheckedAll(allChecked);

                            return newDataBuffer;
                          });
                          setBillingIdArr((prevValue) =>
                            prevValue.concat(
                              data.buffer
                                .filter(
                                  (item) =>
                                    item.month ===
                                    moment(data.date).format("MMMM")
                                )
                                .map((item) => item.billing_id)
                            )
                          );
                        }
                      }}
                    />
                    <p>{moment(data.date).format("MMMM YYYY")},</p>
                    <p className="mx-2">
                      {data.buffer && data.buffer !== undefined
                        ? data.buffer
                            .filter(
                              (item) =>
                                item.month === moment(data.date).format("MMMM")
                            )
                            .reduce((acc, num) => acc + num.total_amount, 0)
                            .toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })
                            .replaceAll(",", ".")
                        : 0}
                    </p>
                  </div>
                  <button
                    className="bg-blue-500 p-2 rounded-md text-white font-bold"
                    onClick={() => {
                      setDataBuffer((prevValue) =>
                        prevValue.map((item, idx) => {
                          return index === idx
                            ? {
                                ...item,
                                isOpen: !item.isOpen,
                              }
                            : item;
                        })
                      );
                    }}
                  >
                    {data.isOpen ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </button>
                </div>
                {data.buffer && data.buffer !== undefined ? (
                  <Collapse
                    in={data.isOpen}
                    unmountOnExit
                    sx={{ fontWeight: "bold", padding: 2 }}
                  >
                    {data.buffer.map((data) => (
                      <div
                        className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between w-full"
                        key={data.billing_id}
                      >
                        <div className="flex items-center w-full">
                          <div className="w-full">
                            <div className="w-full flex justify-between">
                              <Checkbox
                                checked={data.isCheckedChild}
                                onChange={() => {
                                  setDataBuffer((prevValue) => {
                                    const newDataBuffer = prevValue.map(
                                      (value) => ({
                                        ...value,
                                        buffer: value.buffer.map((child) => {
                                          return child.billing_id ===
                                            data.billing_id
                                            ? {
                                                ...child,
                                                isCheckedChild:
                                                  !child.isCheckedChild,
                                              }
                                            : child;
                                        }),
                                      })
                                    );
                                    setIsCheckedAll(
                                      newDataBuffer.every((data) =>
                                        data.buffer.every(
                                          (item) => item.isCheckedChild
                                        )
                                      )
                                    );
                                    return newDataBuffer;
                                  });
                                  setDataBuffer((prevValue) => {
                                    const newDataBuffer = prevValue.map(
                                      (data) => ({
                                        ...data,
                                        isChecked: data.buffer.every(
                                          (item) => item.isCheckedChild
                                        ),
                                      })
                                    );
                                    return newDataBuffer;
                                  });

                                  if (data.isCheckedChild) {
                                    setBillingIdArr((prevValue) =>
                                      prevValue.filter(
                                        (item) => item !== data.billing_id
                                      )
                                    );
                                  } else {
                                    setBillingIdArr((prevValue) => [
                                      ...prevValue,
                                      data.billing_id,
                                    ]);
                                  }
                                }}
                              />
                              <div
                                className={`${
                                  statusBilling[data.status].color
                                } text-white font-bold py-2 px-8 rounded-md w-1/4 text-center`}
                                // className={`${
                                //   data.status === 1
                                //     ? "bg-yellow-500"
                                //     : data.status === 2
                                //     ? "bg-red-500"
                                //     : "bg-green-500"
                                // } text-white font-bold py-2 px-8 rounded-md w-1/4 text-center`}
                              >
                                {statusBilling[data.status].name}
                              </div>
                            </div>
                            <Divider sx={{ marginY: 2, borderWidth: 1 }} />
                            <div className="flex my-2">
                              <img
                                src={logoOlin}
                                alt="foto_product"
                                className="w-30 rounded-lg mx-2 p-2"
                              />
                              <div className="flex justify-between w-full">
                                <div className="flex flex-col text-sm lg:text-base gap-1 text-clip overflow-hidden w-full">
                                  <p className="text-blue-500">
                                    {data.invoice_number}
                                  </p>
                                  <p>{data.po_number}</p>
                                  <p className="font-bold">
                                    {data.total_amount
                                      .toLocaleString("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                      })
                                      .replaceAll(",", ".")}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <Divider sx={{ marginY: 2, borderWidth: 1 }} />
                            {data.status === 1 || data.status === 2 ? (
                              <div className="basis-1/2 md:basis-1/4 float-right">
                                <Button
                                  variant="contained"
                                  fullWidth
                                  onClick={() =>
                                    navigate(
                                      `/manual-billing/detail/${data.billing_id}`
                                    )
                                  }
                                >
                                  Bayar Sekarang
                                </Button>
                              </div>
                            ) : (
                              <div className="flex w-full items-center justify-between">
                                <div className="basis-1/2 md:basis-1/4 float-left">
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/manual-billing/detail/${data.billing_id}`
                                      )
                                    }
                                    className="w-full bg-gray-700 text-white cursor-pointer hover:bg-black rounded-md text-center p-2"
                                  >
                                    Billing Detail
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Collapse>
                ) : null}
              </div>
            ))}
            {/*dataBuffer.map((item, index) => (
              <div
                className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between w-full"
                key={index}
              >
                <div className="w-full">
                  <div className="flex items-center py-2">
                    <p className="w-full">
                      Jatuh tempo{" "}
                      {moment(item.overdue_date).format("DD MMMM YYYY")}{" "}
                    </p>
                    <div
                    // className={`${
                    //   item.status === 1
                    //     ? "bg-yellow-500"
                    //     : item.status === 2
                    //     ? "bg-red-500"
                    //     : "bg-green-500"
                    // } text-white font-bold py-2 px-8 rounded-md w-1/4 text-center`}
                    >
                      {item.status === 1
                          ? "Unpaid"
                          : item.status === 2
                          ? "Overdue"
                          : "Paid"}
                    </div>
                  </div>

                  <Divider sx={{ marginY: 2, borderWidth: 1 }} />
                  <div className="flex my-2">
                    <img
                      src={logoOlin}
                      alt="foto_product"
                      className="w-30 rounded-lg mx-2 p-2"
                    />
                    <div className="flex justify-between w-full">
                      <div className="flex flex-col text-sm lg:text-base gap-1 text-clip overflow-hidden w-full">
                        <p className="text-blue-500">
                          <Link
                            to={`/manual-billing/detail/${item.billing_id}`}
                            className="hover:none"
                          >
                            {item.invoice_number}
                          </Link>
                        </p>
                        <p>{item.po_number}</p>
                        <p className="font-bold">
                          Rp.
                          {item.total_amount
                            .toLocaleString()
                            .replaceAll(",", ".")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))*/}
          </div>
        ) : isAllBillingDataLoading ? (
          <div className="flex justify-center mt-5">
            <CircularProgress />
          </div>
        ) : isUnpaidBillingError ? (
          <p className="text-red-500 py-4 text-center">{errorMessage}</p>
        ) : null}
      </div>
      <ExportBillingModal
        openModal={openExportModal}
        setOpenModal={setOpenExportModal}
      />
      <ImportBillingModal
        openModal={openImportModal}
        setOpenModal={setOpenImportModal}
      />
    </div>
  );
};

export default AllBillingManual;
