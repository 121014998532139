import axios from "axios";
import Cookies from "js-cookie";

import { GeneratePublicToken } from "../utils/GeneratePublicToken";

const token = () => Cookies.get("accessToken");

export const fetchData = async ({
  periode_sales,
  method_sales,
  start_date,
  end_date,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },

      url: `${process.env.REACT_APP_API_REPORTING}report/sales`,
      method: "POST",
      data: {
        periode_sales,
        method_sales,
        start_date,
        end_date,
      },
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getTransactionStatistics = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}get/trx/stat`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};


export const getTransactionStatisticsNew = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}new/get/trx/stat`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchHistoryTransaction = async ({
  param,
  offset,
  limit,
  bank_name,
  start_date,
  end_date,
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}pos/history-transaction-dashboard`,
      method: "POST",
      data: {
        param,
        offset,
        limit,
        bank_name,
        start_date,
        end_date,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getPaymentDashboard = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}pos/filter-payment-history-dashboard`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchDetailPayment = async ({ param, start_date, end_date }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}pos/details-payment-dashboard`,
      method: "POST",
      data: {
        param,
        start_date,
        end_date,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getMultipleOutlet = async (company_name, offset, limit) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}get/multiple-outlet?company_name=${company_name}&offset=${offset}&limit=${limit}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchMultipleOutlet = async (body) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];
  try {
    const config = {
      // headers: {
      //   Authorization: `Bearer ${token()}`,
      // },
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_REPORTING}report/multiple-sales`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getTransactionDetail = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}pos/detail/history?id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const sendOtpDisbursment = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}disburstment/send-otp`,
      method: "POST",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const createDisbursment = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}disburstment`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};
