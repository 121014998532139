import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useQuery, useMutation } from "@tanstack/react-query";
import { IoIosAddCircleOutline } from "react-icons/io";
import { BsTrash } from "react-icons/bs";
import * as yup from "yup";

import EmptyList from "../../../ui/EmptyList";
import {
  createRetur,
  getProductForRetur,
} from "../../../../services/billingAPI";
import CreateReturProductModal from "./CreateReturProductModal";
import { IGNORE_TYPE } from "../../../constants/constant";
import useDebounce from "../../../hooks/useDebounce";

const FETCH_LIMIT = 10;

const validationSchema = yup.object({
  distributor_name: yup
    .string()
    .required("Nama wajib di isi")
    .max(100, "Nama Distributor maksimal 100 karakter"),
  distributor_address: yup
    .string()
    .required("Alamat wajib di isi")
    .max(100, "Alamat Distributor maksimal 100 karakter"),
  distributor_phone: yup
    .string()
    .required("No telp wajib di isi")
    .min(5, "No Telp Distributor minimal 5 karakter")
    .max(100, "No Telp Distributor maksimal 100 karakter"),
  note_number: yup
    .string()
    .required("Nota wajib diisi")
    .min(3, "Nomor nota harus lebih dari 3 karakter"),
  data_product: yup
    .array()
    .required("Produk wajib di isi")
    .min(1, "minimal retur 1 barang"),
});

const CreateReturProduct = () => {
  const navigate = useNavigate();
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [buffer, setBuffer] = useState([]);
  const [offset, setOffset] = useState(1);
  const [searchProduct, setSearchProduct] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const debounce = useDebounce(searchProduct, 500);

  const [productReturListBuffer, setProductBufferListBuffer] = useState([]);
  const { mutate: mutateCreateRetur } = useMutation({
    mutationFn: createRetur,
    onSuccess: () => navigate("/billing/product-retur"),
    onError: (err) => setErrorMessage(err.message.en),
  });

  const { data: productReturList, isLoading: isProductReturListLoading } =
    useQuery({
      queryKey: ["product-retur-list", offset, debounce],
      queryFn: () => getProductForRetur(FETCH_LIMIT, offset, debounce),
      enabled: isAddProductModalOpen,
      onSuccess: (data) => {
        if (data && data !== undefined) {
          setProductBufferListBuffer(
            data.data?.map((item) => ({
              ...item,
              isChecked: false,
            }))
          );
        } else {
          setProductBufferListBuffer([]);
        }
      },
    });

  const calculateStock = (item) => {
    if (item.type_packaging === item.packaging.type_box) {
      return item.pharmacy_net_price * item.retur_quantity;
    } else if (item.type_packaging === item.packaging.type_strip) {
      return (
        (item.pharmacy_net_price * item.retur_quantity) /
        item.packaging.total_strip
      );
    } else {
      return (
        (item.pharmacy_net_price * item.retur_quantity) /
        (item.packaging.total_strip * item.packaging.total_unit)
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      distributor_name: "",
      distributor_address: "",
      distributor_phone: 0,
      note_number: Math.floor(100000 + Math.random() * 900000).toString(),
      total_price: 0,
      data_product: [],
    },
    validationSchema,
    onSubmit: (values) => {
      mutateCreateRetur({
        ...values,
        total_price: values.data_product
          .map((data) => {
            if (data.type_packaging === data.packaging.type_box) {
              return data.pharmacy_net_price * data.retur_quantity;
            } else if (data.type_packaging === data.packaging.type_strip) {
              return (
                (data.pharmacy_net_price * data.retur_quantity) /
                data.packaging.total_strip
              );
            } else {
              return (
                (data.pharmacy_net_price * data.retur_quantity) /
                (data.packaging.total_strip * data.packaging.total_unit)
              );
            }
          })
          .reduce((acc, curr) => {
            return acc + curr;
          }, 0),
        data_product: values.data_product.map((data, index) => {
          if (buffer[index].type_packaging === data.packaging?.type_unit) {
            return {
              invoice_number: data.invoice_number,
              product_id: data.product_id,
              product_new_id: data.product_new_id,
              product_name: data.product_name,
              batch_number: data.batch_number,
              expired_date: data.expired_date,
              product_new_stock: data.product_new_stock,
              pharmacy_net_price: calculateStock(data),
              sell_price: data.sell_price,
              retur_quantity: data.retur_quantity,
              quantity: data.quantity,
              type_packaging: data.type_packaging,
            };
          }
          if (buffer[index].type_packaging === data.packaging?.type_strip) {
            if (IGNORE_TYPE.includes(data.packaging?.type_unit)) {
              return {
                invoice_number: data.invoice_number,
                product_id: data.product_id,
                product_new_id: data.product_new_id,
                product_name: data.product_name,
                batch_number: data.batch_number,
                expired_date: data.expired_date,
                product_new_stock: data.product_new_stock,
                pharmacy_net_price: calculateStock(data),
                sell_price: data.sell_price,
                retur_quantity: data.retur_quantity * 1,
                quantity: data.quantity,
                type_packaging: data.type_packaging,
              };
            } else {
              return {
                invoice_number: data.invoice_number,
                product_id: data.product_id,
                product_new_id: data.product_new_id,
                product_name: data.product_name,
                batch_number: data.batch_number,
                expired_date: data.expired_date,
                product_new_stock: data.product_new_stock,
                pharmacy_net_price: calculateStock(data),
                sell_price: data.sell_price,
                retur_quantity:
                  data.retur_quantity * data.packaging?.total_unit,
                quantity: data.quantity,
                type_packaging: data.type_packaging,
              };
            }
          }
          if (buffer[index].type_packaging === data.packaging?.type_box) {
            if (IGNORE_TYPE.includes(data.packaging?.type_unit)) {
              return {
                invoice_number: data.invoice_number,
                product_id: data.product_id,
                product_new_id: data.product_new_id,
                product_name: data.product_name,
                batch_number: data.batch_number,
                expired_date: data.expired_date,
                product_new_stock: data.product_new_stock,
                pharmacy_net_price: calculateStock(data),
                sell_price: data.sell_price,
                retur_quantity:
                  data.retur_quantity * data.packaging?.total_strip * 1,
                quantity: data.quantity,
                type_packaging: data.type_packaging,
              };
            } else {
              return {
                invoice_number: data.invoice_number,
                product_id: data.product_id,
                product_new_id: data.product_new_id,
                product_name: data.product_name,
                batch_number: data.batch_number,
                expired_date: data.expired_date,
                product_new_stock: data.product_new_stock,
                pharmacy_net_price: calculateStock(data),
                sell_price: data.sell_price,
                retur_quantity:
                  data.retur_quantity *
                  data.packaging?.total_strip *
                  data.packaging?.total_unit,
                quantity: data.quantity,
                type_packaging: data.type_packaging,
              };
            }
          }
          return {
            invoice_number: data.invoice_number,
            product_id: data.product_id,
            product_new_id: data.product_new_id,
            product_name: data.product_name,
            batch_number: data.batch_number,
            expired_date: data.expired_date,
            product_new_stock: data.product_new_stock,
            pharmacy_net_price: calculateStock(data),
            sell_price: data.sell_price,
            retur_quantity: data.retur_quantity,
            quantity: data.quantity,
            type_packaging: data.type_packaging,
          };
        }),
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div className="shadow-md rounded-md p-4">
          <p className="text-blue-500 font-bold pt-2">Create Retur</p>
          <hr className="my-2" />
          <div className="mt-3">
            <p className="font-bold">Note Number</p>
            <TextField
              fullWidth
              disabled
              value={formik.values.note_number.toString()}
              error={
                formik.touched.note_number && Boolean(formik.errors.note_number)
              }
              helperText={
                formik.touched.note_number && formik.errors.note_number
              }
              // onChange={(e) => {
              //   let numericValue = e.target.value;
              //   if (numericValue === "") {
              //     numericValue = "0";
              //   }
              //   formik.setFieldValue(
              //     "note_number",
              //     parseInt(numericValue.replace(/[^0-9]/g, ""), 10),
              //   );
              // }}
            />
          </div>
          <div className="mt-3">
            <p className="font-bold">Nama Distributor / Principal</p>
            <TextField
              fullWidth
              value={formik.values.distributor_name}
              error={
                formik.touched.distributor_name &&
                Boolean(formik.errors.distributor_name)
              }
              helperText={
                formik.touched.distributor_name &&
                formik.errors.distributor_name
              }
              onChange={(e) =>
                formik.setFieldValue("distributor_name", e.target.value)
              }
            />
          </div>
          <div className="mt-3">
            <p className="font-bold">No Telp. Distributor / Principal</p>
            <TextField
              fullWidth
              value={formik.values.distributor_phone}
              error={
                formik.touched.distributor_phone &&
                Boolean(formik.errors.distributor_phone)
              }
              helperText={
                formik.touched.distributor_phone &&
                formik.errors.distributor_phone
              }
              onChange={(e) => {
                let numericValue = e.target.value;
                formik.setFieldValue(
                  "distributor_phone",
                  numericValue.replace(/[^0-9]/g, ""),
                  10
                );
              }}
            />
          </div>
          <div className="mt-3">
            <p className="font-bold">Alamat Distributor / Principal</p>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={formik.values.distributor_address}
              error={
                formik.touched.distributor_address &&
                Boolean(formik.errors.distributor_address)
              }
              helperText={
                formik.touched.distributor_address &&
                formik.errors.distributor_address
              }
              onChange={(e) =>
                formik.setFieldValue("distributor_address", e.target.value)
              }
            />
          </div>

          <div className="w-full flex items-center justify-end mt-4">
            <button
              id="add-product-modal"
              type="button"
              onClick={() => setIsAddProductModalOpen(true)}
              className="flex py-2 px-5 items-center border-2 border-black rounded-md cursor-pointer hover:bg-slate-100"
            >
              <IoIosAddCircleOutline className="mr-2 text-black" />
              <p>Add Product</p>
            </button>
          </div>
        </div>
        <p className="font-bold text-lg my-4">Selected Products</p>
        {formik.values.data_product.length === 0 ? (
          <EmptyList message={"No Selected Products Yet"} className="mb-3" />
        ) : (
          <div className="w-full block overflow-x-auto mb-3">
            <table className="w-full border">
              <thead>
                <tr className="text-center bg-blue-500 text-white">
                  <th className="py-2 ">Invoice Number</th>
                  <th className="py-2">Product Name</th>
                  <th className="py-2">Quantity</th>
                  <th className="py-2">UOM</th>
                  <th className="py-2">Harga Satuan</th>
                  <th className="py-2">Remove</th>
                </tr>
              </thead>
              <tbody>
                {formik.values.data_product.map((item, index) => (
                  <tr className="text-center" key={index}>
                    <td className="py-2 border text-blue-500 underline">
                      {item.invoice_number}
                    </td>
                    <td className="py-2 border">{item.product_name}</td>
                    <td className="py-2 border">
                      <div className="flex justify-center">
                        <button
                          className="rounded-l-md py-2 px-3 bg-[#FF7A5D] disabled:bg-gray-200"
                          disabled={item.retur_quantity <= 0}
                          type="button"
                          onClick={() => {
                            formik.setFieldValue(
                              "data_product",
                              formik.values.data_product.map((data) => {
                                return data.iteration === item.iteration
                                  ? {
                                      ...data,
                                      retur_quantity: item.retur_quantity - 1,
                                      quantity: item.retur_quantity - 1,
                                    }
                                  : data;
                              })
                            );
                          }}
                        >
                          -
                        </button>
                        <input
                          className="bg-slate-100 p-2 w-[15%]"
                          value={item.retur_quantity}
                          onChange={(e) => {
                            let numericValue = e.target.value;
                            if (numericValue === "") {
                              numericValue = "0";
                            }
                            formik.setFieldValue(
                              "data_product",
                              formik.values.data_product.map((data) => {
                                return data.iteration === item.iteration
                                  ? {
                                      ...data,
                                      retur_quantity: parseInt(
                                        numericValue.replace(/[^0-9]/g, ""),
                                        10
                                      ),
                                      quantity: parseInt(
                                        numericValue.replace(/[^0-9]/g, ""),
                                        10
                                      ),
                                    }
                                  : data;
                              })
                            );
                          }}
                        />
                        <button
                          className="rounded-r-md bg-[#5DFFBB] py-2 px-3 disabled:bg-gray-200"
                          disabled={
                            item.product_new_stock <= item.retur_quantity ||
                            item.type_packaging === ""
                          }
                          type="button"
                          onClick={() => {
                            formik.setFieldValue(
                              "data_product",
                              formik.values.data_product.map((data) => {
                                return data.iteration === item.iteration
                                  ? {
                                      ...data,
                                      retur_quantity: item.retur_quantity + 1,
                                      quantity: item.quantity + 1,
                                    }
                                  : data;
                              })
                            );
                          }}
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td className="py-2 border w-[40%]">
                      {item.packaging !== null ? (
                        <FormControl sx={{ width: "40%" }}>
                          <InputLabel id="demo-simple-select-label">
                            UOM
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="UOM"
                            defaultValue=""
                          >
                            <MenuItem
                              value={item.packaging.type_box}
                              onClick={() => {
                                setBuffer((prevValue) =>
                                  prevValue.map((data) => {
                                    return data.iteration === item.iteration
                                      ? {
                                          ...data,
                                          type_packaging:
                                            item.packaging.type_box,
                                        }
                                      : data;
                                  })
                                );
                                formik.setFieldValue(
                                  "data_product",
                                  formik.values.data_product.map((data) => {
                                    return item.iteration === data.iteration
                                      ? {
                                          ...data,
                                          type_packaging:
                                            item.packaging.type_box,
                                        }
                                      : data;
                                  })
                                );
                              }}
                            >
                              {item.packaging.type_box}
                            </MenuItem>
                            <MenuItem
                              value={item.packaging.type_strip}
                              onClick={() => {
                                setBuffer((prevValue) =>
                                  prevValue.map((data) => {
                                    return data.iteration === item.iteration
                                      ? {
                                          ...data,
                                          type_packaging:
                                            item.packaging.type_strip,
                                        }
                                      : data;
                                  })
                                );
                                formik.setFieldValue(
                                  "data_product",
                                  formik.values.data_product.map((data) => {
                                    return item.iteration === data.iteration
                                      ? {
                                          ...data,
                                          type_packaging:
                                            item.packaging.type_strip,
                                        }
                                      : data;
                                  })
                                );
                              }}
                            >
                              {item.packaging.type_strip}
                            </MenuItem>
                            {IGNORE_TYPE.includes(
                              item.packaging.type_unit
                            ) ? null : (
                              <MenuItem
                                value={item.packaging.type_unit}
                                onClick={() => {
                                  setBuffer((prevValue) =>
                                    prevValue.map((data) => {
                                      return data.iteration === item.iteration
                                        ? {
                                            ...data,
                                            type_packaging:
                                              item.packaging.type_unit,
                                          }
                                        : data;
                                    })
                                  );
                                  formik.setFieldValue(
                                    "data_product",
                                    formik.values.data_product.map((data) => {
                                      return item.iteration === data.iteration
                                        ? {
                                            ...data,
                                            type_packaging:
                                              item.packaging.type_unit,
                                          }
                                        : data;
                                    })
                                  );
                                }}
                              >
                                {item.packaging.type_unit}
                              </MenuItem>
                            )}
                            <MenuItem
                              value={""}
                              sx={{ display: "none" }}
                              disabled
                            ></MenuItem>
                          </Select>
                        </FormControl>
                      ) : null}
                    </td>
                    <td className="py-2 border">
                      {calculateStock(item).toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </td>
                    <td className="py-2 border">
                      <div className="w-full flex justify-center">
                        <div
                          onClick={() => {
                            formik.setFieldValue(
                              "data_product",
                              formik.values.data_product.filter(
                                (data) => data.iteration !== item.iteration
                              )
                            );
                            setBuffer((prevValue) =>
                              prevValue.filter(
                                (data) => data.iteration !== item.iteration
                              )
                            );
                          }}
                          className="bg-red-500 rounded-md p-1 flex cursor-pointer justify-center-items-center text-white hover:bg-red-700"
                        >
                          <BsTrash />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-end font-bold mr-3 my-3">
              <div>
                <p>Total Harga:</p>
                <p>
                  Rp.{" "}
                  {formik.values.data_product
                    .map((data) => {
                      if (data.type_packaging === data.packaging.type_box) {
                        return data.pharmacy_net_price * data.retur_quantity;
                      } else if (
                        data.type_packaging === data.packaging.type_strip
                      ) {
                        return (
                          (data.pharmacy_net_price * data.retur_quantity) /
                          data.packaging.total_strip
                        );
                      } else {
                        return (
                          (data.pharmacy_net_price * data.retur_quantity) /
                          (data.packaging.total_strip *
                            data.packaging.total_unit)
                        );
                      }
                    })
                    .reduce((acc, curr) => {
                      return acc + curr;
                    }, 0)
                    .toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                </p>
              </div>
            </div>
            <div className="flex justify-center">
              <button
                className="bg-blue-500 rounded-md px-[100px] py-2.5 font-bold text-white mt-3 disabled:bg-gray-200"
                disabled={
                  formik.values.data_product
                    .map((data) => data.type_packaging)
                    .includes("") ||
                  formik.values.data_product
                    .map((data) => data.retur_quantity)
                    .includes(0)
                }
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        )}
        <Dialog
          open={isAddProductModalOpen}
          onClose={() => setIsAddProductModalOpen(false)}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>Pilih Produk</DialogTitle>
          <DialogContent>
            <CreateReturProductModal
              productReturList={productReturList}
              isProductReturListLoading={isProductReturListLoading}
              IGNORE_TYPE={IGNORE_TYPE}
              formik={formik}
              buffer={buffer}
              setBuffer={setBuffer}
              productReturListBuffer={productReturListBuffer}
              setProductBufferListBuffer={setProductBufferListBuffer}
              offset={offset}
              setOffset={setOffset}
              searchProduct={searchProduct}
              setSearchProduct={setSearchProduct}
              debounce={debounce}
            />
          </DialogContent>
          <DialogActions>
            <div className="flex justify-center w-full h-[40px] font-bold">
              <button
                className="rounded border w-full mr-1"
                onClick={() => setIsAddProductModalOpen(false)}
              >
                Batal
              </button>
              <button
                className="bg-[#007AF1] rounded text-white w-full disabled:bg-gray-200"
                disabled={
                  productReturListBuffer === undefined ||
                  productReturListBuffer === null
                }
                onClick={() => {
                  formik.setFieldValue("data_product", buffer);
                  setIsAddProductModalOpen(false);
                }}
              >
                Simpan
              </button>
            </div>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={5000}
          onClose={() => setErrorMessage("")}
          message={errorMessage}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={(_, reason) => {
                  if (reason === "clickaway") {
                    return;
                  }
                  setErrorMessage("");
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </div>
    </form>
  );
};

export default CreateReturProduct;
