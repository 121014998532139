import React, { useState, useCallback, useEffect, useMemo } from "react";
import cloneDeep from "lodash/cloneDeep";
import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Pagination,
} from "@mui/material";
import {
  FilterAlt as FilterAltIcon,
  MoreHoriz as MoreHorizIcon,
  Sort as SortIcon,
} from "@mui/icons-material";

import ModalDeleteProducts from "../../../common/particles.jsx/ModalDeleteProducts";
import ModalActivateProducts from "../../../common/particles.jsx/ModalActivateProducts";
import { sortOptions } from "../../../constants/constant";
import { SlOptionsVertical } from "react-icons/sl";
import useDebounce from "../../../hooks/useDebounce";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import {
  fetchProductList,
  activateProducts,
} from "../../../../services/InventoryAPI";
import Label from "../../../common/particles.jsx/Label";
import Category from "../../../common/particles.jsx/Category";
import Principal from "../../../common/particles.jsx/Principal";
import InventoryNavigation from "../InventoryNavigation";

const FETCH_LIMIT = 10;
const STATUS_PRODUCT = 2;

const ProductInactive = () => {
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [productId, setProductId] = useState([]);
  const [anchorElProduct, setAnchorElProduct] = useState(null);
  const [anchorElSort, setAnchorElSort] = useState(null);

  const [selectSortValue, setSelectSortValue] = useState(null);
  const [selectSortName, setSelectSortName] = useState(null);

  const [listId, setListId] = useState([]);

  const [offset, setOffset] = useState(1);
  const [type, setType] = useState("");

  const [checked, setChecked] = useState(false);

  const [openCategoryFilter, setOpenCategoryFilter] = useState(false);
  const [openLabelFilter, setOpenLabelFilter] = useState(false);
  const [openPrincipalFilter, setOpenPrincipalFilter] = useState(false);

  const [search, setSearch] = useState("");

  const debounce = useDebounce(search, 1000);

  const {
    mutate: mutateProductList,
    data: products,
    isLoading,
  } = useMutation({
    mutationFn: fetchProductList,
  });

  const { mutate: mutateActivateProduct } = useMutation({
    mutationFn: activateProducts,
    onSuccess: () => {
      setOpenActivateModal(false);
      window.location.reload();
    },
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleSelectList = useCallback(
    (selectedId) => {
      const selectedValue = productId.findIndex((id) => id === selectedId);
      let updatedList = cloneDeep(productId);

      if (selectedValue !== -1) {
        updatedList.splice(selectedValue, 1);
      } else {
        updatedList.push(selectedId);
      }
      setProductId(updatedList);
    },
    [productId]
  );

  const pageCount = useMemo(() => {
    if (products === undefined) return 0;
    else if (products.jumlah_product === undefined) return 0;
    else return Math.ceil(products.jumlah_product / FETCH_LIMIT);
  }, [products]);

  useEffect(() => {
    if (
      openLabelFilter === true ||
      openCategoryFilter === true ||
      openPrincipalFilter === true
    )
      setType("");
  }, [openLabelFilter, openCategoryFilter, openPrincipalFilter]);

  useEffect(() => {
    if (debounce !== "") setOffset(1);
  }, [debounce]);

  useEffect(() => {
    mutateProductList({
      status: STATUS_PRODUCT,
      limit: FETCH_LIMIT,
      type,
      offset,
      list_id: listId,
      name: debounce,
      sort: selectSortValue,
    });
  }, [offset, debounce, type, listId, selectSortValue]);

  const productData =
    products && products.product_data !== null
      ? products.product_data.map((p) => p.product_id)
      : [];

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <InventoryNavigation />
      <ModalActivateProducts
        open={openActivateModal}
        setOpen={setOpenActivateModal}
        productId={productId}
        mutateActivateProduct={mutateActivateProduct}
      />
      <ModalDeleteProducts
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        productId={productId}
      />
      <div
        className="offcanvas offcanvas-bottom fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-1/3 max-h-full"
        tabIndex="-1"
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header flex items-center justify-between p-4">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold"
            id="offcanvasBottomLabel"
          >
            Offcanvas bottom
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow p-4 overflow-y-auto small">
          ...
        </div>
      </div>

      {/* <div className="flex flex-wrap w-full  mt-2 items-center gap-2"> */}
      <div className="flex w-full  mt-2 items-center gap-2">
        <div className="max-w-4xl w-full ">
          <label htmlFor="search" className="sr-only">
            Search{" "}
          </label>
          <div methode="get" action="#" className="relative z-10">
            <button
              type="submit"
              id="searchsubmit"
              className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <svg
                className="h-5 w-5 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border  rounded-md leading-5 bg-white text-black placeholder-gray-400  sm:text-sm transition duration-150 ease-in-out"
              placeholder="Cari produk"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div>
          <Button
            className="py-[7px] rounded-lg px-3 border flex items-center gap-2 bg-white"
            variant="contained"
            aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <FilterAltIcon sx={{ color: "black" }} />
            <p className="text-black">Filter</p>
          </Button>
        </div>
        <>
          <div>
            <button
              className={`bg-blue-500 hover:bg-grey-200 font-normal py-2 px-4 rounded-lg inline-flex items-center gap-3 disabled:bg-gray-300`}
              onClick={() => setOpenDeleteModal(true)}
              disabled={productId.length === 0 ? true : false}
            >
              <span className={`text-white`}>Hapus</span>
            </button>
          </div>
          <div>
            <button
              className={`bg-orange-500 hover:bg-grey-200 font-normal py-2 px-[28px] rounded-lg inline-flex items-center gap-3 disabled:bg-gray-300`}
              onClick={() => {
                setOpenActivateModal(true);
                setAnchorEl(false);
              }}
              disabled={productId.length === 0 ? true : false}
            >
              <span className={`text-white`}>Activate</span>
            </button>
          </div>
          <button
            className="border border-black py-2 px-3 rounded-md inline-flex items-center"
            aria-controls={Boolean(anchorElSort) ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorElSort) ? "true" : undefined}
            onClick={(e) => setAnchorElSort(e.currentTarget)}
          >
            <span className="mr-2">
              <SortIcon />
            </span>
            <span>Urut Berdasarkan {selectSortName}</span>
          </button>
        </>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center mt-5">
          <CircularProgress />
        </div>
      ) : (
        <>
          {products && products.product_data !== null ? (
            <>
              <div className="flex items-center mt-5">
                <input
                  id="allSelect"
                  type="checkbox"
                  name="allSelect"
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600  focus:ring-2 "
                  checked={checked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setProductId(productData);
                    } else {
                      setProductId([]);
                    }
                    setChecked(e.target.checked);
                  }}
                />
                <label
                  htmlFor="checked-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900"
                >
                  Pilih Semua produk
                </label>
              </div>

              {products.product_data.map((item, index) => {
                return (
                  <div
                    className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between"
                    key={item.product_id}
                  >
                    <div className="flex items-center">
                      <input
                        key={index}
                        id={item.product_id}
                        type="checkbox"
                        name={item.product_name}
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600  focus:ring-2 "
                        checked={productId.includes(item.product_id)}
                        onChange={() => handleSelectList(item.product_id)}
                      />
                      <img
                        src={item.media}
                        alt="foto_product"
                        className="w-20 mx-2"
                      />
                      <div className="flex flex-col text-sm lg:text-base gap-1 text-clip overflow-hidden">
                        <p>
                          <Link
                            to={`/inventory/produk-inactive/detail-product/${item.product_id}`}
                            className="hover:none"
                          >
                            {item.product_name}
                          </Link>
                        </p>
                        <p className="font-bold">
                          Rp. {item.sell_price} /
                          {item.packing !== null && item.packing !== undefined
                            ? item.packing.type_box
                            : null}
                        </p>
                        <p>{item.distributor_name}</p>
                        <p>Jumlah: {item.packaging_detail}</p>
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <Button
                        className="py-[7px] rounded-lg px-3 border flex items-center gap-2 bg-white "
                        variant="contained"
                        aria-controls={
                          Boolean(anchorElProduct) ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                          Boolean(anchorElProduct) ? "true" : undefined
                        }
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                          setProductId([item.product_id]);
                        }}
                      >
                        <MoreHorizIcon sx={{ color: "black" }} />
                      </Button>
                    </div>
                    <div className="block md:hidden">
                      <SlOptionsVertical />
                    </div>
                  </div>
                );
              })}

              <div className="flex justify-center mt-[10px]">
                <Pagination
                  count={pageCount}
                  page={offset}
                  onChange={(_, value) => setOffset(value)}
                  shape="rounded"
                  size="large"
                  color="primary"
                />
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center mt-5">
              <div className="flex flex-col items-center">
                {/* <img src={Empty} alt="empty" className="w-1/2" /> */}
                <p className="text-gray-500">Tidak ada produk</p>
              </div>
            </div>
          )}
        </>
      )}
      <Menu
        anchorEl={anchorElSort}
        open={Boolean(anchorElSort)}
        onClose={() => setAnchorElSort(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {sortOptions.map((option) => (
          <MenuItem
            onClick={() => {
              setAnchorElSort(null);
              setSelectSortValue(option.value);
              setSelectSortName(option.name);
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          value={productId}
          onClick={() => {
            setOpenActivateModal(true);
          }}
        >
          Activate
        </MenuItem>
        <MenuItem
          value={productId}
          onClick={() => {
            setOpenDeleteModal(true);
            setAnchorEl(null);
          }}
        >
          Hapus
        </MenuItem>
      </Menu>
      <Dialog
        open={openCategoryFilter}
        onClose={() => setOpenCategoryFilter(false)}
      >
        <DialogTitle>
          Kategori
          {listId.length > 0 ? (
            <Button
              variant="contained"
              sx={{ marginLeft: 1 }}
              onClick={() => {
                setType("category");
                setOpenCategoryFilter(false);
              }}
            >
              Cari
            </Button>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Category
            status={STATUS_PRODUCT}
            offset={offset}
            limit={FETCH_LIMIT}
            listId={listId}
            setListId={setListId}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openLabelFilter} onClose={() => setOpenLabelFilter(false)}>
        <DialogTitle>
          Golongan
          {listId.length > 0 ? (
            <Button
              variant="contained"
              sx={{ marginLeft: 1 }}
              onClick={() => {
                setType("label");
                setOpenLabelFilter(false);
              }}
            >
              Cari
            </Button>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Label listId={listId} setListId={setListId} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openPrincipalFilter}
        onClose={() => setOpenPrincipalFilter(false)}
      >
        <DialogTitle>
          Principal
          {listId.length > 0 ? (
            <Button
              variant="contained"
              sx={{ marginLeft: 1 }}
              onClick={() => {
                setType("principal");
                setOpenPrincipalFilter(false);
              }}
            >
              Cari
            </Button>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Principal
            status={STATUS_PRODUCT}
            offset={offset}
            limit={FETCH_LIMIT}
            listId={listId}
            setListId={setListId}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProductInactive;
