import React from "react";
import Header from "../../../components/ui/Header";
import SidebarComponents from "../../../components/ui/SidebarComponents";
import StockAdjustment from "../../../components/screen/inventory/StockAdjustment/StockAdjustment";
import { SIDEBAR_WIDTH } from "../../../components/constants/constant";

const StockAdjustmentPage = () => {
  return (
    <div className="">
      <div className="flex">
        <SidebarComponents />
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            Stock Adjustment
          </h1>
          <StockAdjustment />
        </div>
      </div>
    </div>
  );
};

export default StockAdjustmentPage;
