import { useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { cancelOrder, getDrugData } from "../../../services/OrderApi";
import CopyRecipePreview from "./CopyRecipePreview";
import { FiMinusCircle } from "react-icons/fi";
import { LuDownload } from "react-icons/lu";
import { IoPrintOutline } from "react-icons/io5";
import { debounce } from "lodash";

const toDate = (date) => moment(date).format("YYYY-MM-DD");

const CopyRecipeDoctor = (props) => {
  const {
    dataBuffer,
    setDataBuffer,
    cancelModal,
    setCancelModal,
    makeCopyRecipe,
    detailStatus,
    recipeCopyBody,
    setRecipeCopyBody,
    addCopyRecipe,
    setAddCopyRecipe,
    setRecipeCred,
    prescriptionImg,
  } = props;

  const componentRef = useRef();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [printCount, setPrintCount] = useState(1);
  const [downloadCount, setDownloadCount] = useState(1);
  const [suggestions, setSuggestions] = useState([]);
  const [uomOptions, setUomOptions] = useState([]);
  const [inputValues, setInputValues] = useState([]);

  const { mutate: mutateCancelOrder } = useMutation({
    mutationFn: cancelOrder,
    onSuccess() {
      setCancelModal({
        isModal: false,
        cancel_reason: "",
      });
    },
  });

  const { refetch } = useQuery(
    ["product-data", inputValues[currentIndex] || ""],
    () => getDrugData(inputValues[currentIndex] || ""),
    {
      enabled: false,
      onSuccess: (fetchedData) => {
        if (fetchedData && fetchedData.length > 0) {
          const filteredData = fetchedData.filter((item) =>
            item.product_name
              .toLowerCase()
              .includes(inputValues[currentIndex]?.toLowerCase() || "")
          );
          setSuggestions(filteredData.slice(0, 5));
        } else {
          setSuggestions([]);
        }
      },
      onError: (error) => {
        console.error("Error fetching drug data:", error);
        setSuggestions([]);
      },
    }
  );

  const debouncedFetchDrugData = useCallback(
    debounce((query) => {
      refetch();
    }, 300),
    [refetch]
  );

  useEffect(() => {
    if (inputValues[currentIndex]?.trim()) {
      debouncedFetchDrugData(inputValues[currentIndex]);
    } else {
      setSuggestions([]);
    }
  }, [inputValues[currentIndex], debouncedFetchDrugData]);

  const handleInputChange = (e, drugIndex) => {
    const value = e.target.value;

    setInputValues((prevValues) => ({
      ...prevValues,
      [currentIndex]: value,
    }));

    setRecipeCopyBody((prevValue) =>
      prevValue.map((item, idx) => {
        if (idx === currentIndex) {
          const updatedDrug = [...item.drug];
          updatedDrug[drugIndex] = {
            ...updatedDrug[drugIndex],
            drug_name: value,
          };
          return {
            ...item,
            drug: updatedDrug,
          };
        }
        return item;
      })
    );

    if (!suggestions.some((suggestion) => suggestion.product_name === value)) {
      setUomOptions([]);
    }
  };

  const handleSuggestionClick = (suggestion, selectedDrugIndex) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [currentIndex]: suggestion.product_name,
    }));

    setRecipeCopyBody((prevValue) =>
      prevValue.map((item, idx) => {
        if (idx === currentIndex) {
          const updatedDrug = [...item.drug];
          updatedDrug[selectedDrugIndex] = {
            ...updatedDrug[selectedDrugIndex],
            drug_name: suggestion.product_name,
          };
          return {
            ...item,
            drug: updatedDrug,
          };
        }
        return item;
      })
    );

    const newUomOptions = [
      suggestion.uom_box,
      suggestion.uom_strip,
      suggestion.uom_unit,
    ].filter(Boolean);
    setUomOptions(newUomOptions);

    setSuggestions([]);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Resep ${printCount}`,
    pageStyle: `
      @media print {
        @page {
          margin: 0; /* Menghilangkan margin halaman */
        }
        body {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        }
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        }
        img {
          margin: auto;
          display: block;
          max-width: 100%;
          max-height: 100vh;
        }
      }
    `,
    onAfterPrint: () => setPrintCount(printCount + 1),
  });

  return (
    <div>
      {dataBuffer && dataBuffer !== undefined ? (
        <div>
          <div className="flex h-[calc(100vh-244px)]">
            <div className="flex-1 h-full overflow-y-auto">
              <p className="font-semibold">Resep {currentIndex + 1}</p>
              <div>
                <div className="w-full h-full mb-8">
                  <div className="relative flex w-full items-center justify-center">
                    {prescriptionImg && (
                      <div className="flex flex-col">
                        <img
                          ref={componentRef}
                          src={prescriptionImg[currentIndex]}
                          alt={`Prescription ${currentIndex + 1}`}
                          className="mt-2"
                          style={{ maxWidth: "100%", maxHeight: "500px" }}
                        />
                        <div className="w-full flex justify-end mt-2">
                          <button
                            className="p-2 text-[12px] font-bold rounded-md mr-2 bg-blue-500 text-white border-blue-500 hover:bg-blue-700"
                            onClick={handlePrint}
                          >
                            <IoPrintOutline className="text-[26px]" />
                          </button>
                          <button
                            className="p-2 text-[12px] font-bold rounded-md mr-2 bg-blue-500 text-white border-blue-500 hover:bg-blue-700"
                            onClick={async () => {
                              try {
                                const response = await fetch(
                                  prescriptionImg[currentIndex],
                                  {
                                    mode: "cors",
                                  }
                                );
                                const blob = await response.blob();
                                const link = document.createElement("a");
                                const fileName = `Resep-Digital-${downloadCount}.png`;
                                link.href = URL.createObjectURL(blob);
                                link.download = fileName;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                URL.revokeObjectURL(link.href);
                                setDownloadCount(downloadCount + 1);
                              } catch (error) {
                                console.error(
                                  "Failed to download image:",
                                  error
                                );
                              }
                            }}
                          >
                            <LuDownload className="text-[26px]" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {addCopyRecipe[currentIndex] && recipeCopyBody?.length > 0 ? (
                  <div className="mb-4">
                    <div className="w-full">
                      <label className="block text-sm font-medium text-black mb-1">
                        Nama Dokter <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Tulis nama dokter"
                        className="w-full mb-2 h-11 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        value={recipeCopyBody[currentIndex]?.doctor_name || ""}
                        onChange={(e) => {
                          const newDoctorName = e.target.value;
                          setRecipeCred((prevValue) => ({
                            ...prevValue,
                            doctor_name: newDoctorName,
                          }));
                          setRecipeCopyBody((prevValue) =>
                            prevValue.map((item, idx) => {
                              if (idx === currentIndex) {
                                return {
                                  ...item,
                                  doctor_name: newDoctorName,
                                };
                              }
                              return item;
                            })
                          );
                        }}
                      />
                    </div>
                    <div className="w-[100%]">
                      <div className="w-full mb-2">
                        <label className="block text-sm font-medium text-black mb-1">
                          Tanggal Resep <span className="text-red-500">*</span>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopDatePicker
                            onChange={(newExpiredDate) => {
                              setRecipeCopyBody((prevValue) =>
                                prevValue.map((item, idx) => {
                                  if (idx === currentIndex) {
                                    return {
                                      ...item,
                                      recipe_date: toDate(newExpiredDate),
                                    };
                                  }
                                  return item;
                                })
                              );
                            }}
                            value={
                              recipeCopyBody[currentIndex]?.recipe_date || null
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  fullWidth
                                  sx={{
                                    marginBottom: 0,
                                    borderRadius: "8px",
                                    "& .MuiOutlinedInput-root": {
                                      borderRadius: "8px",
                                      height: "48px",
                                      padding: "0",
                                    },
                                    "& .MuiInputAdornment-root": {
                                      marginRight: "16px",
                                    },
                                  }}
                                />
                              );
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="w-full">
                      <label className="block text-sm font-medium text-black mb-1">
                        Pro <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Tulis nama customer"
                        className="w-full mb-2 h-11 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        value={recipeCopyBody[currentIndex]?.pro || ""}
                        onChange={(e) => {
                          const newProValue = e.target.value;
                          setRecipeCopyBody((prevValue) =>
                            prevValue.map((item, idx) => {
                              if (idx === currentIndex) {
                                return {
                                  ...item,
                                  pro: newProValue,
                                };
                              }
                              return item;
                            })
                          );
                        }}
                      />
                    </div>
                    <div className="w-full relative">
                      <label className="block text-sm font-medium text-black mb-1">
                        Umur <span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          className="w-full mb-2 h-11 pl-4 pr-16 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                          value={recipeCopyBody[currentIndex]?.age || 0}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            const newAgeValue =
                              numericValue === ""
                                ? 0
                                : parseInt(numericValue, 10);
                            setRecipeCopyBody((prevValue) =>
                              prevValue.map((item, idx) => {
                                if (idx === currentIndex) {
                                  return {
                                    ...item,
                                    age: newAgeValue,
                                  };
                                }
                                return item;
                              })
                            );
                          }}
                          inputMode="numeric"
                          pattern="[0-9]*"
                        />
                        <span className="absolute right-4 top-6 transform -translate-y-1/2 text-gray-500">
                          Tahun
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {addCopyRecipe[currentIndex] &&
                recipeCopyBody[currentIndex]?.drug?.length > 0
                  ? recipeCopyBody[currentIndex]?.drug?.map(
                      (data, drugIndex) => (
                        <div
                          key={drugIndex}
                          className="border border-black rounded-md p-3 mb-3"
                        >
                          <>
                            <div className="w-full flex items-center justify-between">
                              <p className="font-semibold">
                                Obat {drugIndex + 1}
                              </p>
                              <FiMinusCircle
                                className="text-red-500 cursor-pointer hover:text-red-700"
                                onClick={() => {
                                  setRecipeCopyBody((prevValue) =>
                                    prevValue.map((item, idx) => {
                                      if (idx === currentIndex) {
                                        const updatedDrug = [...item.drug];
                                        updatedDrug.splice(drugIndex, 1);
                                        return {
                                          ...item,
                                          drug: updatedDrug,
                                        };
                                      }
                                      return item;
                                    })
                                  );
                                }}
                              />
                            </div>
                            <div className="border border-black w-full mt-2 mb-3"></div>
                            <div className="w-full flex items-center mb-2">
                              <p className="text-[24px] w-10 font-semibold">
                                R/
                              </p>
                              <div
                                className="w-full"
                                style={{ position: "relative" }}
                              >
                                <TextField
                                  fullWidth
                                  sx={{
                                    marginBottom: 1,
                                    "& .MuiOutlinedInput-root": {
                                      height: "48px",
                                      padding: "0 8px",
                                      borderRadius: "8px",
                                    },
                                    "& .MuiInputLabel-root": {
                                      top: "-5px",
                                    },
                                    "& .MuiInputBase-input": {
                                      padding: "8px 4px",
                                    },
                                  }}
                                  value={
                                    inputValues[
                                      `${currentIndex}-${drugIndex}`
                                    ] || data.drug_name
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, drugIndex)
                                  }
                                  label={
                                    <span>
                                      Nama Obat{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </span>
                                  }
                                />
                                {suggestions.length > 0 && (
                                  <div
                                    style={{
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                      maxHeight: "150px",
                                      overflowY: "auto",
                                      position: "absolute",
                                      zIndex: 1000,
                                      backgroundColor: "#fff",
                                      width: "100%",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    {suggestions.map((suggestion, index) => (
                                      <MenuItem
                                        key={index}
                                        onClick={() =>
                                          handleSuggestionClick(
                                            suggestion,
                                            drugIndex
                                          )
                                        }
                                      >
                                        {suggestion.product_name}
                                      </MenuItem>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="w-10"></div>
                              <div className="flex w-full gap-2">
                                <Autocomplete
                                  sx={{
                                    width: "100%",
                                    marginBottom: 1,
                                    "& .MuiOutlinedInput-root": {
                                      height: "48px",
                                      padding: "0 8px",
                                      borderRadius: "8px",
                                    },
                                    "& .MuiInputLabel-root": {
                                      top: "-5px",
                                    },
                                    "& .MuiInputBase-input": {
                                      padding: "8px 4px",
                                    },
                                  }}
                                  disablePortal
                                  freeSolo
                                  value={data.uom || ""}
                                  onChange={(_, option) => {
                                    setRecipeCopyBody((prevValue) =>
                                      prevValue.map((item, idx) => {
                                        if (idx === currentIndex) {
                                          const updatedDrug = [...item.drug];
                                          updatedDrug[drugIndex] = {
                                            ...updatedDrug[drugIndex],
                                            uom: option || "",
                                          };
                                          return {
                                            ...item,
                                            drug: updatedDrug,
                                          };
                                        }
                                        return item;
                                      })
                                    );
                                  }}
                                  onInputChange={(_, newInputValue) => {
                                    setRecipeCopyBody((prevValue) =>
                                      prevValue.map((item, idx) => {
                                        if (idx === currentIndex) {
                                          const updatedDrug = [...item.drug];
                                          updatedDrug[drugIndex] = {
                                            ...updatedDrug[drugIndex],
                                            uom: newInputValue || "",
                                          };
                                          return {
                                            ...item,
                                            drug: updatedDrug,
                                          };
                                        }
                                        return item;
                                      })
                                    );
                                  }}
                                  options={
                                    uomOptions.length > 0 ? uomOptions : []
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={
                                        <span>
                                          UOM{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      }
                                    />
                                  )}
                                />
                                <TextField
                                  fullWidth
                                  sx={{
                                    marginBottom: 1,
                                    "& .MuiOutlinedInput-root": {
                                      height: "48px",
                                      padding: "0 8px",
                                      borderRadius: "8px",
                                    },
                                    "& .MuiInputLabel-root": {
                                      top: "-5px",
                                    },
                                    "& .MuiInputBase-input": {
                                      padding: "8px 4px",
                                    },
                                  }}
                                  value={
                                    recipeCopyBody[currentIndex]?.drug[
                                      drugIndex
                                    ]?.quantity || ""
                                  }
                                  label={
                                    <span>
                                      QTY{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </span>
                                  }
                                  onChange={(e) => {
                                    const numericValue = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setRecipeCopyBody((prevValue) =>
                                      prevValue.map((item, idx) => {
                                        if (idx === currentIndex) {
                                          const updatedDrug = [...item.drug];
                                          updatedDrug[drugIndex] = {
                                            ...updatedDrug[drugIndex],
                                            quantity:
                                              parseInt(numericValue, 10) || 0,
                                          };
                                          return {
                                            ...item,
                                            drug: updatedDrug,
                                          };
                                        }
                                        return item;
                                      })
                                    );
                                  }}
                                  onKeyDown={(e) => {
                                    if (
                                      !/[0-9]/.test(e.key) &&
                                      e.key !== "Backspace" &&
                                      e.key !== "ArrowLeft" &&
                                      e.key !== "ArrowRight"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                />
                              </div>
                            </div>
                            <div className="w-full flex items-center mb-2">
                              <p className="text-[24px] w-10 font-semibold">
                                S
                              </p>
                              <TextField
                                fullWidth
                                sx={{
                                  marginBottom: 1,
                                  "& .MuiOutlinedInput-root": {
                                    height: "48px",
                                    padding: "0 8px",
                                    borderRadius: "8px",
                                  },
                                  "& .MuiInputLabel-root": {
                                    top: "-5px",
                                  },
                                  "& .MuiInputBase-input": {
                                    padding: "8px 4px",
                                  },
                                }}
                                label={
                                  <span>
                                    Cara Pakai{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </span>
                                }
                                value={
                                  recipeCopyBody[currentIndex]?.drug[drugIndex]
                                    ?.instruction_use || ""
                                }
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  setRecipeCopyBody((prevValue) =>
                                    prevValue.map((item, idx) => {
                                      if (idx === currentIndex) {
                                        const updatedDrug = [...item.drug];
                                        updatedDrug[drugIndex] = {
                                          ...updatedDrug[drugIndex],
                                          instruction_use: inputValue,
                                        };
                                        return {
                                          ...item,
                                          drug: updatedDrug,
                                        };
                                      }
                                      return item;
                                    })
                                  );
                                }}
                              />
                            </div>
                            <div className="flex gap-3 items-center justify-end">
                              <p>Iter</p>
                              <TextField
                                fullWidth
                                value={data.iter_qty || 0}
                                sx={{
                                  width: "100px",
                                  marginBottom: 1,
                                  "& .MuiOutlinedInput-root": {
                                    height: "48px",
                                    padding: "0 8px",
                                    borderRadius: "8px",
                                  },
                                  "& .MuiInputLabel-root": {
                                    top: "-5px",
                                  },
                                  "& .MuiInputBase-input": {
                                    padding: "8px 4px",
                                  },
                                }}
                                onChange={(e) => {
                                  const numericValue = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setRecipeCopyBody((prevValue) =>
                                    prevValue.map((item, idx) => {
                                      if (idx === currentIndex) {
                                        const updatedDrug = [...item.drug];
                                        updatedDrug[drugIndex] = {
                                          ...updatedDrug[drugIndex],
                                          iter_qty:
                                            parseInt(numericValue, 10) || 0,
                                        };
                                        return {
                                          ...item,
                                          drug: updatedDrug,
                                        };
                                      }
                                      return item;
                                    })
                                  );
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]/.test(e.key) &&
                                    e.key !== "Backspace" &&
                                    e.key !== "ArrowLeft" &&
                                    e.key !== "ArrowRight"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                inputMode="numeric"
                                pattern="[0-9]*"
                              />
                            </div>
                            <div className="border border-black w-full mt-2 mb-3"></div>
                            <div className="flex gap-3 item-center mb-2">
                              <p className="w-24 mt-2">Tipe det</p>
                              <FormControl
                                sx={{
                                  width: "100%",
                                  marginBottom: 1,
                                  "& .MuiOutlinedInput-root": {
                                    height: "48px",
                                    padding: "0 8px",
                                    borderRadius: "8px",
                                  },
                                  "& .MuiInputLabel-root": {
                                    top: "-5px",
                                  },
                                  "& .MuiInputBase-input": {
                                    padding: "8px 4px",
                                  },
                                }}
                              >
                                <Select
                                  value={data.det_type || ""}
                                  displayEmpty
                                  onChange={(e) => {
                                    setRecipeCopyBody((prevValue) =>
                                      prevValue.map((item, idx) => {
                                        if (idx === currentIndex) {
                                          const updatedDrug = [...item.drug];
                                          updatedDrug[drugIndex] = {
                                            ...updatedDrug[drugIndex],
                                            det_type: e.target.value,
                                          };
                                          return {
                                            ...item,
                                            drug: updatedDrug,
                                          };
                                        }
                                        return item;
                                      })
                                    );
                                  }}
                                >
                                  <MenuItem value="" disabled>
                                    Pilih tipe det
                                  </MenuItem>
                                  <MenuItem value={"Det Orig"}>
                                    Det Orig
                                  </MenuItem>
                                  <MenuItem value={"Det"}>Det</MenuItem>
                                  <MenuItem value={"Ne Det"}>Ne Det</MenuItem>
                                  <MenuItem value={"Det Iter"}>
                                    Det Iter
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            {data.det_type === "Det Iter" ? (
                              <div className="flex justify-end">
                                <TextField
                                  fullWidth
                                  placeholder="0"
                                  required
                                  error={
                                    !data.det_iter_qty || data.det_iter_qty <= 0
                                  }
                                  value={data.det_iter_qty || ""}
                                  sx={{
                                    width: "100px",
                                    marginBottom: 1,
                                    "& .MuiOutlinedInput-root": {
                                      height: "48px",
                                      padding: "0 8px",
                                      borderRadius: "8px",
                                    },
                                    "& .MuiInputLabel-root": {
                                      top: "-5px",
                                    },
                                    "& .MuiInputBase-input": {
                                      padding: "8px 4px",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor:
                                        !data.det_iter_qty ||
                                        data.det_iter_qty <= 0
                                          ? "red"
                                          : "",
                                    },
                                  }}
                                  onChange={(e) => {
                                    const numericValue = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setRecipeCopyBody((prevValue) =>
                                      prevValue.map((item, idx) => {
                                        if (idx === currentIndex) {
                                          const updatedDrug = [...item.drug];
                                          updatedDrug[drugIndex] = {
                                            ...updatedDrug[drugIndex],
                                            det_iter_qty:
                                              parseInt(numericValue, 10) || 0,
                                          };
                                          return {
                                            ...item,
                                            drug: updatedDrug,
                                          };
                                        }
                                        return item;
                                      })
                                    );
                                  }}
                                  onKeyDown={(e) => {
                                    if (
                                      !/[0-9]/.test(e.key) &&
                                      e.key !== "Backspace" &&
                                      e.key !== "ArrowLeft" &&
                                      e.key !== "ArrowRight"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                />
                              </div>
                            ) : null}
                          </>
                        </div>
                      )
                    )
                  : null}
                {addCopyRecipe[currentIndex] ? (
                  <Tooltip title="Silahkan isi form informasi terlebih dahulu">
                    <button
                      className="mb-4 px-3 py-2 rounded-md border-2 border-blue-500 text-blue-500 font-bold w-full disabled:border-gray-300 disabled:text-gray-300"
                      disabled={
                        recipeCopyBody[currentIndex]?.doctor_name?.length ===
                          0 ||
                        recipeCopyBody[currentIndex]?.pro.length === 0 ||
                        recipeCopyBody[currentIndex]?.age === 0
                      }
                      onClick={() => {
                        setRecipeCopyBody((prevValue) => {
                          const existingRecipe = prevValue[currentIndex];
                          if (existingRecipe) {
                            return prevValue.map((item, index) => {
                              if (index === currentIndex) {
                                return {
                                  ...item,
                                  drug: [
                                    ...item.drug,
                                    {
                                      drug_name: "",
                                      instruction_use: "",
                                      quantity: 0,
                                      uom: "",
                                      det_type: "",
                                      det_iter_qty: 0,
                                      iter_qty: 0,
                                    },
                                  ],
                                };
                              }
                              return item;
                            });
                          } else {
                            return [
                              ...prevValue,
                              {
                                doctor_name: "",
                                recipe_date: moment().format("YYYY-MM-DD"),
                                pro: "",
                                age: 0,
                                notes: "",
                                drug: [
                                  {
                                    drug_name: "",
                                    instruction_use: "",
                                    quantity: 0,
                                    uom: "",
                                    det_type: "",
                                    det_iter_qty: 0,
                                    iter_qty: 0,
                                  },
                                ],
                              },
                            ];
                          }
                        });
                      }}
                    >
                      Tambah Obat
                    </button>
                  </Tooltip>
                ) : null}
              </div>
              <div className="flex">
                {prescriptionImg &&
                  prescriptionImg?.map((_, index) => (
                    <button
                      className={`py-2 px-3 text-[12px] font-bold rounded-md mr-2 ${
                        currentIndex === index
                          ? "bg-blue-500 text-white border-blue-500 hover:bg-blue-700"
                          : "border-2 border-blue-500 text-blue-500 hover:bg-slate-200"
                      }`}
                      onClick={() => setCurrentIndex(index)}
                      key={index}
                    >
                      {index + 1}
                    </button>
                  ))}
                {makeCopyRecipe ? (
                  <div className="flex items-center">
                    {addCopyRecipe[currentIndex] === true ? null : (
                      <button
                        className="px-3 py-2 rounded-md text-white bg-blue-500 font-bold text-[12px] w-full border-2 border-blue-500 hover:bg-blue-700 hover:border-blue-700"
                        onClick={() => {
                          setAddCopyRecipe((prev) =>
                            prev.map((item, i) =>
                              i === currentIndex ? true : item
                            )
                          );
                          setRecipeCred({
                            doctor_name: "",
                            recipe_date: moment().format("YYYY-MM-DD"),
                            pro: "",
                            age: 0,
                            notes: "",
                          });
                          setDataBuffer((prevValue) => ({
                            ...prevValue,
                            data: prevValue.data
                              .filter(
                                (data) =>
                                  data.product_label_id === 3 ||
                                  data.product_label_id === 4 ||
                                  data.product_label_id === 5
                              )
                              .map((data, idx) => {
                                return idx === currentIndex
                                  ? {
                                      ...data,
                                      isAdd: true,
                                    }
                                  : data;
                              }),
                          }));
                          setRecipeCopyBody((prevState) => {
                            const newObject = {
                              doctor_name: "",
                              recipe_date: moment().format("YYYY-MM-DD"),
                              pro: "",
                              age: 0,
                              notes: "",
                              drug: [
                                {
                                  drug_name: "",
                                  instruction_use: "",
                                  quantity: 0,
                                  uom: "",
                                  det_type: "",
                                  det_iter_qty: 0,
                                  iter_qty: 0,
                                },
                              ],
                            };
                            const updatedState = [...prevState];
                            if (currentIndex < updatedState.length) {
                              updatedState[currentIndex] = newObject;
                            } else {
                              for (
                                let i = updatedState.length;
                                i < currentIndex;
                                i++
                              ) {
                                updatedState[i] = null;
                              }
                              updatedState[currentIndex] = newObject;
                            }
                            return updatedState;
                          });
                        }}
                      >
                        + Tambah Salinan Resep {currentIndex + 1}
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex-1 w-full overflow-y-auto">
              {makeCopyRecipe ? (
                <div className="overflow-y-auto w-full pl-3">
                  {addCopyRecipe && recipeCopyBody[currentIndex] ? (
                    <button
                      className="text-red-500 font-bold flex items-center justify-end w-full gap-2 hover:text-red-700"
                      onClick={() => {
                        setRecipeCopyBody((prevValue) => {
                          const updatedRecipeCopyBody = prevValue.map(
                            (item, idx) => (idx === currentIndex ? null : item)
                          );
                          return updatedRecipeCopyBody;
                        });
                        setAddCopyRecipe((prevAddCopyRecipe) => {
                          const updatedAddCopyRecipe = [...prevAddCopyRecipe];
                          updatedAddCopyRecipe[currentIndex] = false;
                          return updatedAddCopyRecipe;
                        });
                      }}
                    >
                      Remove Salinan Resep <FiMinusCircle />
                    </button>
                  ) : null}
                </div>
              ) : (
                <div className="w-full overflow-y-auto">
                  <div className="h-6"></div>
                  {dataBuffer.data
                    ?.filter(
                      (data) =>
                        data.product_label_id === 3 ||
                        data.product_label_id === 4 ||
                        data.product_label_id === 5
                    )
                    .map((data, index) => (
                      <div
                        className="border border-black rounded-md p-2 font-bold w-[90%] my-2 ml-8"
                        key={index}
                      >
                        <div className="flex justify-between w-full">
                          <div className="flex items-center gap-x-3">
                            <img
                              src={data.media}
                              alt=""
                              className="border border-black rounded-md w-[100px] h-auto bg-cover"
                            />
                            <div className="text-[14px]">
                              <p>{data.product_name}</p>
                              <p className="text-[12px] font-medium">
                                Jumlah: {data.quantity} {data.packaging}
                              </p>
                            </div>
                          </div>
                          {data.product_label_id === 3 ||
                          data.product_label_id === 4 ||
                          data.product_label_id === 5 ? (
                            <div className="flex justify-center font-bold text-red-500">
                              <p className="text-[10px]">OKT</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))}
                </div>
              )}
              {addCopyRecipe[currentIndex] && recipeCopyBody.length > 0 ? (
                <CopyRecipePreview
                  recipeCopyBody={recipeCopyBody}
                  currentIndex={currentIndex}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <Dialog
        open={cancelModal.isModalOpen}
        fullWidth
        maxWidth="md"
        onClose={() =>
          setCancelModal({
            isModalOpen: false,
            cancel_reason: "",
            buffer: "",
            isOtherReason: false,
          })
        }
      >
        <DialogTitle>Tolak Pesanan</DialogTitle>
        <DialogContent>
          <p>Apa Anda yakin ingin menolak pesanan ini</p>
          <div className="flex">
            <FormControl fullWidth sx={{ marginY: 3 }}>
              <InputLabel>Alasan Penolakan</InputLabel>
              <Select
                label="Alasan Penolakan"
                value={cancelModal.buffer}
                onChange={(e) => {
                  if (e.target.value === "Others") {
                    setCancelModal((prevValue) => ({
                      ...prevValue,
                      buffer: e.target.value,
                      isOtherReason: true,
                    }));
                  } else {
                    setCancelModal((prevValue) => ({
                      ...prevValue,
                      buffer: e.target.value,
                      cancel_reason: e.target.value,
                      isOtherReason: false,
                    }));
                  }
                }}
              >
                <MenuItem value={"Resep tidak sesuai dengan pesanan"}>
                  Resep tidak sesuai dengan pesanan
                </MenuItem>
                <MenuItem value={"Resep tidak jelas"}>
                  Resep tidak jelas
                </MenuItem>
                <MenuItem value={"Obat yang dipesan sudah tidak tersedia"}>
                  Obat yang dipesan sudah tidak tersedia
                </MenuItem>
                <MenuItem value={"Others"}>Others</MenuItem>
                <MenuItem
                  value={""}
                  disabled
                  sx={{ display: "none" }}
                ></MenuItem>
              </Select>
            </FormControl>
          </div>
          {cancelModal.isOtherReason ? (
            <TextField
              placeholder="Masukkan alasan penolakan"
              fullWidth
              value={cancelModal.cancel_reason}
              onChange={(e) => {
                setCancelModal((prevValue) => ({
                  ...prevValue,
                  cancel_reason: e.target.value,
                }));
              }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <button
            className="px-3 py-2 rounded-md border border-black font-bold w-full mr-2"
            onClick={() => {
              setCancelModal((prevValue) => ({
                ...prevValue,
                isModalOpen: false,
              }));
            }}
          >
            Cancel
          </button>
          <button
            className="px-3 py-2 rounded-md text-white bg-red-500 font-bold w-full"
            onClick={() => {
              mutateCancelOrder({
                invoice_number: detailStatus.invoiceNumber,
                cancel_reason: cancelModal.cancel_reason,
              });
            }}
          >
            Tolak
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CopyRecipeDoctor;
