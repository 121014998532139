import { MenuItem, Select, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useMutation } from "@tanstack/react-query";
import { updateInvoiceStatus } from "../../../services/invoiceApi";
import { useEffect, useState } from "react";
import moment from "moment";

const EditInvoiceStatusModal = ({
  setOpenModal,
  openModal,
  status,
  paidDate,
  paymentMethod,
  invNum,
  invDate,
  refetch,
}) => {
  const [tempStatus, setTempStatus] = useState(null);
  const [tempDate, setTempDate] = useState(null);
  const [method, setMethod] = useState("");

  const handleSubmit = () => {
    const body = {
      invoice_number: invNum,
      payment_method: method,
      paid_at: tempDate === null ? "" : tempDate,
    };
    mutateUpdateStatus(body);
  };

  const handleClose = () => {
    setTempStatus(null);
    setTempDate(null);
    setMethod("");
    setOpenModal(false);
  };

  const { mutate: mutateUpdateStatus } = useMutation({
    mutationFn: updateInvoiceStatus,
    onSuccess: () => {
      refetch();
      handleClose();
    },
  });

  useEffect(() => {
    setTempStatus(status);
    setTempDate(paidDate === "" ? null : moment(paidDate));
    setMethod(paymentMethod);
  }, [status, paidDate, paymentMethod]);

  useEffect(() => {
    tempStatus ? setTempDate(moment().format("YYYY-MM-DD")) : setTempDate(null);
    if (!tempStatus) setMethod("");
  }, [tempStatus]);

  return (
    <>
      {openModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-8 max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="p-4 w-full">
                  <p className="text-center font-medium text-[30px] mb-3">
                    Update Status Invoice
                  </p>
                  <hr className="mb-4" />
                  <div className="mb-4">
                    <p className="font-bold mb-2">Status Pembayaran</p>
                    <Select
                      fullWidth
                      value={tempStatus === null ? status : tempStatus}
                      onChange={(e) => {
                        setTempStatus(e.target.value);
                      }}
                    >
                      <MenuItem value={true}>Lunas</MenuItem>
                      <MenuItem value={false}>Belum Lunas</MenuItem>
                    </Select>
                  </div>
                  {(tempStatus === null && status === false) ||
                  (tempStatus !== null && tempStatus === false) ? null : (
                    <>
                      <div className="mb-4">
                        <p className="font-bold mb-2">Tanggal Pembayaran</p>
                        <DesktopDatePicker
                          disableFuture
                          minDate={invDate}
                          className="w-full"
                          onChange={(newExpiredDate) => {
                            setTempDate(
                              moment(newExpiredDate).format("YYYY-MM-DD")
                            );
                          }}
                          value={
                            tempStatus === null && tempDate === null
                              ? paidDate
                              : tempDate
                          }
                          renderInput={(params) => {
                            return <TextField {...params} />;
                          }}
                        />
                      </div>
                      <div>
                        <p className="font-bold mb-2">Metode Pembayaran</p>
                        <TextField
                          placeholder="Isi Metode Pembayaran"
                          fullWidth
                          value={method}
                          onChange={(e) => setMethod(e.target.value)}
                          multiline
                          rows={4}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="flex items-center justify-center p-2 gap-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    type="button"
                    className="bg-red-400 text-white hover:bg-red-600 font-bold uppercase text-sm w-full py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={handleClose}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="bg-orange-400 text-white hover:bg-orange-600 font-bold uppercase text-sm w-full py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={handleSubmit}
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default EditInvoiceStatusModal;
