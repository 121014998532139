import { useFormik } from "formik";
import moment from "moment";
import { LuPrinter } from "react-icons/lu";
import { MdMailOutline } from "react-icons/md";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useRef, useState } from "react";

import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";
import {
  getPrescriptionHistoryDetail,
  mailRecipe,
  uploadImage,
} from "../../../../services/patientCare";
import PrescriptionPreview from "../patientCareDoctorAnalysis/PrescriptionPreview";
import html2canvas from "html2canvas";
import ReactToPrint, { useReactToPrint } from "react-to-print";

const PrescriptionDetail = ({ isDetail }) => {
  const { id } = useParams();
  const componentRef = useRef(null);
  const [isSending, setIsSending] = useState(false);

  //   const handlePrint = useReactToPrint({ contentRef: componentRef });

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleSendMail = async () => {
    setIsSending(true);
    if (formik.values.prescription_code[0] === "I") {
      const element = document.getElementById("print-recipe"),
        canvas = await html2canvas(element),
        data = canvas.toDataURL("image/jpg");

      mutateUploadImage(dataURLtoFile(data));
    } else {
      mutateMail({
        id: Number(id),
        attachment: formik.values.attachment,
      });
    }
  };

  const { mutate: mutateUploadImage } = useMutation({
    mutationFn: uploadImage,
    onSuccess: (data) =>
      mutateMail({
        id: Number(id),
        attachment: data,
      }),
    onError: () => setIsSending(false),
  });

  const { data: dataDetail, isFetching } = useQuery({
    queryKey: ["detail-prescription", id],
    queryFn: () => getPrescriptionHistoryDetail(id),
    enabled: id !== undefined,
  });

  const formik = useFormik({
    initialValues: {
      prescription_code: dataDetail ? dataDetail.prescription_code : "",
      attachment: dataDetail ? dataDetail.attachment : "", //empty if I
      doctor_name: dataDetail ? dataDetail.doctor_name : "",
      sip: dataDetail ? dataDetail.sip : "",
      address: dataDetail ? dataDetail.address : "",
      phone: dataDetail ? dataDetail.phone : "",
      patient_name: dataDetail ? dataDetail.patient_name : "",
      patient_dob: dataDetail ? dataDetail.patient_dob : "",
      prescription_date: dataDetail ? dataDetail.prescription_date : "",
      objective: { weight: dataDetail ? dataDetail.weight : "" },
      regular: dataDetail ? dataDetail.regular : [],
      concoction: dataDetail ? dataDetail.concoction : [],
    },
    enableReinitialize: true,
  });

  const { mutate: mutateMail, isLoading: isLoadingMail } = useMutation({
    mutationFn: mailRecipe,
    onSuccess: () => {
      setIsSending(false);
    },
    onError: () => {
      setIsSending(false);
    },
  });

  return (
    <>
      <LoadingBackdrop isLoading={isSending} />
      <div className="flex justify-center">
        {isDetail ? null : (
          <div className="absolute right-8 py-2 max-w-[25%] grid grid-cols-1 lg:grid-cols-3 gap-2">
            <ReactToPrint
              content={() => componentRef.current}
              trigger={() => (
                <button
                  disabled={!dataDetail || isFetching}
                  className="w-[36px] h-[36px] rounded-md bg-[#687B8E] text-white hover:bg-gray-300 p-1.5 disabled:bg-gray-300"
                >
                  <LuPrinter className="w-full h-full" />
                </button>
              )}
            />
            <button
              className="w-[36px] h-[36px] rounded-md bg-blue-500 text-white hover:bg-gray-300  p-1.5 disabled:bg-gray-300 "
              onClick={handleSendMail}
              disabled={!dataDetail || isFetching}
            >
              <MdMailOutline className="w-full h-full" />
            </button>
          </div>
        )}
        <div className="px-[16%] lg:px-[25%] py-2 w-full flex justify-center">
          <div className="w-full border-2 rounded-md">
            <div className="w-full p-3" ref={componentRef} id="print-recipe">
              {dataDetail && !isFetching ? (
                formik.values.prescription_code[0] === "I" ? (
                  <PrescriptionPreview
                    formik={formik}
                    selectedDoctor={{
                      name: formik.values.doctor_name,
                      sip: formik.values.sip,
                      address: formik.values.address,
                      phone: formik.values.phone,
                    }}
                    selectedPatient={{
                      patient_name: formik.values.patient_name,
                      date_of_birth: formik.values.patient_dob,
                    }}
                  />
                ) : (
                  <div className="w-full">
                    {formik.values.attachment === "" ? (
                      <p>Tidak ada attachment</p>
                    ) : (
                      <img
                        src={formik.values.attachment}
                        className="w-full h-full object-contain"
                      />
                    )}
                  </div>
                )
              ) : isFetching ? (
                <div className="flex justify-center items-center">
                  <CircularProgress size={20} />
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  <p>Terjadi kesalahan dalam mengambil data</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrescriptionDetail;
