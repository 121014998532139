import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Checkbox, CircularProgress } from "@mui/material";
import moment from "moment";
import { SlArrowRight } from "react-icons/sl";
import { BsChatRightDots } from "react-icons/bs";
import { MdOutlineAccessTime } from "react-icons/md";
import { TfiReceipt } from "react-icons/tfi";

import { updateStatusOrder } from "../../../services/OrderApi";
import { statusOrder, statusOrderColor } from "../../constants/constant";
import { ChatboxContext } from "../../context/ChatboxProvider";

const toDate = (date) => moment(date).format("DD MMMM YYYY, HH:mm");

const twoDays = (date) =>
  moment(date).add(2, "days").format("DD MMMM YYYY, HH:mm");

const oneDay = (date) =>
  moment(date).add(1, "days").format("DD MMMM YYYY, HH:mm");

const button = {
  2: "Terima Pesanan",
  3: "Siap Dikirim",
};

const OrderCard = (props) => {
  const navigate = useNavigate();
  const {
    orderListData,
    setIsModalOpen,
    setRecipeCopy,
    isOrderListLoading,
    setDetailStatus,
    setIsDrugModalOpen,
    mutateOrderDetail,
    isLoading,
    setUpdateReceiptBody,
    isRecipeCopy,
    setIsRecipeCopy,
    setIsRevenueModalOpen,
    setPrescriptionImg,
  } = props;

  const { setProductName, setBuyerName, setIsChatBoxOpen } =
    useContext(ChatboxContext);

  const { mutate: mutateUpdateStatusOrder } = useMutation({
    mutationFn: updateStatusOrder,
  });

  return (
    <div>
      <div>
        {isOrderListLoading ? (
          <div className="flex justify-center mt-5">
            <CircularProgress />
          </div>
        ) : (
          <div>
            {orderListData && orderListData !== undefined
              ? orderListData.data?.map((data, index) => (
                  <div
                    className="border border-black rounded-md p-3 my-3 font-bold"
                    key={index}
                  >
                    <div className="flex justify-between w-full">
                      <div className="flex gap-x-2 items-center">
                        <div className="flex items-center">
                          <p
                            style={{
                              backgroundColor: statusOrderColor[data.status],
                              padding: "0.5rem",
                              paddingLeft: "0.75rem",
                              paddingRight: "0.75rem",
                              borderRadius: "9999px",
                              color: "white",
                            }}
                          >
                            {statusOrder[data.status]}
                          </p>
                        </div>
                        <p>/</p>
                        <p
                          className="text-blue-500 hover:underline cursor-pointer"
                          onClick={() =>
                            navigate(`/order/${data.invoice_number}`)
                          }
                        >
                          {data.invoice_number}
                        </p>
                        <p>/</p>
                        <p>{data.fullname_censored}</p>
                        <p>/</p>
                        <p>{toDate(data.created_at) + " WIB"}</p>
                      </div>
                      {data.status === 2 ||
                      data.status === 3 ||
                      data.status === 4 ? (
                        <div>
                          <p>Batas Response</p>
                          <div className="rounded-full bg-[#FFB30F] p-3 text-white flex items-center ">
                            <p>{twoDays(data.created_at)}</p>
                            <MdOutlineAccessTime className="text-[25px] ml-2" />
                          </div>
                        </div>
                      ) : null}
                      {data.status === 8 ? (
                        <div>
                          <p>Batas Response</p>
                          <div className="rounded-full bg-[#FFB30F] p-3 text-white flex items-center ">
                            <p>{oneDay(data.created_at)}</p>
                            <MdOutlineAccessTime className="text-[25px] ml-2" />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full border border-black my-2"></div>

                    <div className="flex justify-between w-full">
                      <div className="w-[50%]">
                        <div className="flex items-center gap-x-3 border border-black p-3 rounded-md  w-full">
                          <img
                            src={data.media}
                            alt=""
                            className="rounded-md w-[150px] h-auto bg-cover"
                          />
                          <div className="w-full">
                            <div className="w-full flex justify-between">
                              <p>{data.product_name}</p>
                              {data.product_label_id === 3 ||
                              data.product_label_id === 4 ||
                              data.product_label_id === 5 ? (
                                <p className="font-bold text-red-500">
                                  Perlu Resep Obat
                                </p>
                              ) : null}
                            </div>

                            <p>jumlah: {data.quantity}</p>
                            <p>
                              {data.price.toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })}
                            </p>
                            <div className="flex">
                              {data.product_label_id === 3 ||
                              data.product_label_id === 4 ||
                              data.product_label_id === 5 ? (
                                <button
                                  className="border-2 border-blue-500 font-bold text-blue-500 rounded-md p-2 mr-2"
                                  onClick={() => {
                                    setPrescriptionImg(data.prescription_image);
                                    setIsRecipeCopy(false);
                                    setRecipeCopy({
                                      isModalOpen: true,
                                      invoice_number: data.invoice_number,
                                    });
                                  }}
                                >
                                  Lihat Resep Dokter
                                </button>
                              ) : null}

                              {data.copy_recipe_doctor ? (
                                <button
                                  className="border-2 border-blue-500 font-bold text-blue-500 rounded-md p-2"
                                  onClick={() => {
                                    setRecipeCopy({
                                      isModalOpen: true,
                                      invoice_number: data.invoice_number,
                                    });
                                    setIsRecipeCopy(true);
                                  }}
                                >
                                  Lihat Salinan Resep Dokter
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {data.total_product === 1 ? null : (
                          <p
                            className="text-blue-500 font-bold hover:underline cursor-pointer text-end my-3"
                            onClick={() =>
                              navigate(`/order/${data.invoice_number}`)
                            }
                          >
                            +{data.total_product - 1} more product
                          </p>
                        )}
                      </div>
                      <div className="flex justify-center w-[25%]">
                        <div className="h-full border border-black mx-3"></div>
                        <div className="p-2">
                          <p>Alamat</p>
                          <p>{data.address}</p>
                        </div>
                      </div>
                      <div className="flex justify-center w-[25%]">
                        <div className="h-full border border-black mr-3"></div>
                        <div className="p-2">
                          <p>Kurir</p>
                          <p>{data.shipping_method_name}</p>
                        </div>
                      </div>
                    </div>

                    <div className="w-full border border-black my-2"></div>
                    {data.status === 6 || data.status === 7 ? (
                      <p>Alasan Penolakan : {data.cancel_reason}</p>
                    ) : (
                      <div className="flex gap-x-2 justify-between">
                        <p>Total Penjualan</p>
                        <button
                          className="flex items-center border border-black"
                          onClick={() => {
                            setIsRevenueModalOpen(true);
                            mutateOrderDetail(data.invoice_number);
                          }}
                        >
                          <p>
                            {(data.price * data.quantity).toLocaleString(
                              "id-ID",
                              {
                                style: "currency",
                                currency: "IDR",
                              }
                            )}
                          </p>
                          <SlArrowRight />
                        </button>
                      </div>
                    )}
                    <div className="w-full border border-black my-2"></div>
                    <div className="flex gap-x-2 justify-between w-full">
                      <div className="flex items-center">
                        <span
                          className="flex items-center cursor-pointer"
                          onClick={() =>
                            setDetailStatus((prevValue) => ({
                              ...prevValue,
                              isModalOpen: true,
                              invoiceNumber: data.invoice_number,
                              status: data.status,
                              cancelReason: data.cancel_reason,
                              fullname: data.fullname,
                              createdAt: data.created_at,
                            }))
                          }
                        >
                          <TfiReceipt className="mr-2" /> Detail Status
                        </span>
                        <div className="h-full border border-black mx-3"></div>
                        <span
                          className="flex items-center cursor-pointer"
                          onClick={() => {
                            setIsChatBoxOpen(true);
                            setBuyerName(data.fullname);
                            setProductName(data.product_name);
                          }}
                        >
                          <BsChatRightDots className="mr-2" /> Chat Pembeli
                        </span>
                      </div>
                      {data.status === 5 ||
                      data.status === 6 ||
                      data.status === 7 ||
                      data.status === 8 ? null : (
                        <button
                          className="bg-blue-500 text-white p-2 rounded-md"
                          onClick={() => {
                            mutateOrderDetail(data.invoice_number);
                            if (data.status === 2 && data.hard_drug_flag) {
                              setIsDrugModalOpen(true);
                              mutateOrderDetail(data.invoice_number);
                              setDetailStatus((prevValue) => ({
                                ...prevValue,
                                invoiceNumber: data.invoice_number,
                                shippingId: data.shipping_method_id,
                              }));
                            }
                            if (data.status === 3 && !isLoading) {
                              setUpdateReceiptBody((prevValue) => ({
                                ...prevValue,
                                invoice_number: data.invoice_number,
                                isModalOpen: true,
                              }));
                            }
                            if (data.status === 8) {
                              mutateUpdateStatusOrder({
                                invoice_number: data.invoice_number,
                                status: 5,
                                recipe_copy_data: [],
                              });
                            }
                            if (data.status === 2 && !data.hard_drug_flag) {
                              mutateUpdateStatusOrder({
                                invoice_number: data.invoice_number,
                                status: data.shipping_method_id === 4 ? 8 : 3,
                                recipe_copy_data: [],
                              });
                            }
                          }}
                        >
                          {button[data.status]}
                        </button>
                      )}
                    </div>
                  </div>
                ))
              : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderCard;
