import { Popover } from "@mui/material";
import moment from "moment";
import DatePicker from "react-datepicker";

const PopupRangeCalendar = ({
  anchorElCal,
  setAnchorElCal,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  return (
    <Popover
      open={Boolean(anchorElCal)}
      anchorEl={anchorElCal}
      onClose={() => setAnchorElCal(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className="p-2">
        <DatePicker
          onChange={(dates) => {
            const [start, end] = dates;
            setStartDate(start ? moment(start).format("YYYY-MM-DD") : "");
            setEndDate(end ? moment(end).format("YYYY-MM-DD") : "");
          }}
          startDate={startDate == "" ? null : new Date(startDate)}
          endDate={endDate === "" ? null : new Date(endDate)}
          selectsRange={true}
          inline
        />
        <button
          className="bg-blue-500 text-white w-full rounded-md p-1 hover:bg-blue-700"
          onClick={() => {
            setStartDate("");
            setEndDate("");
          }}
        >
          Reset
        </button>
      </div>
    </Popover>
  );
};

export default PopupRangeCalendar;
