import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import PrescriptionDetail from "../../../components/screen/patientCare/prescriptionHistory/PrescriptionDetail";

const DetailPrescriptionPage = ({ isDetail }) => {
  return (
    <PageTemplate
      header={isDetail ? "Detail Prescription" : "Make Prescription Copy"}
      mainComponent={<PrescriptionDetail isDetail={isDetail} />}
    />
  );
};

export default HOC(DetailPrescriptionPage);
