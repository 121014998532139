import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import moment from "moment";

import {
  getSummaryInventory,
  getDetailCardStockNew,
  getBatchNumberDate,
} from "../../../../services/InventoryAPI";

const FETCH_LIMIT = 10;

const toDate = (date) => moment(date).format("DD-MM-YYYY");

const CardStockDetailNew = () => {
  const { productId = "" } = useParams();
  const companyId = localStorage.getItem("company_id");
  const navigate = useNavigate();

  const [offset, setOffset] = useState(1);
  const [date, setDate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const { data: summaryDetailData } = useQuery({
  //   queryKey: ["summary-detail", productId, companyId, offset],
  //   queryFn: () =>
  //     getSummaryInventory(productId, companyId, FETCH_LIMIT, offset),
  // });

  const { data: cardStockDetailData, isFetching: isFetchingCardStock } =
    useQuery({
      queryKey: ["card-stock-detail-new", productId, companyId, offset],
      queryFn: () =>
        getDetailCardStockNew(productId, companyId, FETCH_LIMIT, offset),
    });

  const { data: batchNumberDate } = useQuery({
    queryKey: ["batch-date", productId, date],
    queryFn: () => getBatchNumberDate(productId, date),
    enabled: productId !== "" && date !== "",
  });

  const pageCount = useMemo(() => {
    if (cardStockDetailData && cardStockDetailData !== undefined)
      return Math.ceil(cardStockDetailData.count / FETCH_LIMIT);
    else return 0;
  }, [cardStockDetailData]);

  return (
    <div>
      <div className="flex text-blue-500 font-bold">
        <p
          className="hover:underline cursor-pointer"
          onClick={() => {
            navigate("/home");
          }}
        >
          Homepage
        </p>
        <p className="mx-2">/</p>
        <p
          className="hover:underline cursor-pointer"
          onClick={() => {
            navigate("/inventory/produk-aktif");
          }}
        >
          Inventory
        </p>
        <p className="mx-2">/</p>
        <p>Detail Stock</p>
      </div>
      {cardStockDetailData &&
      cardStockDetailData !== undefined &&
      cardStockDetailData?.data &&
      cardStockDetailData?.data !== undefined ? (
        <div>
          <div className="flex justify-between w-full my-3">
            <div className="w-full">
              <p>Product ID</p>
              <p>
                {cardStockDetailData.data &&
                cardStockDetailData.data !== undefined
                  ? cardStockDetailData.data[0].product_id
                  : null}
              </p>
              <div className="w-full border border-black"></div>
            </div>
            <div className="w-full">
              <p>Nama Produk</p>
              <p>
                {cardStockDetailData.data &&
                cardStockDetailData.data !== undefined
                  ? cardStockDetailData.data[0].product_name
                  : null}
              </p>
              <div className="w-full border border-black"></div>
            </div>
          </div>
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#007AF1",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Beginnning Stock
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Stock In
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Stock Out
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Final Stock
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Batch Number
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Expired Date
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Supplier Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    PO Number
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    HNA per satuan Primer
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Discount
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    PPN
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Final Price per satuan Primer
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Sales Price
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    User Log
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cardStockDetailData &&
                cardStockDetailData.data &&
                !isFetchingCardStock ? (
                  cardStockDetailData.data.map((data, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        {toDate(data.created_at)}
                      </TableCell>
                      <TableCell align="center">{data.stock_current}</TableCell>
                      <TableCell align="center">{data.stock_in}</TableCell>
                      <TableCell align="center">{data.stock_out}</TableCell>
                      {/*
<TableCell align="center">
                      {data.stock_in === 0
                        ? data.stock_current - data.stock_out
                        : data.stock_current + data.stock_in}
                    </TableCell>

                        */}{" "}
                      {/*data.package_detail !== null ? (
                      <TableCell align="center">
                        {IGNORE_TYPE.includes(data.package_detail.type_unit) ? (
                          <p>{data.package_detail.type_strip}</p>
                        ) : (
                          <p>{data.package_detail.type_unit}</p>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell align="center">Rp. 0</TableCell>
                    )*/}
                      <TableCell align="center">
                        {data.stock_current + data.stock_in - data.stock_out}
                      </TableCell>
                      <TableCell align="center">{data.number_batch}</TableCell>
                      <TableCell align="center">{data.expired_date}</TableCell>
                      <TableCell align="center">{data.supplier_name}</TableCell>
                      <TableCell align="center">{data.po_number}</TableCell>
                      <TableCell align="center">
                        {data.pharmacy_net_price.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </TableCell>
                      <TableCell align="center">
                        {data.pharmacy_net_price_discount !== 0
                          ? data.pharmacy_net_price_discount + "%"
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {data.pharmacy_net_price_ppn ? "11%" : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {data.pharmacy_net_price_real.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </TableCell>
                      <TableCell align="center">
                        {data.sell_price.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </TableCell>
                      <TableCell align="center">{data.user_log}</TableCell>
                    </TableRow>
                  ))
                ) : isFetchingCardStock ? (
                  <TableRow>
                    <TableCell align="center" colSpan={9}>
                      <CircularProgress size={20} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow align="center" colSpan={9}>
                    <TableCell>Tidak ada data</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div className="flex mt-10 justify-center">
          <p>Data Kosong</p>
        </div>
      )}
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Detail Batch Number</DialogTitle>
        <DialogContent>
          {batchNumberDate && batchNumberDate !== undefined ? (
            <TableContainer component={Paper} sx={{ width: "100%" }}>
              <Table aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: "#007AF1",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Batch Number
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batchNumberDate.map((data, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{data}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </DialogContent>
      </Dialog>
      <div className="flex justify-center mt-3">
        <Pagination
          count={pageCount}
          page={offset}
          onChange={(_, value) => setOffset(value)}
          shape="rounded"
          size="large"
          color="primary"
        />
      </div>
    </div>
  );
};

export default CardStockDetailNew;
