import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosAddCircleOutline, IoIosArrowRoundBack } from "react-icons/io";
import moment from "moment";
import { getProductList } from "../../../services/invoiceApi";
import InfiniteScroll from "react-infinite-scroll-component";

const FETCH_LIMIT = 10;

const AddPromoProduct = ({ setIsAddProduct, formik }) => {
  const [search, setSearch] = useState("");
  const [addProductArr, setAddProductArr] = useState(formik.values.promo);
  const [offset, setOffset] = useState(1);
  const [fetchNext, setFetchNext] = useState(false);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const handleAddProduct = () => {
    formik.setFieldValue("promo", addProductArr);
    setIsAddProduct(false);
  };

  const handleCheckItem = (checked, item) => {
    var temp = [...addProductArr];
    if (checked) {
      temp.push({
        promo_type: 0,
        product_id: item.product_id,
        product_name: item.product_name,
        batch_number: item.batch_no,
        packaging:
          item.product_units && item.product_units !== undefined
            ? item.product_units.primary_type
            : "",
        type_uom: 0,
        expired_date: item.batch_exp,
        total_discount: 0,
        on_us: 0,
        off_us: 0,
        quantity_bought: 0,
        quantity_get: 0,
        is_multiple: false,
        max_item: 0,
        period_start: moment().format("YYYY-MM-DD"),
        period_end: moment().add(1, "d").format("YYYY-MM-DD"),
      });
    } else {
      const index = temp.findIndex(
        (prod) => prod.product_id === item.product_id,
      );
      temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
    }
    setAddProductArr(temp);
  };

  const changePackaging = (id, val) => {
    var temp = [...addProductArr];
    const index = addProductArr.findIndex((prod) => prod.product_id === id);
    temp[index].type_uom = val;
    setAddProductArr(temp);
  };

  const { isFetching } = useQuery({
    queryKey: ["promo-product", search],
    queryFn: () => getProductList(FETCH_LIMIT, 1, search),
    onSuccess: (data) => {
      setItems(data.data);
      setFetchNext(true);
      setOffset(2);
    },
    onError: () => {
      setHasMore(false);
    },
  });

  const { data: nextData, isFetching: isFetchingNext } = useQuery({
    queryKey: ["next-promo-product", fetchNext],
    queryFn: () => getProductList(FETCH_LIMIT, offset, search),
    enabled: fetchNext,
    onError: () => {
      setFetchNext(false);
      setHasMore(false);
    },
  });

  useEffect(() => {
    if (nextData && nextData.data !== null) {
      nextData.data.length > 0 ? setHasMore(true) : setHasMore(false);
      setOffset(offset + 1);
      setFetchNext(false);

      if (nextData && items.length > 0) {
        var temp = [...items];
        temp.push(...nextData.data);
        setItems(temp);
      }
    }
    if (nextData && nextData.data === null) {
      setHasMore(false);
    }
  }, [hasMore, nextData]);

  return (
    <>
      <div className="flex gap-2 items-center">
        <IoIosArrowRoundBack
          className="hover:text-slate-300 cursor-pointer w-[24px] h-full"
          onClick={() => setIsAddProduct(false)}
        />
        <p className="font-bold text-xl my-4">Tambah Product</p>
      </div>
      <div className="flex w-full items-center gap-2">
        <TextField
          value={search}
          label="Search Product (by name, NIE)"
          fullWidth
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
        />
        <button
          onClick={handleAddProduct}
          className=" w-full basis-1/3 bg-[#99FFC2] flex justify-center p-2 px-4 items-center border-2 border-black rounded-md cursor-pointer hover:bg-[#88dba9]"
        >
          <IoIosAddCircleOutline className="mr-2 text-black" />
          Add Products
        </button>
      </div>
      <div className="block overflow-x-auto my-4">
        <div className="flex w-full">
          <p className="w-[30%] text-center border p-2 font-bold">Select</p>
          <p className="w-full border p-2 font-bold">Product Name</p>
          <p className="w-full border p-2 font-bold">Batch No</p>
          <p className="w-full border p-2 font-bold">Expired Date</p>
          <p className="w-full border p-2 font-bold">Packaging</p>
        </div>
        <InfiniteScroll
          className="w-full"
          dataLength={items && items.length ? items.length : 0}
          next={() => setFetchNext(true)}
          hasMore={hasMore}
          loader={<CircularProgress size={20} />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Tidak ada produk lagi</b>
            </p>
          }
        >
          <>
            {items && items.length > 0 ? (
              items.map((item, i) => {
                return (
                  <>
                    <div className="w-full flex" key={i}>
                      <p className="w-[30%] text-center border p-2 font-bold">
                        {" "}
                        <Checkbox
                          onChange={(_, checked) =>
                            handleCheckItem(checked, item)
                          }
                          checked={addProductArr.find(
                            (prod) => prod.product_id === item.product_id,
                          )}
                        />
                      </p>
                      <p className="w-full border p-2">{item.product_name}</p>
                      <p className="w-full border p-2">{item.batch_no}</p>
                      <p className="w-full border p-2">{item.batch_exp}</p>
                      {item.product_units !== null ? (
                        <div className="border w-full">
                          <FormControl sx={{ margin: 2, width: "90%" }}>
                            <InputLabel>Packaging</InputLabel>
                            <Select
                              value={
                                !addProductArr.find(
                                  (prod) => prod.product_id === item.product_id,
                                )
                                  ? 0
                                  : addProductArr[
                                      addProductArr.findIndex(
                                        (prod) =>
                                          prod.product_id === item.product_id,
                                      )
                                    ].type_uom
                              }
                              disabled={
                                addProductArr.find(
                                  (prod) => prod.product_id === item.product_id,
                                ) === undefined
                              }
                              label="Promo Type"
                              onChange={(e) => {
                                changePackaging(
                                  item.product_id,
                                  e.target.value,
                                );
                              }}
                            >
                              <MenuItem
                                value={null}
                                sx={{ display: "none" }}
                              ></MenuItem>
                              <MenuItem
                                value={0}
                                onClick={() => {
                                  var temp = [...addProductArr];
                                  const index = addProductArr.findIndex(
                                    (prod) =>
                                      prod.product_id === item.product_id,
                                  );
                                  temp[index].packaging =
                                    item.product_units.primary_type;
                                  setAddProductArr(temp);
                                }}
                              >
                                {item.product_units.primary_type}
                              </MenuItem>
                              <MenuItem
                                value={1}
                                onClick={() => {
                                  var temp = [...addProductArr];
                                  const index = addProductArr.findIndex(
                                    (prod) =>
                                      prod.product_id === item.product_id,
                                  );
                                  temp[index].packaging =
                                    item.product_units.secondary_type;
                                  setAddProductArr(temp);
                                }}
                              >
                                {item.product_units.secondary_type}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      ) : null}
                      {/*<p className="w-full border p-2">
                        {item.product_unit?.toUpperCase()}
                      </p>

                        */}
                    </div>
                  </>
                );
              })
            ) : !isFetching && !isFetchingNext ? (
              <p className="p-2 border text-center w-full">
                Tidak ada produk dengan nama/NIE yang dicari
              </p>
            ) : null}
          </>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default AddPromoProduct;
