import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Tabs,
  Tab,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import useDebounce from "../../../hooks/useDebounce";
import {
  searchProductAdjustment,
  getSearchStockOpname,
  createAdjustmentStock,
} from "../../../../services/InventoryAPI";
import { IGNORE_TYPE } from "../../../constants/constant";

const toDate = (date) => moment(date).format("YYYY-MM-DD");

const CreateAdjustmentStock = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [search, setSearch] = useState("");
  const [productId, setProductId] = useState(0);
  const [dataBuffer, setDataBuffer] = useState([]);
  const [typeAdjustment, setTypeAdjustment] = useState(0);
  const [errorState, setErrorState] = useState(false);
  const [optionSearchBuffer, setOptionSearchBuffer] = useState([]);
  const [optionSearchValue, setOptionSearchValue] = useState([]);
  const [openOption, setOpenOption] = useState(false);

  const { data: searchStockOpnameData } = useQuery({
    queryKey: ["search-so", productId],
    queryFn: () => getSearchStockOpname(productId),
    enabled: productId !== 0,
  });

  const debounce = useDebounce(search, 500);

  const { data: searchProductData, isFetching: isSearchProductLoading } =
    useQuery({
      queryKey: ["search-product", debounce],
      queryFn: () => searchProductAdjustment(debounce),
    });

  const { mutate: mutateCreateAdjustmentStock } = useMutation({
    mutationFn: createAdjustmentStock,
    onSuccess: () => navigate("/inventory/stock-adjustment"),
  });

  useEffect(() => {
    setDataBuffer(
      searchStockOpnameData && searchStockOpnameData !== undefined
        ? searchStockOpnameData.map((data) => ({
            product_id: data.product_id,
            product_new_id: data.product_new_id,
            product_name: data.product_name,
            batch: data.batch_number,
            stock: data.stock,
            stock_adjustment: 0,
            type_adjustment: 0,
            expired_date: data.expired_date,
            pharmacy_net_price: data.pharmacy_net_price,
            pharmacy_net_price_discount: data.pharmacy_net_price_discount,
            pharmacy_net_price_ppn: data.pharmacy_net_price_ppn,
            pharmacy_net_price_real: data.pharmacy_net_price_real,
            packaging: data.packaging,
            sell_price: data.sell_price,
            package: "",
            type_package: 0,
            isDisabled: true,
            batch_type: 2,
            stockBuffer: 0,
          }))
        : [],
    );
  }, [searchStockOpnameData]);

  useEffect(() => {
    if (openOption)
      setOptionSearchBuffer(
        searchProductData && searchProductData !== null
          ? searchProductData
          : [],
      );
  }, [openOption, searchProductData]);

  return (
    <div>
      <div className="flex text-[#007AF1] font-medium">
        <p
          className="hover:underline hover:cursor-pointer"
          onClick={() => navigate("/inventory/stock-adjustment")}
        >
          Stock Adjustment
        </p>
        <p className="mx-2">/</p>
        <p>Create Stock Adjustment</p>
      </div>
      <Tabs
        value={value}
        onChange={(_, newValue) => setValue(newValue)}
        aria-label="basic tabs example"
        sx={{ marginY: 3 }}
      >
        <Tab label="Stock Adjustment" value={1} />
      </Tabs>
      <div hidden={value === 2}>
        <div className="my-3 flex">
          <Autocomplete
            fullWidth
            loading={isSearchProductLoading}
            onOpen={() => setOpenOption(true)}
            onClose={() => setOpenOption(false)}
            disablePortal
            options={optionSearchBuffer}
            value={optionSearchValue}
            onChange={(_, inputValue) => {
              setOptionSearchValue(inputValue);
              setProductId(inputValue !== null ? inputValue.id : 0);
              setOptionSearchBuffer([]);
            }}
            onInputChange={(_, onInputValue, reason) => {
              if (reason === "input") {
                setSearch(onInputValue);
                setOptionSearchBuffer([]);
              }
            }}
            getOptionLabel={(option) => option.product_name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.product_name}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isSearchProductLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <FormControl sx={{ width: "100%", marginLeft: 2 }} error={errorState}>
            <InputLabel>Alasan Penyesuaian</InputLabel>
            <Select
              value={typeAdjustment}
              label="Alasan Penyesuaian"
              onChange={(e) => setTypeAdjustment(e.target.value)}
            >
              <MenuItem disabled sx={{ display: "none" }} value={0}></MenuItem>
              <MenuItem value={1}>Stock Rusak</MenuItem>
              <MenuItem value={2}>Stock Hilang</MenuItem>
              <MenuItem value={3}>Stock Expired</MenuItem>
            </Select>
          </FormControl>
        </div>
        {searchStockOpnameData &&
        searchStockOpnameData !== undefined &&
        dataBuffer.length > 0 ? (
          <div className="mt-3">
            <p className="font-bold text-[20px]">
              {dataBuffer[0].product_name}
            </p>
            <p>Kemasan</p>
            <div className="flex flex-row">
              <p>{dataBuffer[0].packaging?.primary_total}&nbsp;</p>
              <p>{dataBuffer[0].packaging?.primary_type},&nbsp;</p>
              <p>{dataBuffer[0].packaging?.secondary_total}&nbsp;</p>
              <p>{dataBuffer[0].packaging?.secondary_type},&nbsp;</p>
              <p>{dataBuffer[0].packaging?.terniary_total}&nbsp;</p>
              <p>{dataBuffer[0].packaging?.terniary_type}&nbsp;</p>
            </div>
            <div>
              <p className="font-bold my-3">Active Stock</p>
              <div className="w-full border-2 border-black"></div>
              {dataBuffer.map((data, index) => (
                <div className="flex flex-row mt-3" key={index}>
                  <TextField
                    value={data.batch}
                    onChange={(e) =>
                      setDataBuffer((prevValue) =>
                        prevValue.map((item, idx) => {
                          return index === idx
                            ? {
                                ...item,
                                batch: e.target.value,
                              }
                            : item;
                        }),
                      )
                    }
                    disabled={data.isDisabled}
                    label="No. Batch"
                    sx={{ width: "100%" }}
                  />
                  <div className="mx-3 flex flex-row w-full">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        label="Expired Date"
                        value={data.expired_date}
                        disabled={data.isDisabled}
                        disablePast
                        onChange={(newExpiredDate) => {
                          setDataBuffer((prevValue) =>
                            prevValue.map((item, idx) => {
                              return index === idx
                                ? {
                                    ...item,
                                    expired_date: toDate(newExpiredDate),
                                  }
                                : item;
                            }),
                          );
                        }}
                        renderInput={(params) => {
                          return <TextField {...params} />;
                        }}
                      />
                    </LocalizationProvider>
                    <TextField
                      value={data.stock}
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        setDataBuffer((prevValue) =>
                          prevValue.map((item, idx) => {
                            return index === idx
                              ? {
                                  ...item,
                                  stock: parseInt(
                                    numericValue.replace(/[^0-9]/g, ""),
                                    10,
                                  ),
                                }
                              : item;
                          }),
                        );
                      }}
                      disabled={data.isDisabled}
                      label="Current Stock"
                      sx={{ width: "100%", marginLeft: 2 }}
                    />
                  </div>
                  <TextField
                    value={data.stockBuffer}
                    onChange={(e) => {
                      let numericValue = e.target.value;
                      if (numericValue === "") {
                        numericValue = "0";
                      }
                      if (data.packaging?.terniary_type === data.package) {
                        setDataBuffer((prevValue) =>
                          prevValue.map((item, idx) => {
                            return index === idx
                              ? {
                                  ...item,
                                  stock_adjustment: parseInt(
                                    numericValue.replace(/[^0-9]/g, ""),
                                    10,
                                  ),
                                  stockBuffer: parseInt(
                                    numericValue.replace(/[^0-9]/g, ""),
                                    10,
                                  ),
                                }
                              : item;
                          }),
                        );
                      }
                      if (data.packaging?.secondary_type === data.package) {
                        if (
                          IGNORE_TYPE.includes(data.packaging?.terniary_type)
                        ) {
                          setDataBuffer((prevValue) =>
                            prevValue.map((item, idx) => {
                              return index === idx
                                ? {
                                    ...item,
                                    stock_adjustment: parseInt(
                                      numericValue.replace(/[^0-9]/g, ""),
                                      10,
                                    ),
                                    stockBuffer: parseInt(
                                      numericValue.replace(/[^0-9]/g, ""),
                                      10,
                                    ),
                                  }
                                : item;
                            }),
                          );
                        } else {
                          setDataBuffer((prevValue) =>
                            prevValue.map((item, idx) => {
                              return index === idx
                                ? {
                                    ...item,
                                    stock_adjustment:
                                      parseInt(
                                        numericValue.replace(/[^0-9]/g, ""),
                                        10,
                                      ) * data.packaging?.terniary_total,
                                    stockBuffer: parseInt(
                                      numericValue.replace(/[^0-9]/g, ""),
                                      10,
                                    ),
                                  }
                                : item;
                            }),
                          );
                        }
                      }
                      if (data.package === data.packaging?.primary_type) {
                        if (
                          IGNORE_TYPE.includes(data.packaging?.terniary_type)
                        ) {
                          setDataBuffer((prevValue) =>
                            prevValue.map((item, idx) => {
                              return index === idx
                                ? {
                                    ...item,
                                    stock_adjustment:
                                      parseInt(
                                        numericValue.replace(/[^0-9]/g, ""),
                                        10,
                                      ) * data.packaging?.secondary_total,
                                    stockBuffer: parseInt(
                                      numericValue.replace(/[^0-9]/g, ""),
                                      10,
                                    ),
                                  }
                                : item;
                            }),
                          );
                        } else {
                          setDataBuffer((prevValue) =>
                            prevValue.map((item, idx) => {
                              return index === idx
                                ? {
                                    ...item,
                                    stock_adjustment:
                                      parseInt(
                                        numericValue.replace(/[^0-9]/g, ""),
                                        10,
                                      ) *
                                      data.packaging?.secondary_total *
                                      data.packaging?.terniary_total,
                                    stockBuffer: parseInt(
                                      numericValue.replace(/[^0-9]/g, ""),
                                      10,
                                    ),
                                  }
                                : item;
                            }),
                          );
                        }
                      }
                    }}
                    label="Stock on Hands"
                    sx={{ width: "100%" }}
                  />
                  <div className="w-[40%] ml-2">
                    {data.packaging && data.packaging !== null ? (
                      <FormControl sx={{ width: "100%" }} error={errorState}>
                        <InputLabel>Packaging</InputLabel>
                        <Select
                          value={data.package}
                          label="Packaging"
                          onChange={(e) => {
                            setDataBuffer((prevValue) =>
                              prevValue.map((item, idx) => {
                                return index === idx
                                  ? {
                                      ...item,
                                      package: e.target.value,
                                    }
                                  : item;
                              }),
                            );
                            setErrorState(false);
                          }}
                        >
                          <MenuItem
                            value={data.packaging.primary_type}
                            onClick={() => {
                              if (
                                IGNORE_TYPE.includes(
                                  data.packaging.terniary_type,
                                )
                              ) {
                                setDataBuffer((prevValue) =>
                                  prevValue.map((item, idx) => {
                                    return index === idx
                                      ? {
                                          ...item,
                                          type_package: 0,
                                          stock_adjustment:
                                            data.stockBuffer *
                                            data.packaging.secondary_type,
                                        }
                                      : item;
                                  }),
                                );
                              } else {
                                setDataBuffer((prevValue) =>
                                  prevValue.map((item, idx) => {
                                    return index === idx
                                      ? {
                                          ...item,
                                          type_package: 0,
                                          stock_adjustment:
                                            data.stockBuffer *
                                            data.packaging.secondary_type *
                                            data.packaging.terniary_type,
                                        }
                                      : item;
                                  }),
                                );
                              }
                            }}
                          >
                            {data.packaging.primary_type}
                          </MenuItem>
                          <MenuItem
                            value={data.packaging.secondary_type}
                            onClick={() => {
                              if (
                                IGNORE_TYPE.includes(
                                  data.packaging.terniary_type,
                                )
                              ) {
                                setDataBuffer((prevValue) =>
                                  prevValue.map((item, idx) => {
                                    return index === idx
                                      ? {
                                          ...item,
                                          type_package: 1,
                                          stock_adjustment: data.stockBuffer,
                                        }
                                      : item;
                                  }),
                                );
                              } else {
                                setDataBuffer((prevValue) =>
                                  prevValue.map((item, idx) => {
                                    return index === idx
                                      ? {
                                          ...item,
                                          type_package: 1,
                                          stock_adjustment:
                                            data.stockBuffer *
                                            data.packaging.secondary_total,
                                        }
                                      : item;
                                  }),
                                );
                              }
                            }}
                          >
                            {data.packaging.secondary_type}
                          </MenuItem>
                          {!IGNORE_TYPE.includes(
                            data.packaging.terniary_type,
                          ) ? (
                            <MenuItem
                              value={data.packaging.terniary_type}
                              onClick={() => {
                                setDataBuffer((prevValue) =>
                                  prevValue.map((item, idx) => {
                                    return index === idx
                                      ? {
                                          ...item,
                                          type_package: 2,
                                          stock_adjustment: data.stockBuffer,
                                        }
                                      : item;
                                  }),
                                );
                              }}
                            >
                              {data.packaging.terniary_type}
                            </MenuItem>
                          ) : null}
                          <MenuItem
                            disabled
                            sx={{ display: "none" }}
                            value=""
                          ></MenuItem>
                        </Select>
                      </FormControl>
                    ) : null}
                    {errorState ? (
                      <FormHelperText sx={{ color: "#D32F2F" }}>
                        Salah satu kemasan belum diisi
                      </FormHelperText>
                    ) : null}
                  </div>
                  {dataBuffer.length > 1 ? (
                    <div className="flex items-center">
                      <button
                        onClick={() => {
                          const newArray = [...dataBuffer];
                          newArray.splice(index, 1);
                          setDataBuffer(newArray);
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  ) : null}
                </div>
              ))}
              <p
                className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                onClick={() =>
                  setDataBuffer((prevValue) => [
                    ...prevValue,
                    {
                      product_id: prevValue[0]?.product_id,
                      product_new_id: prevValue[0]?.product_new_id,
                      product_name: prevValue[0]?.product_name,
                      batch: "",
                      stock: 0,
                      stock_adjustment: 0,
                      type_adjustment: 0,
                      pharmacy_net_price: prevValue[0]?.pharmacy_net_price,
                      sell_price: prevValue[0]?.sell_price,
                      expired_date: null,
                      packaging: prevValue[0]?.packaging,
                      package: "",
                      type_package: 0,
                      isDisabled: false,
                      batch_type: 1,
                      stockBuffer: 0,
                    },
                  ])
                }
              >
                +Tambah Batch lain
              </p>
            </div>
            <div className="flex flex-row justify-end font-bold mt-3">
              <button
                className="bg-gray-200 rounded-md p-3 border-2 border-black mr-2"
                onClick={() => navigate("/inventory/stock-opname")}
              >
                Batalkan
              </button>
              <button
                className="bg-blue-500 rounded-md p-3 text-white"
                onClick={() => {
                  if (
                    dataBuffer.map((data) => data.type_name).includes("") ||
                    typeAdjustment === 0
                  ) {
                    setErrorState(true);
                  } else {
                    mutateCreateAdjustmentStock(
                      dataBuffer.map((data) => ({
                        product_id: data.product_id,
                        product_new_id: data.product_new_id,
                        product_name: data.product_name,
                        batch: data.batch,
                        stock: data.stock,
                        stock_adjustment: data.stock_adjustment,
                        type_adjustment: typeAdjustment,
                        package: data.package,
                        sell_price: data.sell_price,
                        pharmacy_net_price: data.pharmacy_net_price,
                        pharmacy_net_price_discount:
                          data.pharmacy_net_price_discount,
                        pharmacy_net_price_ppn: data.pharmacy_net_price_ppn,
                        pharmacy_net_price_real: data.pharmacy_net_price_real,
                        type_package: data.type_package,
                        batch_type: data.batch_type,
                        expired_date: data.expired_date,
                      })),
                    );
                    setErrorState(false);
                  }
                }}
              >
                Konfirmasi
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CreateAdjustmentStock;
