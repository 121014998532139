import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";
import moment from "moment";

import { getDetailCardStock } from "../../../services/InventoryAPI";

const toDate = (date) => moment(date).format("DD/MM/YY");

const CardStockDetail = () => {
  const navigate = useNavigate();
  const { productId = "" } = useParams();

  const { data: cardStockDetailData } = useQuery({
    queryKey: ["card-stock-detail"],
    queryFn: () => getDetailCardStock(productId),
  });

  return (
    <div>
      <div className="flex text-[#007AF1] font-medium">
        <p
          className="hover:underline hover:cursor-pointer"
          onClick={() => navigate("/inventory/produk-aktif")}
        >
          Inventory
        </p>
        <p className="mx-2">/</p>
        <p
          className="hover:underline hover:cursor-pointer"
          onClick={() => navigate("/inventory/card-stock")}
        >
          Kartu Stock
        </p>
        <p className="mx-2">/</p>
        <p className="hover:underline hover:cursor-pointer">{productId}</p>
      </div>
      {cardStockDetailData && cardStockDetailData !== undefined ? (
        <TableContainer component={Paper} sx={{ width: "100%", marginTop: 3 }}>
          <Table aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: "#007AF1",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold" }}
                  align="center"
                >
                  Tanggal
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold" }}
                  align="center"
                >
                  Tipe Transaksi
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold" }}
                  align="center"
                >
                  Description
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold" }}
                  align="center"
                >
                  Jumlah
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold" }}
                  align="center"
                >
                  Current Stock
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold" }}
                  align="center"
                >
                  Outlet
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold" }}
                  align="center"
                >
                  User Log
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cardStockDetailData.map((data, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    {toDate(data.created_at)}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#007AF1" }}>
                    {data.type_name_transaction}
                  </TableCell>
                  <TableCell align="center">{data.description}</TableCell>
                  <TableCell align="center">{data.quantity}</TableCell>
                  <TableCell align="center">{data.current_stock}</TableCell>
                  <TableCell align="center">{data.company_name}</TableCell>
                  <TableCell align="center">{data.created_by}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </div>
  );
};

export default CardStockDetail;
