import React from "react";
import Header from "../../components/ui/Header";
import SidebarComponents from "../../components/ui/SidebarComponents";
import DetailPurchaseOrder from "../../components/screen/purchaseOrder/DetailPurchaseOrder";
import { SIDEBAR_WIDTH } from "../../components/constants/constant";

const DetailPurchaseOrderPage = () => {
  return (
    <div className="">
      <div className="flex">
          <SidebarComponents />       
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            PO Detail
          </h1>
          <DetailPurchaseOrder />
        </div>
      </div>
    </div>
  );
};

export default DetailPurchaseOrderPage;
