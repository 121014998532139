import axios from "axios";
import Cookies from "js-cookie";

const token = () => Cookies.get("accessToken");

export const getCustomer = async (currentPage, limit, search, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}pos/customer?limit=${limit}&offset=${currentPage}&search=${search}&id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getCustomerDetail = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}pos/customer?id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getCustomerOrderDetail = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}get/invoice?trx_id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const updateCustomer = async ({ id, name, email, phone, address }) => {
  try {
    const body = {
      id,
      name,
      email,
      phone,
      address,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}pos/customer`,
      method: "PUT",
      data: body,
    };
    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteCustomer = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}pos/customer?id=${id}`,
      method: "DELETE",
    };
    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getCustomerTransaction = async (
  currentPage = 1,
  id,
  limit,
  start_date,
  end_date,
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}pos/customer/transaction?id=${id}&limit=${limit}&offset=${currentPage}&start_date=${start_date}&end_date=${end_date}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const createCustomer = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_POS}create/customer`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

