import React, { useState, useEffect, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  Menu,
  MenuItem,
  CircularProgress,
  InputAdornment,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import {
  fetchProductList,
  deleteProducts,
} from "../../../../services/InventoryAPI";
import { sortOptions } from "../../../constants/constant";
import useDebounce from "../../../hooks/useDebounce";
import Category from "../../../common/particles.jsx/Category";
import Label from "../../../common/particles.jsx/Label";
import ModalDeleteProducts from "../../../common/particles.jsx/ModalDeleteProducts";
import Principal from "../../../common/particles.jsx/Principal";
import InventoryNavigation from "../InventoryNavigation";
import { FaChevronDown, FaPrint, FaSortAlphaDown } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import CollapsibleExpired from "./CollapsibleExpired";

const FETCH_LIMIT = 20;
const STATUS_PRODUCT = 5;
const INITIAL_OFFSET = {
  expired: 1,
  oneMos: 1,
  threeMos: 1,
  sixMos: 1,
};

const ProductExpired = () => {
  const navigate = useNavigate();
  const [productId, setProductId] = useState([]);
  const [listId, setListId] = useState([]);
  const [principalId, setPrincipalId] = useState([]);
  const [openCategoryFilter, setOpenCategoryFilter] = useState(false);
  const [openLabelFilter, setOpenLabelFilter] = useState(false);
  const [openPrincipalFilter, setOpenPrincipalFilter] = useState(false);
  const [selectSortValue, setSelectSortValue] = useState(0);
  const [offset, setOffset] = useState(INITIAL_OFFSET);
  const [productArr, setProductArr] = useState([]);
  const [isFirst, setIsFirst] = useState(true);

  const [expandedExp, setExpandedExp] = useState(false);
  const [expandOneMos, setExpandOneMos] = useState(false);
  const [expandThreeMos, setExpandThreeMos] = useState(false);
  const [expandSixMos, setExpandSixMos] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElProduct, setAnchorElProduct] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const openTab = Boolean(anchorEl);

  const fetchMore = (key, i) => {
    const body = {
      offset: offset[key] + 1,
      limit: FETCH_LIMIT,
      status: STATUS_PRODUCT,
      type: "expired",
      list_id: [i === 0 ? 0 : i === 1 ? 3 : 6],
      name: debounce,
      sort: selectSortValue,
    };

    i === 0
      ? mutateExpired(body)
      : i === 1
      ? mutateExpiredOne(body)
      : i === 2
      ? mutateExpiredThree(body)
      : mutateExpiredSix(body);

    let keyStr =
      i === 0
        ? "expired"
        : i === 1
        ? "oneMos"
        : i === 2
        ? "threeMos"
        : "sixMos";

    setOffset({ ...offset, [keyStr]: offset[key] + 1 });
    setIsFirst(false);
  };

  const {
    mutate: mutateExpired,
    data: productsExpired,
    isLoading: isLoadingExpired,
  } = useMutation({
    mutationFn: fetchProductList,
  });

  const {
    mutate: mutateExpiredOne,
    data: productsExpiredOne,
    isLoading: isLoadingExpiredOne,
  } = useMutation({
    mutationFn: fetchProductList,
  });

  const {
    mutate: mutateExpiredThree,
    data: productsExpiredThree,
    isLoading: isLoadingExpiredThree,
  } = useMutation({
    mutationFn: fetchProductList,
  });

  const {
    mutate: mutateExpiredSix,
    data: productsExpiredSix,
    isLoading: isLoadingExpiredSix,
  } = useMutation({
    mutationFn: fetchProductList,
  });

  const { mutate: mutateDeleteProduct } = useMutation({
    mutationFn: deleteProducts,
    onSuccess: () => {
      window.location.reload();
    },
  });

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setOpenCategoryFilter(false);
    setOpenLabelFilter(false);
    setOpenPrincipalFilter(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [type, setType] = useState("");
  const [search, setSearch] = useState("");

  const debounce = useDebounce(search, 500);

  const isFinishedLoading = () => {
    return (
      !isLoadingExpired &&
      !isLoadingExpiredOne &&
      !isLoadingExpiredThree &&
      !isLoadingExpiredSix &&
      productsExpired &&
      productsExpiredOne &&
      productsExpiredThree &&
      productsExpiredSix
    );
  };

  const getTotalProduct = () => {
    return (
      productsExpired.jumlah_product +
      productsExpiredOne.jumlah_product +
      productsExpiredThree.jumlah_product +
      productsExpiredSix.jumlah_product
    );
  };

  const getCurrentCount = () => {
    const arrProduct = [
      { arr: productsExpired, offName: "expired" },
      { arr: productsExpiredOne, offName: "oneMos" },
      { arr: productsExpiredThree, offName: "threeMos" },
      { arr: productsExpiredSix, offName: "sixMos" },
    ];

    const total = arrProduct.reduce(
      (acc, num) =>
        acc +
        (num.arr && num.arr.product_data
          ? num.arr.product_data.length === FETCH_LIMIT
            ? offset[num.offName] * FETCH_LIMIT
            : (offset[num.offName] - 1) * FETCH_LIMIT +
              num.arr.product_data.length
          : 0),
      0
    );

    return total;
  };

  const handleSelectList = (selectedId) => {
    const index = productId.findIndex((id) => id === selectedId);
    setProductId(
      index < 0
        ? [...productId, selectedId]
        : [...productId.slice(0, index), ...productId.slice(index + 1)]
    );
  };

  useEffect(() => {
    mutateExpired({
      offset: 1,
      limit: FETCH_LIMIT,
      status: STATUS_PRODUCT,
      type: "expired",
      list_id: [0],
      name: debounce,
      sort: selectSortValue,
    });
    mutateExpiredOne({
      offset: 1,
      limit: FETCH_LIMIT,
      status: STATUS_PRODUCT,
      type: "expired",
      list_id: [1],
      name: debounce,
      sort: selectSortValue,
    });
    mutateExpiredThree({
      offset: 1,
      limit: FETCH_LIMIT,
      status: STATUS_PRODUCT,
      type: "expired",
      list_id: [3],
      name: debounce,
      sort: selectSortValue,
    });
    mutateExpiredSix({
      offset: 1,
      limit: FETCH_LIMIT,
      status: STATUS_PRODUCT,
      type: "expired",
      list_id: [6],
      name: debounce,
      sort: selectSortValue,
    });
    setOffset(INITIAL_OFFSET);
    setProductId([]);
    setIsFirst(true);
  }, [debounce, selectSortValue]);

  useEffect(() => {
    if (search === "") setOffset(INITIAL_OFFSET);
  }, [search]);

  useEffect(() => {
    if (debounce !== "") setOffset(INITIAL_OFFSET);
  }, [debounce]);

  useEffect(() => {
    sessionStorage.setItem("id", productId);
  }, [productId]);

  useEffect(() => {
    let temp = [...productArr];
    if (productsExpired && productsExpired.product_data) {
      productsExpired.product_data.map((item) => {
        if (temp.findIndex((p) => p.product_id === item.product_id) === -1)
          temp.push(item);
      });
    }
    if (productsExpiredOne && productsExpiredOne.product_data)
      productsExpiredOne.product_data.map((item) => {
        if (temp.findIndex((p) => p.product_id === item.product_id) === -1)
          temp.push(item);
      });
    if (productsExpiredThree && productsExpiredThree.product_data)
      productsExpiredThree.product_data.map((item) => {
        if (temp.findIndex((p) => p.product_id === item.product_id) === -1)
          temp.push(item);
      });
    if (productsExpiredSix && productsExpiredSix.product_data)
      productsExpiredSix.product_data.map((item) => {
        if (temp.findIndex((p) => p.product_id === item.product_id) === -1)
          temp.push(item);
      });

    setProductArr(temp);
  }, [
    productsExpired,
    productsExpiredOne,
    productsExpiredThree,
    productsExpiredSix,
  ]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <InventoryNavigation />
      <ModalDeleteProducts
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        productId={productId}
        mutateDeleteProduct={mutateDeleteProduct}
      />
      <div
        className="offcanvas offcanvas-bottom fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-1/3 max-h-full"
        tabIndex="-1"
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header flex items-center justify-between p-4">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold"
            id="offcanvasBottomLabel"
          >
            Offcanvas bottom
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow p-4 overflow-y-auto small">
          ...
        </div>
      </div>

      <div className="flex gap-2 items-center w-full my-4">
        <TextField
          fullWidth
          placeholder="Search Products"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          inputProps={{ style: { padding: 8, fontSize: 12 } }}
        />
        {/* <div
          className="w-fit p-[8px] rounded-md border-2 flex gap-2 items-center text-gray-500 text-xs hover:bg-gray-100 cursor-pointer"
          aria-controls={openTab ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openTab ? "true" : undefined}
          onClick={handleDropdown}
        >
          <FaFilter />
          <p className="whitespace-nowrap">All Products</p>
          <FaChevronDown />
        </div> */}
        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 text-xs hover:bg-gray-100 cursor-pointer"
          aria-controls={Boolean(anchorElSort) ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorElSort) ? "true" : undefined}
          onClick={(e) => setAnchorElSort(e.currentTarget)}
        >
          <FaSortAlphaDown />
          <p className="whitespace-nowrap">All Products</p>
          <FaChevronDown />
        </div>
        <button
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-blue-500 border-blue-500 text-xs disabled:bg-gray-300 disabled:text-white"
          disabled={productId.length === 0}
          onClick={() => navigate("/inventory/retur")}
        >
          <FaPrint />
          <p className="whitespace-nowrap">Print Return</p>
        </button>
        <button
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-red-500 border-red-500 text-xs disabled:bg-gray-300 disabled:text-white"
          disabled={productId.length === 0}
          onClick={() => setOpenDeleteModal(true)}
        >
          <MdOutlineCancel />
          <p className="whitespace-nowrap">Destroy</p>
        </button>
      </div>

      <div className="flex justify-between items-center mb-4">
        <FormControlLabel
          control={<Checkbox />}
          label="Pilih Semua Produk"
          disabled={!isFinishedLoading()}
          checked={
            isFinishedLoading() ? getCurrentCount() === productId.length : false
          }
          onChange={(e, checked) => {
            setProductId(checked ? productArr.map((p) => p.product_id) : []);
          }}
        />
        {isFinishedLoading() ? (
          <p className="text-gray-300">{getTotalProduct()} products found</p>
        ) : (
          <CircularProgress size={20} />
        )}
      </div>
      {Object.keys(offset).map((key, i) => {
        return (
          <>
            <CollapsibleExpired
              type={i}
              products={
                i === 0
                  ? productsExpired
                  : i === 1
                  ? productsExpiredOne
                  : i === 2
                  ? productsExpiredThree
                  : productsExpiredSix
              }
              isLoading={
                i === 0
                  ? isLoadingExpired
                  : i === 1
                  ? isLoadingExpiredOne
                  : i === 2
                  ? isLoadingExpiredThree
                  : isLoadingExpiredSix
              }
              anchorElProduct={anchorElProduct}
              setAnchorElProduct={setAnchorElProduct}
              expandedExp={
                i === 0
                  ? expandedExp
                  : i === 1
                  ? expandOneMos
                  : i === 2
                  ? expandThreeMos
                  : expandSixMos
              }
              setExpandedExp={
                i === 0
                  ? setExpandedExp
                  : i === 1
                  ? setExpandOneMos
                  : i === 2
                  ? setExpandThreeMos
                  : setExpandSixMos
              }
              isFirst={isFirst}
              loadMore={() => {
                fetchMore(key, i);
              }}
              FETCH_LIMIT={FETCH_LIMIT}
              handleSelectList={handleSelectList}
              productId={productId}
              setProductId={setProductId}
            />
            <div className="mb-4" />
          </>
        );
      })}

      <Menu
        anchorEl={anchorElSort}
        open={Boolean(anchorElSort)}
        onClose={() => setAnchorElSort(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {sortOptions
          .filter((option) => option.value !== 2)
          .map((option) => (
            <MenuItem
              onClick={() => {
                setAnchorElSort(null);
                setSelectSortValue(option.value);
              }}
            >
              {option.name}
            </MenuItem>
          ))}
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openTab}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem
          onClick={(e) => {
            setOpenCategoryFilter(true);
            // setType("category");
            setAnchorElFilter(e.currentTarget);
            setListId([]);
          }}
        >
          Kategori
        </MenuItem> */}
        {/* <MenuItem
          onClick={(e) => {
            setOpenLabelFilter(true);
            // setType("label");
            setAnchorElFilter(e.currentTarget);
            setListId([]);
          }}
        >
          Golongan
        </MenuItem> */}
        <MenuItem
          onClick={(e) => {
            setOpenPrincipalFilter(true);
            // setType("principal");
            setAnchorElFilter(e.currentTarget);
            setPrincipalId([]);
          }}
        >
          Principal
        </MenuItem>
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorElFilter}
        open={Boolean(anchorElFilter)}
        onClose={handleCloseFilter}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <>
          {openCategoryFilter ? (
            <Category
              status={STATUS_PRODUCT}
              offset={1}
              limit={FETCH_LIMIT}
              listId={listId}
              setListId={setListId}
            />
          ) : openLabelFilter ? (
            <Label listId={listId} setListId={setListId} />
          ) : (
            <Principal
              status={STATUS_PRODUCT}
              offset={1}
              limit={FETCH_LIMIT}
              listId={principalId}
              setListId={setPrincipalId}
            />
          )}
          <div className="px-4">
            <button
              className="w-full bg-blue-500 text-white hover:bg-blue-700 rounded-md p-2 "
              onClick={() => {
                setType(
                  openCategoryFilter
                    ? "category"
                    : openLabelFilter
                    ? "label"
                    : "principal"
                );
                handleCloseFilter();
              }}
            >
              Cari Produk
            </button>
          </div>
        </>
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorElProduct}
        open={Boolean(anchorElProduct)}
        onClose={() => setAnchorElProduct(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => setTimeout(() => navigate("/inventory/retur"), 100)}
        >
          Cetak Retur
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteModal(true);
            setAnchorElProduct(null);
          }}
        >
          Destroy
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProductExpired;
