import { useMutation } from "@tanstack/react-query";
import { updatePromoStatus } from "../../../services/invoiceApi";
import { useState } from "react";

const EditPromoStatusModal = ({
  setOpenModal,
  openModal,
  id,
  newStatus,
  setIsChanged,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleChangeStatus = () => {
    const body = {
      promo_id: id,
      status: newStatus,
    };
    mutateStatus(body);
  };

  const { mutate: mutateStatus } = useMutation({
    mutationFn: updatePromoStatus,
    onSuccess: () => {
      setOpenModal(false);
      setIsChanged(true);
    },
    onError: (err) => setErrorMessage(err.id),
  });

  return (
    <>
      {openModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-8 max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="p-4 w-full text-center">
                  <p className="text-center font-medium text-[30px] mb-3">
                    Update Status Invoice
                  </p>
                  Apakah anda yakin ingin mengubah promo menjadi{" "}
                  {newStatus ? "aktif" : "tidak aktif"}?
                </div>

                <p className="text-center text-red-500 mb-4">{errorMessage}</p>

                <div className="flex items-center justify-center p-2 gap-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-red-400 text-white hover:bg-red-600 font-bold uppercase text-sm w-full py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={() => {
                      setErrorMessage("");
                      setOpenModal(false);
                    }}
                  >
                    Batal
                  </button>
                  <button
                    className="bg-orange-400 text-white hover:bg-orange-600 font-bold uppercase text-sm w-full py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={handleChangeStatus}
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default EditPromoStatusModal;
