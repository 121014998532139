import {
  Button,
  CircularProgress,
  InputAdornment,
  Pagination,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getPoList } from "../../../services/purchaseOrderApi";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";

const FETCH_LIMIT = 10;

const PoListBilling = () => {
  const navigate = useNavigate();

  const [offset, setOffset] = useState(1);
  const [search, setSearch] = useState("");

  const {
    mutate: mutatePoList,
    data: dataPoList,
    isLoading,
  } = useMutation({
    mutationFn: getPoList,
  });

  useEffect(() => {
    mutatePoList({
      search: search,
      limit: FETCH_LIMIT,
      offset: offset,
      type: 4,
      sort: 0,
      start_date: "",
      end_date: "",
    });
  }, [mutatePoList, search, offset]);

  return (
    <div>
      <TextField
        sx={{ marginTop: 2 }}
        value={search}
        label="No. PO/Nama Distributor"
        fullWidth
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AiOutlineSearch />
            </InputAdornment>
          ),
        }}
      />
      <div className="block overflow-x-auto mt-4">
        <table className="w-full">
          <tr>
            <th className="border p-2">Tanggal PO</th>
            <th className="border p-2">Nomor PO</th>
            <th className="border p-2">Distributor</th>
            <th className="border p-2">Action</th>
          </tr>
          {dataPoList && dataPoList.total > 0 && dataPoList.data !== null ? (
            dataPoList.data.map((item, i) => {
              return (
                <tr key={i}>
                  <td className="border p-2">{item.po_date}</td>
                  <td className="border p-2">{item.po_number}</td>
                  <td className="border p-2">{item.distributor_name}</td>
                  <td className="border p-2">
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        navigate(
                          `/manual-billing/create-billing/${item.id}/${item.is_partial}`,
                        );
                      }}
                    >
                      Select PO
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : isLoading ? (
            <tr>
              <td colSpan={4}>
                <CircularProgress size={20} />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={4} className="text-red-500">
                Tidak ada data PO ditemukan
              </td>
            </tr>
          )}
        </table>
      </div>

      {dataPoList && dataPoList.total > 0 && dataPoList.data !== null ? (
        <Pagination
          sx={{ float: "right", marginTop: 2 }}
          count={Math.ceil(dataPoList.total / FETCH_LIMIT)}
          page={offset}
          onChange={(_, value) => setOffset(value)}
          shape="rounded"
          size="large"
          color="primary"
        />
      ) : null}
    </div>
  );
};

export default PoListBilling;
