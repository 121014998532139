import React from "react";
import HOC from "../../components/HOC";
import Header from "../../components/ui/Header";
import SidebarComponents from "../../components/ui/SidebarComponents";
import CreateBillingManual from "../../components/screen/billingManual/CreateBillingManual";
import { SIDEBAR_WIDTH } from "../../components/constants/constant";

const CreateBillingManualPage = () => {
  return (
    <div className="">
      <div className="flex">
          <SidebarComponents />       
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            Create Billing Manual
          </h1>
          <CreateBillingManual />
        </div>
      </div>
    </div>
  );
};

export default HOC(CreateBillingManualPage);
