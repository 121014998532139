import OlinLogo from "../../../assets/icons/logoOlin.png";

const CopyRecipePreview = ({ recipeCopyBody, currentIndex }) => {
  const currentDate = new Date();

  let adjustedIndex = currentIndex;

  while (adjustedIndex >= recipeCopyBody.length && adjustedIndex > 0) {
    adjustedIndex--;
  }

  const recipeDay = String(currentDate.getDate()).padStart(2, "0");
  const recipeMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const recipeYear = currentDate.getFullYear();

  const [year, month, day] = recipeCopyBody[adjustedIndex]?.recipe_date
    ? recipeCopyBody[adjustedIndex]?.recipe_date.split("-")
    : [recipeYear, recipeMonth, recipeDay];

  const formattedRecipeDate = `${recipeDay}/${recipeMonth}/${recipeYear}`;
  const formattedDate = `${day}/${month}/${year}`;

  const convertToRoman = (num) => {
    const romanNumerals = [
      { value: 1000, numeral: "M" },
      { value: 900, numeral: "CM" },
      { value: 500, numeral: "D" },
      { value: 400, numeral: "CD" },
      { value: 100, numeral: "C" },
      { value: 90, numeral: "XC" },
      { value: 50, numeral: "L" },
      { value: 40, numeral: "XL" },
      { value: 10, numeral: "X" },
      { value: 9, numeral: "IX" },
      { value: 5, numeral: "V" },
      { value: 4, numeral: "IV" },
      { value: 1, numeral: "I" },
    ];

    let result = "";
    for (const { value, numeral } of romanNumerals) {
      while (num >= value) {
        result += numeral;
        num -= value;
      }
    }
    return result;
  };

  return (
    <div className="pl-3 mt-2">
      <div className="border-1 border-gray-300 rounded-lg p-4">
        <div>
          <div className="flex items-center justify-between">
            <div>
              <p className="font-bold text-[18px]">
                Apotek Jaya Abadi Selamanya
              </p>
              <div className="flex text-[12px]">
                <div>
                  <p>No. SIPA</p>
                  <p>SIA</p>
                  <p>Alamat</p>
                </div>
                <div className="ml-3 mr-1">
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                </div>

                <div>
                  <p className="break-words">
                    123412341234/21431/iV/fejf/324234
                  </p>
                  <p className="break-words">123123123/2023/23/23123</p>
                  <p className="break-words">
                    Jln. Wr. Supratman No.Kav.55. Kota Semarang
                  </p>
                </div>
              </div>
            </div>
            <div>
              <p className="text-[13px]">Powered by</p>
              <img alt="Olin Logo" src={OlinLogo} className="w-[80px]" />
            </div>
          </div>
          <div className="border-1 border-black my-2"></div>
          <div>
            <p className="text-center text-[16px] font-bold">Salinan Resep</p>
            <div className="text-[12px] flex justify-between">
              <div className="flex">
                <div>
                  <p>No</p>
                  <p>Dari</p>
                  <p>Tanggal Resep</p>
                </div>
                <div className="ml-3 mr-1">
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                </div>

                <div>
                  <p className="text-gray-300">[Auto]</p>
                  <p>{recipeCopyBody[adjustedIndex]?.doctor_name || "-"}</p>
                  <p>{formattedDate}</p>
                </div>
              </div>
              <div>
                <p>{formattedRecipeDate}</p>
              </div>
            </div>
          </div>
          <div className="my-3">
            {recipeCopyBody[adjustedIndex]?.drug.map((data, index) => (
              <div className="w-full text-[12px] mt-2" key={index}>
                <div className="flex w-full">
                  <p className="mr-4">R/</p>
                  <div className="flex justify-between items-center w-full">
                    <div className="">
                      <p className="text-start">
                        {data.drug_name || "-"}
                        {data.uom ? " " + data.uom.toLowerCase() : ""}
                        {data.quantity
                          ? " no. " + convertToRoman(data.quantity)
                          : ""}{" "}
                      </p>
                      <p>S. {data.instruction_use}</p>
                    </div>
                    {data.iter_qty > 0 && (
                      <p className="text-blue-500 border-2 border-blue-500 rounded-sm px-1 text-end">
                        Iter {data.iter_qty}x
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="flex justify-end ml-8 mt-2 items-center w-full border-b border-black">
                    <p>
                      - {data.det_type?.toLowerCase()}{" "}
                      {data.det_iter_qty === 0 ? "" : data.det_iter_qty}
                      {data.det_type === "Det Iter" ? "x" : ""} -
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex text-[12px]">
            <div>
              <p>Pro</p>
              <p>Umur</p>
            </div>
            <div className="ml-3 mr-1">
              <p>:</p>
              <p>:</p>
            </div>

            <div className="">
              <p>{recipeCopyBody[adjustedIndex]?.pro || "-"}</p>
              <p>
                {recipeCopyBody[adjustedIndex]?.age === 0
                  ? "-"
                  : recipeCopyBody[adjustedIndex]?.age}{" "}
                Tahun
              </p>
            </div>
          </div>
          <div className="flex w-full justify-end">
            <div className="flex flex-col text-[12px] gap-12">
              <p>P.C.C</p>
              <p>M. Rifqi Rokhman, M.Sc., Apt.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyRecipePreview;
