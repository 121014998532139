import { Breadcrumbs, Link } from "@mui/material";
import { useLocation } from "react-router-dom";

const BreadcrumbPatientCare = () => {
  const { pathname } = useLocation();

  return (
    <Breadcrumbs sx={{ color: "#1761FD", marginBottom: 2 }}>
      <Link underline="hover" color="#1761FD" sx={{ color: "#1761FD" }}>
        Homepage
      </Link>
      {pathname.includes("/patient-care/services") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/patient-care/services"
          sx={{ color: "#1761FD" }}
        >
          Services
        </Link>
      ) : null}
      {pathname.includes("/patient-care/doctor-database") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/patient-care/doctor-database"
          sx={{ color: "#1761FD" }}
        >
          Doctor Database
        </Link>
      ) : null}
      {pathname.includes("/patient-care/patient-database") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/patient-care/patient-database"
          sx={{ color: "#1761FD" }}
        >
          Patient Database
        </Link>
      ) : null}
      {pathname.includes("/patient-care/initial-analysis") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/patient-care/initial-analysis"
          sx={{ color: "#1761FD" }}
        >
          Initial Analysis
        </Link>
      ) : null}
      {pathname.includes("/patient-care/initial-analysis/create-analysis") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/patient-care/initial-analysis/create-analysis"
          sx={{ color: "#1761FD" }}
        >
          Tambah Initial Analysis
        </Link>
      ) : null}
      {pathname.includes("/patient-care/initial-analysis/detail-analysis/") ? (
        <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
          Detail Initial Analysis
        </Link>
      ) : null}
      {pathname.includes("/patient-care/initial-analysis/edit-analysis/") ? (
        <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
          Edit Initial Analysis
        </Link>
      ) : null}
      {pathname.includes("/patient-care/doctor-analysis") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/patient-care/doctor-analysis"
          sx={{ color: "#1761FD" }}
        >
          Doctor Analysis
        </Link>
      ) : null}
      {pathname.includes("/patient-care/doctor-analysis/create-analysis") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/patient-care/doctor-analysis/create-analysis"
          sx={{ color: "#1761FD" }}
        >
          Add Doctor Analysis
        </Link>
      ) : null}
      {pathname.includes("/patient-care/medical-record") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/patient-care/medical-record"
          sx={{ color: "#1761FD" }}
        >
          Checkup History
        </Link>
      ) : null}
      {pathname.includes("/patient-care/prescription-history") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/patient-care/prescription-history"
          sx={{ color: "#1761FD" }}
        >
          Prescription History
        </Link>
      ) : null}
      {pathname.includes("/patient-care/prescription-history/detail/") ? (
        <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
          Detail Prescription
        </Link>
      ) : null}
      {pathname.includes("/patient-care/prescription-history/make-copy/") ? (
        <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
          Copy Prescription
        </Link>
      ) : null}
    </Breadcrumbs>
  );
};

export default BreadcrumbPatientCare;
