import { GeneratePublicToken } from "../utils/GeneratePublicToken";
import axios from "axios";
import Cookies from "js-cookie";

const token = () => Cookies.get("accessToken");

export const getRankPrinciple = async (offset, limit) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}get/principal/rank?offset=${offset}&limit=${limit}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getPrincipal = async (offset, limit, name) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}get/principal?offset=${offset}&limit=${limit}&name=${name}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchAllProducts = async ({ offset, limit }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}products`,
      method: "POST",
      data: {
        offset,
        limit,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getMostSearchedProduct = async (limit, offset) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}most/product?offset=${offset}&limit=${limit}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getProductBasedOnSearch = async (offset, limit) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}get/product/search?offset=${offset}&limit=${limit}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchCategoriesProduct = async ({
  offset,
  limit,
  category_name,
}) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}category`,
      method: "POST",
      data: {
        offset,
        limit,
        category_name,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchSpecialOffer = async ({ offset, limit }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}product/so`,
      method: "POST",
      data: {
        offset,
        limit,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchSpecialOfferBackground = async ({ offset, limit }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}product/so/homepage`,
      method: "POST",
      data: {
        offset,
        limit,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const addToCartPurchase = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}add/cart/purchase`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.response;
  }
};

export const getCountCart = async (tipe) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}count/cart?tipe=${tipe}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getProductByPrincipalId = async (id, limit, offset) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}get/product/principal?id=${id}&limit=${limit}&offset=${offset}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getProductByCategoryId = async (id, limit, pageParam = 1) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}get/product/category?id=${id}&limit=${limit}&offset=${pageParam}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getProductDetailPurchase = async (product_id, type) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}product/detail?product_id=${product_id}&type=${type}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail.data;
  } catch (err) {
    throw err;
  }
};

export const getHistorySearch = async () => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}get/history/search`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const searchProduct = async ({ search, offset, limit }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}search/product`,
      method: "POST",
      data: { search, offset, limit },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const searchProductBy = async ({ search, offset, limit, type, id }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}search/product/by`,
      method: "POST",
      data: { search, offset, limit, type, id },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const deleteHistorySearch = async () => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}delete/history/search`,
      method: "DELETE",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getCartDetails = async (tipe) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}carts?tipe=${tipe}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getVoucher = async (code, limit, offset) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}voucher?code=${code}&limit=${limit}&offset=${offset}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchDetailVoucher = async (voucher_id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}voucher/detail`,
      method: "POST",
      data: { voucher_id },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const checkallCartProduct = async (checklist) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}update/cart/checklist`,
      method: "POST",
      data: { checklist },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getPaymentMethod = async () => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_MASTER}payment-method`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getShippingMethod = async () => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}get/shipping/method`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchCheckoutCart = async (voucher_id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}handle/cart/checkout`,
      method: "POST",
      data: {
        voucher_id: voucher_id,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const OtpCheckout = async () => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}checkout/sendotp`,
      method: "POST",
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const verifyCheckoutOtp = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}checkout/verifyotp`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail.verify;
  } catch (err) {
    throw err;
  }
};

export const fetchTransactionList = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}list/transaction`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchPaymentDetail = async ({ invoice }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}payment/detail`,
      method: "POST",
      data: {
        invoice,
      },
    };
    const res = await axios(config);
    return res.data.detail.verify;
  } catch (err) {
    throw err;
  }
};

export const paymentCheck = async ({ invoice }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}payment/check`,
      method: "POST",
      data: {
        invoice,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const fetchTransactionDetail = async ({ invoice }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}transaction/detail`,
      method: "POST",
      data: {
        invoice,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const postTransactionDone = async ({ invoice }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}transaction/done`,
      method: "POST",
      data: {
        invoice,
      },
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const postReorderTransaction = async ({ invoice }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}transaction/reorder`,
      method: "POST",
      data: {
        invoice,
      },
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteCart = async () => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}cart/delete`,
      method: "DELETE",
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const addComplain = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}handle/complaint`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const fetchComplainPage = async ({ invoice }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_PURCHASE}add/complaint`,
      method: "POST",
      data: { invoice },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const uploadComplainMedia = async (files) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
        "Content-Type": "multipart/form-data",
      },
      url: `${process.env.REACT_APP_API_MASTER}upload/multiple`,
      method: "POST",
      data: { files: files, type: "public", datasource: "complaint" },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};
