import { useMemo } from "react";
import moment from "moment";

import thumbsUp from "../../../assets/images/thumbs-up.png";

const timeNow = moment().format("HH:mm");

const Chat = () => {
  const companyName = localStorage.getItem("company_name");
  const fullname = localStorage.getItem("name");

  const userList = useMemo(() => {
    return [
      {
        id: 1,
        status: true,
        name: fullname,
      },
      {
        id: 2,
        status: true,
        name: "Apotek Mandiri",
      },
      {
        id: 3,
        status: true,
        name: "Apotek Iren Mutia",
      },
    ];
  }, [fullname]);

  const chatResponse = [
    {
      id: 1,
      message: "Halo Selamat Siang Kak",
      type: 2,
      time: timeNow,
      img: null,
    },
    {
      id: 2,
      message: `selamat siang kak, selamat datang di apotek ${companyName}. ada yang bisa kami bantu?`,
      type: 1,
      time: timeNow,
      img: null,
    },
    {
      id: 3,
      message:
        "ya kak, saya mau pesan obat FG Troches 300'S perlu disertai dengan resep dokter ya?",
      type: 2,
      time: timeNow,
      img: null,
    },
    {
      id: 4,
      message: `iya betul kak ${fullname}, untuk pembelian obat FG Troches 300'S, kakak perlu mengupload resep dokter yang akan otomatis muncul sebelum check out`,
      type: 1,
      time: timeNow,
      img: null,
    },
    {
      id: 4,
      message: "",
      type: 1,
      time: timeNow,
      img: thumbsUp,
    },
  ];

  return (
    <div>
      <div className="font-bold text-blue-500 flex mb-3">
        <p>Home</p>
        <p className="mx-1">/</p>
        <p>Chat</p>
      </div>
      <h1 className="font-semibold text-[20px] lg:text-[30px]">Chat</h1>
      <div className="bg-white shadow-md rounded-lg w-full">
        <div className="p-4 border-b bg-blue-500 text-white rounded-t-lg flex justify-between items-center">
          <p className="text-lg font-semibold">{fullname}</p>
          <button
            className="text-gray-300 hover:text-gray-400 focus:outline-none focus:text-gray-400"
            //    onClick={() => setIsChatBoxOpen((prevValue) => !prevValue)}
            //     onClick={() => {
            //       setIsChatBoxOpen((prevValue) => !prevValue);
            //       setChatboxResponse([
            //         {
            //           id: 1,
            //           // message: `selamat siang kak, selamat datang di apotek ${companyName}. ada yang bisa kami bantu?`,
            //           message: `selamat siang dok, saya mau konsultasi untuk pengunaan ${productName}, saya lupa penggunaannya untuk pengidap bronkitis harus berapa kali sehari ya?`,
            //           type: 1,
            //           time: timeNow,
            //         },
            //         {
            //           id: 2,
            //           //message: `selamat siang kak, selamat datang di apotek ${companyName}. ada yang bisa kami bantu?`,
            //           message: `Hi selamat siang ${buyerName}, untuk pemakaian ${productName} sebagai berikut:
            //
            //   Dewasa: 1 - 2 Tablet, dihisap 4 - 5 kali per hari.
            //   Anak-anak: 1 Tablet, dihisap 4 - 5 kali per hari.
            // `,
            //           type: 2,
            //           time: timeNow,
            //         },
            //       ]);
            //     }}
          >
            {/*<KeyboardArrowDownIcon fontSize="large" />*/}
          </button>
        </div>
        <div className="flex h-[20%]">
          <div className="w-[45%] p-3 ">
            <div className="flex">
              <input
                id="user-input"
                type="text"
                placeholder="Cari pengguna OLIN"
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button className="rounded-md bg-blue-500 text-white font-bold p-3 ml-2">
                Cari
              </button>
            </div>
            {userList.map((data) => (
              <div className="p-3 border-b border-gray-200 flex-col justify-center">
                <p className="font-bold mb-2">{data.name}</p>
                {data.status ? (
                  <p className="flex items-center">
                    <span className="h-[25px] w-[25px] bg-green-500 inline-block rounded-[50%] mr-1.5"></span>
                    Online
                  </p>
                ) : (
                  <p className="flex items-center">
                    <span className="h-[25px] w-[25px] bg-gray-200 inline-block rounded-[50%] mr-1.5"></span>
                    Offline
                  </p>
                )}
              </div>
            ))}
          </div>
          <div className="flex h-[30%]">
            <div className="w-full border border-l-gray-200 pb-3">
              <div id="chatbox" className="p-4 overflow-y-auto ">
                {chatResponse.map((data, index) => (
                  <div>
                    <div
                      key={index}
                      className={`${data.type === 1 ? "w-full flex justify-end" : ""} `}
                    >
                      <div className="mb-2 w-[50%]" key={data.id}>
                        {data.img !== null ? (
                          <div className="w-[50%]">
                            <img src={data.img} alt={data.img} />
                          </div>
                        ) : (
                          <p
                            className={`${data.type === 1 ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"} rounded-lg py-2 px-4 inline-block`}
                          >
                            {data.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <p
                      className={`mb-2 ${data.type === 1 ? "text-right" : ""} `}
                    >
                      {data.time}
                    </p>
                  </div>
                ))}
              </div>
              <div className="p-4 border-t flex">
                <input
                  id="user-input"
                  type="text"
                  placeholder="Type a message"
                  className="w-full px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  // value={userInput}
                  // onChange={(e) => {
                  //   setUserInput(e.target.value);
                  // }}
                />
                <button
                  id="send-button"
                  className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-300"
                  // onClick={() => {
                  //   setChatboxResponse((prevValue) => [
                  //     ...prevValue,
                  //     {
                  //       message: userInput,
                  //       type: 1,
                  //       time: moment().format("HH:mm"),
                  //     },
                  //   ]);
                  //   setChatboxResponse((prevValue) => [
                  //     ...prevValue,
                  //     {
                  //       message: `Iya betul kak ${fullname}, untuk melakukan pembelian obat ${productName}, kakak perlu mengupload resep dokter yang akan otomatis muncul sebelum check out obatnya`,
                  //       type: 2,
                  //       time: moment().format("HH:mm"),
                  //     },
                  //   ]);
                  //   setUserInput("");
                  // }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
