const ReturPrintPdf = (props) => {
  const { returPdfData, toDate, printRef } = props;
  return (
    <div ref={printRef} className="mx-5">
      {returPdfData && returPdfData !== undefined ? (
        <div>
          <div className="flex justify-center mt-3">
            <div className="text-center">
              <h1 className="text-[50px] font-bold">Nota Retur</h1>
              <p className="text-[20px] font-normal">
                Nomor Retur: {returPdfData.note_number}
              </p>
            </div>
          </div>
          <div className="w-full border-[1px] border-black"></div>
          <div className="my-3">
            <p className="text-[20px] font-normal">
              {returPdfData.distributor_name}
            </p>

            <p className="text-[20px] font-normal">
              {returPdfData.distributor_address}
            </p>
            <p className="text-[20px] font-normal">
              {returPdfData.distributor_phone}
            </p>
          </div>
          <div>
            {returPdfData.product_list &&
            returPdfData.product_list !== undefined ? (
              <table className="w-full border">
                <thead>
                  <tr className="text-center bg-blue-500 text-white">
                    <th className="py-2 ">Id Produk</th>
                    <th className="py-2 ">Nama Produk</th>
                    <th className="py-2">Nomor Batch</th>
                    <th className="py-2">Expired Date</th>
                    <th className="py-2">Retur Quantity</th>
                    <th className="py-2">HNA</th>
                    <th className="py-2">Nomor Invoice</th>
                  </tr>
                </thead>
                {returPdfData.product_list.map((data, index) => (
                  <tbody key={index}>
                    <tr className="text-center">
                      <td className="py-2 border">{data.product_id}</td>
                      <td className="py-2 border text-blue-500 underline">
                        {data.product_name}
                      </td>
                      <td className="py-2 border">{data.batch_number}</td>
                      <td className="py-2 border">{data.expired_date}</td>
                      <td className="py-2 border">
                        {data.quantity} {data.type_packaging}
                      </td>
                      <td className="py-2 border">
                        {data.pharmacy_net_price.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </td>
                      <td className="py-2 border">{data.invoice_number}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
            ) : null}
            <div className="flex justify-end mt-3">
              <div>
                <p className="mr-10">Grand Total:</p>
                <p>
                  Rp.
                  {returPdfData.total_price.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
            </div>
            <div className="mt-[100px] flex justify-end mr-10">
              <div className="text-center">
                <p className="font-normal">{toDate(returPdfData.created_at)}</p>
                <p className="font-normal">Penanggung Jawab</p>
                <p className="mt-[100px]">{returPdfData.fullname}</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReturPrintPdf;
