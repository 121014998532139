import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Tabs,
  Tab,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Pagination,
  Autocomplete,
  CircularProgress,
  Collapse,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import {
  searchProductAdjustment,
  getSearchStockOpname,
  createStockOpnameForEndSession,
  getStockOpnameEndSession,
} from "../../../../services/InventoryAPI";
import useDebounce from "../../../hooks/useDebounce";
import { IGNORE_TYPE } from "../../../constants/constant";

const toDate = (date) => moment(date).format("DD/MM/YY");
const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const FETCH_LIMIT = 10;

const CreateStockOpname = () => {
  const navigate = useNavigate();
  const { psId = "" } = useParams();
  const [value, setValue] = useState(1);
  const [search, setSearch] = useState("");
  const [productId, setProductId] = useState(0);
  const [dataBuffer, setDataBuffer] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [offset, setOffset] = useState(1);
  const [productName, setProductName] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [optionSearchBuffer, setOptionSearchBuffer] = useState([]);
  const [optionSearchValue, setOptionSearchValue] = useState([]);
  const [openOption, setOpenOption] = useState(false);

  const debounce = useDebounce(search, 500);
  const debounceProduct = useDebounce(productName, 500);
  const debounceUser = useDebounce(employeeName, 500);

  const { data: searchStockOpnameData } = useQuery({
    queryKey: ["search-so", productId],
    queryFn: () => getSearchStockOpname(productId),
    enabled: productId !== 0,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data && data !== undefined) {
        const uniqueProductId = [
          ...new Set(data.map((item) => item.product_id)),
        ];
        const uniqueProductName = [
          ...new Set(data.map((item) => item.product_name)),
        ];
        const uniquePackaging = [
          ...new Set(data.map((item) => item.packaging)),
        ];
        setDataBuffer((prevValue) =>
          prevValue.concat(
            uniqueProductId.map((item) => ({
              product_id: item,
              product_name: uniqueProductName[0],
              packaging: uniquePackaging[0],
              isOpen: false,
              buffer: data.map((data) => ({
                bufferId: data.product_new_id,
                ps_id: psId,
                product_new_id: data.product_new_id,
                product_id: data.product_id,
                product_name: data.product_name,
                batch: data.batch_number,
                stock: data.stock,
                actual_stock: 0,
                expired_date: data.expired_date,
                pharmacy_net_price: data.pharmacy_net_price,
                pharmacy_net_price_discount: data.pharmacy_net_price_discount,
                pharmacy_net_price_ppn: data.pharmacy_net_price_ppn,
                pharmacy_net_price_real: data.pharmacy_net_price_real,
                sell_price: data.sell_price,
                packaging: data.packaging,
                type_name: "",
                type_packaging: 0,
                isDisabled: true,
                batch_type: 2,
                stockBuffer: 0,
              })),
            })),
          ),
        );
      } else {
        setDataBuffer([]);
      }
    },
  });

  const { data: searchProductData, isFetching: isSearchProductLoading } =
    useQuery({
      queryKey: ["search-product", debounce],
      queryFn: () => searchProductAdjustment(debounce),
    });

  const { mutate: mutateStockOpnameEndSession } = useMutation({
    mutationFn: createStockOpnameForEndSession,
    onSuccess: () => navigate(`/inventory/stock-opname-recap/${psId}`),
  });

  const {
    data: stockOpnameEndSession,
    isLoading: isStockOpnameEndSessionLoading,
  } = useQuery({
    queryKey: ["so-end-session", offset, psId, debounceProduct, debounceUser],
    queryFn: () =>
      getStockOpnameEndSession(
        psId,
        debounceProduct,
        debounceUser,
        offset,
        FETCH_LIMIT,
      ),
    enabled: psId !== "",
  });

  const pageCount = useMemo(() => {
    if (stockOpnameEndSession && stockOpnameEndSession !== undefined)
      return Math.ceil(stockOpnameEndSession.count / FETCH_LIMIT);
    else return 0;
  }, [stockOpnameEndSession]);

  useEffect(() => {
    if (openOption)
      setOptionSearchBuffer(
        searchProductData && searchProductData !== null
          ? searchProductData
          : [],
      );
  }, [openOption, searchProductData]);

  return (
    <div>
      <div className="flex text-[#007AF1] font-medium">
        <p
          className="hover:underline hover:cursor-pointer"
          onClick={() => navigate("/inventory/stock-opname")}
        >
          Stock Opname
        </p>
        <p className="mx-2">/</p>
        <p>Create Stock Opname</p>
      </div>
      <div className="flex justify-between">
        <Tabs
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
          aria-label="basic tabs example"
        >
          <Tab label="Stock Opname" value={1} />
          <Tab label="Riwayat" value={2} />
        </Tabs>
        {dataBuffer.length > 0 ? (
          <div className="flex flex-row justify-end font-bold">
            <button
              className="bg-gray-200 rounded-md p-2 border-2 border-black mr-2"
              onClick={() => navigate("/inventory/stock-opname")}
            >
              Batalkan
            </button>
            <button
              className="bg-blue-500 rounded-md p-2 text-white"
              onClick={() => {
                if (dataBuffer.map((data) => data.type_name).includes("")) {
                  setErrorState(true);
                } else {
                  mutateStockOpnameEndSession(
                    dataBuffer
                      .map((item) =>
                        item.buffer.map((item) => ({
                          ps_id: item.ps_id,
                          product_new_id: item.product_new_id,
                          product_id: item.product_id,
                          product_name: item.product_name,
                          batch: item.batch,
                          stock: item.stock,
                          actual_stock: item.actual_stock,
                          expired_date: item.expired_date,
                          pharmacy_net_price: item.pharmacy_net_price,
                          pharmacy_net_price_discount: item.pharmacy_net_price_discount,
                          pharmacy_net_price_ppn: item.pharmacy_net_price_ppn,
                          pharmacy_net_price_real: item.pharmacy_net_price_real,
                          sell_price: item.sell_price,
                          type_name: item.type_name,
                          type_packaging: item.type_packaging,
                          batch_type: item.batch_type,
                        })),
                      )
                      .flat(),
                  );
                  setErrorState(false);
                }
              }}
            >
              Konfirmasi
            </button>
          </div>
        ) : null}
      </div>
      <div hidden={value === 2}>
        <Autocomplete
          fullWidth
          loading={isSearchProductLoading}
          onOpen={() => {
            setOpenOption(true);
            setOptionSearchValue("");
          }}
          onClose={() => setOpenOption(false)}
          disablePortal
          options={optionSearchBuffer}
          value={optionSearchValue}
          onChange={(_, inputValue) => {
            if (dataBuffer.some((data) => data.product_id === inputValue?.id)) {
              return;
            }
            setOptionSearchValue(inputValue);
            setProductId(inputValue !== null ? inputValue.id : 0);
            setOptionSearchBuffer([]);
          }}
          onInputChange={(_, onInputValue, reason) => {
            if (reason === "input") {
              setSearch(onInputValue);
              setOptionSearchBuffer([]);
            }
          }}
          getOptionLabel={(option) => option.product_name || ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.product_name}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              sx={{ marginTop: 3 }}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isSearchProductLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        {dataBuffer && dataBuffer !== undefined ? (
          <div className="mt-3">
            <div>
              {dataBuffer
                .filter((data) => data.product_id)
                .map((item, index) => (
                  <div
                    className="w-full border border-black my-3 rounded-md"
                    key={index}
                  >
                    <div className="flex p-2 justify-between items-center">
                      <div>
                        <p className="font-bold text-[20px]">
                          {item.product_name}
                        </p>
                        <p>Kemasan</p>
                        {item.packaging !== null ? (
                          <div className="flex flex-row">
                            <p>{item.packaging.primary_total}&nbsp;</p>
                            <p>{item.packaging.primary_type},&nbsp;</p>
                            <p>{item.packaging.secondary_total}&nbsp;</p>
                            <p>{item.packaging.secondary_type},&nbsp;</p>
                            <p>{item.packaging.terniary_total}&nbsp;</p>
                            <p>{item.packaging.terniary_type}&nbsp;</p>
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <button
                          className="bg-red-500 p-2 rounded-md text-white font-bold mr-2"
                          onClick={() => {
                            setDataBuffer((prevValue) =>
                              prevValue.filter(
                                (value) => value.product_id !== item.product_id,
                              ),
                            );
                          }}
                        >
                          Hapus
                        </button>
                        <button
                          className="bg-blue-500 p-2 rounded-md text-white font-bold"
                          onClick={() => {
                            setDataBuffer((prevValue) =>
                              prevValue.map((value) => {
                                return value.product_id === item.product_id
                                  ? {
                                      ...value,
                                      isOpen: !value.isOpen,
                                    }
                                  : value;
                              }),
                            );
                          }}
                        >
                          {item.isOpen ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </button>
                      </div>
                    </div>

                    {item.buffer && item.buffer !== undefined ? (
                      <Collapse
                        in={item.isOpen}
                        unmountOnExit
                        sx={{ fontWeight: "bold", padding: 2 }}
                      >
                        {item.buffer
                          .filter((data) => data.product_id === item.product_id)
                          .map((data, idx) => (
                            <div key={idx}>
                              <div className="flex flex-row mt-3" key={idx}>
                                <TextField
                                  label="No. Batch"
                                  sx={{ width: "100%" }}
                                  value={data.batch}
                                  onChange={(e) => {
                                    setDataBuffer((prevValue) =>
                                      prevValue.map((item) => ({
                                        ...item,
                                        buffer: item.buffer.map((child) => {
                                          return child.bufferId ===
                                            data.bufferId
                                            ? {
                                                ...child,
                                                batch: e.target.value,
                                              }
                                            : child;
                                        }),
                                      })),
                                    );
                                  }}
                                  //disabled={data.isDisabled}
                                />
                                <div className="mx-3 flex flex-row w-full">
                                  <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                  >
                                    <DesktopDatePicker
                                      label="Expired Date"
                                      value={data.expired_date}
                                      disabled={data.isDisabled}
                                      disablePast
                                      onChange={(newExpiredDate) => {
                                        setDataBuffer((prevValue) =>
                                          prevValue.map((item) => ({
                                            ...item,
                                            buffer: item.buffer.map((child) => {
                                              return child.bufferId ===
                                                data.bufferId
                                                ? {
                                                    ...child,
                                                    expired_date:
                                                      toDateApi(newExpiredDate),
                                                  }
                                                : child;
                                            }),
                                          })),
                                        );
                                      }}
                                      renderInput={(params) => {
                                        return <TextField {...params} />;
                                      }}
                                    />
                                  </LocalizationProvider>
                                  <TextField
                                    disabled={data.isDisabled}
                                    label="Current Stock"
                                    sx={{ width: "100%", marginLeft: 2 }}
                                    value={data.stock}
                                    onChange={(e) => {
                                      let numericValue = e.target.value;
                                      if (numericValue === "") {
                                        numericValue = "0";
                                      }
                                      setDataBuffer((prevValue) =>
                                        prevValue.map((item) => ({
                                          ...item,
                                          buffer: item.buffer.map((child) => {
                                            return child.bufferId ===
                                              data.bufferId
                                              ? {
                                                  ...child,
                                                  stock: parseInt(
                                                    numericValue.replace(
                                                      /[^0-9]/g,
                                                      "",
                                                    ),
                                                    10,
                                                  ),
                                                }
                                              : child;
                                          }),
                                        })),
                                      );
                                    }}
                                  />
                                </div>
                                <TextField
                                  value={data.stockBuffer}
                                  onChange={(e) => {
                                    let numericValue = e.target.value;
                                    if (numericValue === "") {
                                      numericValue = "0";
                                    }
                                    if (
                                      data.packaging?.terniary_type ===
                                      data.type_name
                                    ) {
                                      setDataBuffer((prevValue) =>
                                        prevValue.map((item) => ({
                                          ...item,
                                          buffer: item.buffer.map((child) => {
                                            return child.bufferId ===
                                              data.bufferId
                                              ? {
                                                  ...child,
                                                  actual_stock: parseInt(
                                                    numericValue.replace(
                                                      /[^0-9]/g,
                                                      "",
                                                    ),
                                                    10,
                                                  ),
                                                  stockBuffer: parseInt(
                                                    numericValue.replace(
                                                      /[^0-9]/g,
                                                      "",
                                                    ),
                                                    10,
                                                  ),
                                                }
                                              : child;
                                          }),
                                        })),
                                      );
                                    }
                                    if (
                                      data.packaging?.secondary_type ===
                                      data.type_name
                                    ) {
                                      if (
                                        IGNORE_TYPE.includes(
                                          data.packaging?.terniary_type,
                                        )
                                      ) {
                                        setDataBuffer((prevValue) =>
                                          prevValue.map((item) => ({
                                            ...item,
                                            buffer: item.buffer.map((child) => {
                                              return child.bufferId ===
                                                data.bufferId
                                                ? {
                                                    ...child,
                                                    actual_stock: parseInt(
                                                      numericValue.replace(
                                                        /[^0-9]/g,
                                                        "",
                                                      ),
                                                      10,
                                                    ),
                                                    stockBuffer: parseInt(
                                                      numericValue.replace(
                                                        /[^0-9]/g,
                                                        "",
                                                      ),
                                                      10,
                                                    ),
                                                  }
                                                : child;
                                            }),
                                          })),
                                        );
                                      } else {
                                        setDataBuffer((prevValue) =>
                                          prevValue.map((item) => ({
                                            ...item,
                                            buffer: item.buffer.map((child) => {
                                              return child.bufferId ===
                                                data.bufferId
                                                ? {
                                                    ...child,
                                                    actual_stock:
                                                      parseInt(
                                                        numericValue.replace(
                                                          /[^0-9]/g,
                                                          "",
                                                        ),
                                                        10,
                                                      ) *
                                                      data.packaging
                                                        ?.terniary_total,
                                                    stockBuffer: parseInt(
                                                      numericValue.replace(
                                                        /[^0-9]/g,
                                                        "",
                                                      ),
                                                      10,
                                                    ),
                                                  }
                                                : child;
                                            }),
                                          })),
                                        );
                                      }
                                    }
                                    if (
                                      data.type_name ===
                                      data.packaging?.primary_type
                                    ) {
                                      if (
                                        IGNORE_TYPE.includes(
                                          data.packaging?.terniary_type,
                                        )
                                      ) {
                                        setDataBuffer((prevValue) =>
                                          prevValue.map((item) => ({
                                            ...item,
                                            buffer: item.buffer.map((child) => {
                                              return child.bufferId ===
                                                data.bufferId
                                                ? {
                                                    ...child,
                                                    actual_stock:
                                                      parseInt(
                                                        numericValue.replace(
                                                          /[^0-9]/g,
                                                          "",
                                                        ),
                                                        10,
                                                      ) *
                                                      data.packaging
                                                        ?.secondary_total,
                                                    stockBuffer: parseInt(
                                                      numericValue.replace(
                                                        /[^0-9]/g,
                                                        "",
                                                      ),
                                                      10,
                                                    ),
                                                  }
                                                : child;
                                            }),
                                          })),
                                        );
                                      } else {
                                        setDataBuffer((prevValue) =>
                                          prevValue.map((item) => ({
                                            ...item,
                                            buffer: item.buffer.map((child) => {
                                              return child.bufferId ===
                                                data.bufferId
                                                ? {
                                                    ...child,
                                                    actual_stock:
                                                      parseInt(
                                                        numericValue.replace(
                                                          /[^0-9]/g,
                                                          "",
                                                        ),
                                                        10,
                                                      ) *
                                                      data.packaging
                                                        ?.secondary_total *
                                                      data.packaging
                                                        ?.terniary_total,
                                                    stockBuffer: parseInt(
                                                      numericValue.replace(
                                                        /[^0-9]/g,
                                                        "",
                                                      ),
                                                      10,
                                                    ),
                                                  }
                                                : child;
                                            }),
                                          })),
                                        );
                                      }
                                    }
                                  }}
                                  label="Stock on Hands"
                                  sx={{ width: "100%" }}
                                />
                                <div className="w-[40%] ml-2">
                                  {data.packaging &&
                                  data.packaging !== undefined ? (
                                    <FormControl
                                      sx={{ width: "100%" }}
                                      error={errorState}
                                    >
                                      <InputLabel>Packaging</InputLabel>
                                      <Select
                                        value={data.type_name}
                                        label="Packaging"
                                        //onChange={(e) => {
                                        // setDataBuffer((prevValue) =>
                                        //   prevValue.map((item) => ({
                                        //     ...item,
                                        //     buffer: item.buffer.map((child) => {
                                        //       return child.product_new_id ===
                                        //         item.product_new_id
                                        //         ? {
                                        //             ...child,
                                        //             type_name: e.target.value,
                                        //           }
                                        //         : child;
                                        //     }),
                                        //   })),
                                        // );
                                        // setDataBuffer((prevValue) =>
                                        //   prevValue.map((item, idx) => {
                                        //     return index === idx
                                        //       ? {
                                        //           ...item,
                                        //           type_name: e.target.value,
                                        //         }
                                        //       : item;
                                        //   }),
                                        // );
                                        //   setErrorState(false);
                                        // }}
                                      >
                                        <MenuItem
                                          value={data.packaging.primary_type}
                                          onClick={() => {
                                            if (
                                              IGNORE_TYPE.includes(
                                                data.packaging.terniary_type,
                                              )
                                            ) {
                                              setDataBuffer((prevValue) =>
                                                prevValue.map((item) => ({
                                                  ...item,
                                                  buffer: item.buffer.map(
                                                    (child) => {
                                                      return child.bufferId ===
                                                        data.bufferId
                                                        ? {
                                                            ...child,
                                                            type_name:
                                                              data.packaging
                                                                .primary_type,
                                                            type_packaging: 1,
                                                            type_package:
                                                              data.packaging
                                                                .primary_total,
                                                            actual_stock:
                                                              data.stockBuffer *
                                                              data.packaging
                                                                .secondary_total,
                                                          }
                                                        : child;
                                                    },
                                                  ),
                                                })),
                                              );
                                            } else {
                                              setDataBuffer((prevValue) =>
                                                prevValue.map((item) => ({
                                                  ...item,
                                                  buffer: item.buffer.map(
                                                    (child) => {
                                                      return child.bufferId ===
                                                        data.bufferId
                                                        ? {
                                                            ...child,
                                                            type_name:
                                                              data.packaging
                                                                .primary_type,
                                                            type_packaging: 1,
                                                            type_package:
                                                              data.packaging
                                                                .primary_total,
                                                            actual_stock:
                                                              data.stockBuffer *
                                                              data.packaging
                                                                .secondary_total *
                                                              data.packaging
                                                                .terniary_total,
                                                          }
                                                        : child;
                                                    },
                                                  ),
                                                })),
                                              );
                                            }
                                          }}
                                        >
                                          {data.packaging.primary_type}
                                        </MenuItem>
                                        <MenuItem
                                          value={data.packaging.secondary_type}
                                          onClick={() => {
                                            if (
                                              IGNORE_TYPE.includes(
                                                data.packaging.terniary_type,
                                              )
                                            ) {
                                              setDataBuffer((prevValue) =>
                                                prevValue.map((item) => ({
                                                  ...item,
                                                  buffer: item.buffer.map(
                                                    (child) => {
                                                      return child.bufferId ===
                                                        data.bufferId
                                                        ? {
                                                            ...child,
                                                            type_name:
                                                              data.packaging
                                                                .secondary_type,
                                                            type_packaging: 2,
                                                            type_package:
                                                              data.packaging
                                                                .secondary_total,
                                                            actual_stock:
                                                              data.stockBuffer,
                                                          }
                                                        : child;
                                                    },
                                                  ),
                                                })),
                                              );
                                            } else {
                                              setDataBuffer((prevValue) =>
                                                prevValue.map((item) => ({
                                                  ...item,
                                                  buffer: item.buffer.map(
                                                    (child) => {
                                                      return child.bufferId ===
                                                        data.bufferId
                                                        ? {
                                                            ...child,
                                                            type_name:
                                                              data.packaging
                                                                .secondary_type,
                                                            type_packaging: 2,
                                                            type_package:
                                                              data.packaging
                                                                .secondary_total,
                                                            actual_stock:
                                                              data.stockBuffer *
                                                              data.packaging
                                                                .terniary_total,
                                                          }
                                                        : child;
                                                    },
                                                  ),
                                                })),
                                              );
                                            }
                                          }}
                                        >
                                          {data.packaging.secondary_type}
                                        </MenuItem>
                                        {!IGNORE_TYPE.includes(
                                          data.packaging.terniary_type,
                                        ) ? (
                                          <MenuItem
                                            value={data.packaging.terniary_type}
                                            onClick={() => {
                                              setDataBuffer((prevValue) =>
                                                prevValue.map((item) => ({
                                                  ...item,
                                                  buffer: item.buffer.map(
                                                    (child) => {
                                                      return child.bufferId ===
                                                        data.bufferId
                                                        ? {
                                                            ...child,
                                                            type_name:
                                                              data.packaging
                                                                .terniary_type,
                                                            type_packaging: 3,
                                                            type_package:
                                                              data.packaging
                                                                .terniary_total,
                                                            actual_stock:
                                                              data.stockBuffer,
                                                          }
                                                        : child;
                                                    },
                                                  ),
                                                })),
                                              );
                                            }}
                                          >
                                            {data.packaging.terniary_type}
                                          </MenuItem>
                                        ) : null}
                                        <MenuItem
                                          disabled
                                          sx={{ display: "none" }}
                                          value=""
                                        ></MenuItem>
                                      </Select>
                                    </FormControl>
                                  ) : null}
                                  {errorState ? (
                                    <FormHelperText sx={{ color: "#D32F2F" }}>
                                      Salah satu kemasan belum diisi
                                    </FormHelperText>
                                  ) : null}
                                </div>
                                {item.buffer.length > 1 ? (
                                  <div className="flex items-center">
                                    <button
                                      onClick={() => {
                                        setDataBuffer((prevValue) =>
                                          prevValue.map((item) => ({
                                            ...item,
                                            buffer: item.buffer.filter(
                                              (value) =>
                                                value.bufferId !==
                                                data.bufferId,
                                            ),
                                          })),
                                        );
                                      }}
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ))}
                        <p
                          className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                          onClick={() => {
                            setDataBuffer((prevValue) =>
                              prevValue.map((value) => {
                                return value.product_id === item.product_id
                                  ? {
                                      ...value,
                                      buffer: [
                                        ...value.buffer,
                                        {
                                          ps_id: psId,
                                          bufferId:
                                            Math.max(
                                              ...value.buffer.map(
                                                (x) => x.bufferId,
                                              ),
                                            ) + 1,
                                          product_id:
                                            value.buffer[0]?.product_id,
                                          product_new_id:
                                            value.buffer[0].product_new_id,
                                          product_name:
                                            value.buffer[0]?.product_name,
                                          batch: "",
                                          stock: 0,
                                          pharmacy_net_price:
                                            value.buffer[0]?.pharmacy_net_price,

                                          pharmacy_net_price_discount:
                                            value.buffer[0]
                                              ?.pharmacy_net_price_discount,
                                          pharmacy_net_price_ppn:
                                            value.buffer[0]
                                              ?.pharmacy_net_price_ppn,
                                          pharmacy_net_price_real:
                                            value.buffer[0]
                                              ?.pharmacy_net_price_real,
                                          sell_price:
                                            value.buffer[0]?.sell_price,
                                          actual_stock: 0,
                                          expired_date: null,
                                          packaging: value.buffer[0]?.packaging,
                                          type_name: "",
                                          type_package: 0,
                                          isDisabled: false,
                                          batch_type: 1,
                                          stockBuffer: 0,
                                        },
                                      ],
                                    }
                                  : value;
                              }),
                            );
                          }}
                        >
                          +Tambah Batch lain
                        </p>
                      </Collapse>
                    ) : null}
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </div>
      <div hidden={value === 1}>
        <div className="mt-3">
          {stockOpnameEndSession &&
          stockOpnameEndSession !== undefined &&
          stockOpnameEndSession?.data !== null ? (
            <div>
              <TableContainer component={Paper} sx={{ width: "100%" }}>
                <Table aria-label="simple table">
                  <TableHead
                    sx={{
                      backgroundColor: "#007AF1",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Tanggal Penyesuaian
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Nama Produk
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Total Stock Awal
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Total Stock Sebenarnya
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        User
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stockOpnameEndSession.data.map((data, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {toDate(data.created_at)}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#007AF1" }}>
                          <p className="hover:cursor-pointer hover:underline">
                            {data.product_name}
                          </p>
                        </TableCell>
                        <TableCell align="center">{data.total_stock}</TableCell>
                        <TableCell align="center">
                          {data.total_stock_actual}
                        </TableCell>
                        <TableCell align="center">{data.created_by}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="flex justify-center mt-[10px]">
                <Pagination
                  count={pageCount}
                  page={offset}
                  onChange={(_, value) => setOffset(value)}
                  shape="rounded"
                  size="large"
                  color="primary"
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-center w-full mt-3">
              <p>Tidak ditemukan</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateStockOpname;
