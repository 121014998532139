import { useState, useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Dialog,
  DialogContent,
  CircularProgress,
  Pagination,
  TextField,
} from "@mui/material";
import moment from "moment";

import {
  getStockOpnameEndSession,
  getStockOpnameEndSessionDetail,
  createStockHistory,
} from "../../../../services/InventoryAPI";

import DetailStockOpnameModal from "./DetailStockopnameModal";
import useDebounce from "../../../hooks/useDebounce";

const toDate = (date) => moment(date).format("DD/MM/YY");

const FETCH_LIMIT = 10;

const StockOpnameRecap = () => {
  const navigate = useNavigate();
  const { psId = "" } = useParams();
  const [productName, setProductName] = useState("");
  const [offset, setOffset] = useState(1);
  const [employeeName, setEmployeeName] = useState("");
  const [productId, setProductId] = useState(0);
  const [openEndSessionDetailModal, setOpenEndSessionDetailModal] =
    useState(false);

  const debounceProduct = useDebounce(productName, 500);
  const debounceUser = useDebounce(employeeName, 500);

  const {
    data: stockOpnameEndSession,
    isLoading: isStockOpnameEndSessionLoading,
  } = useQuery({
    queryKey: ["so-end-session", offset, psId, debounceProduct, debounceUser],
    queryFn: () =>
      getStockOpnameEndSession(
        psId,
        debounceProduct,
        debounceUser,
        offset,
        FETCH_LIMIT,
      ),
  });

  const {
    data: stockOpnameEndSessionDetail,
    isLoading: isStockOpnameDetailLoading,
  } = useQuery({
    queryKey: ["stock-opname-detail", productId, psId],
    queryFn: () => getStockOpnameEndSessionDetail(productId, psId),
    enabled: productId !== 0,
  });

  const { mutate: mutateHistoryStock } = useMutation({
    mutationFn: createStockHistory,
    onSuccess: () => navigate("/inventory/stock-opname"),
  });

  const pageCount = useMemo(() => {
    if (stockOpnameEndSession && stockOpnameEndSession !== undefined)
      return Math.ceil(stockOpnameEndSession.count / FETCH_LIMIT);
    else return 0;
  }, [stockOpnameEndSession]);

  return (
    <div>
      {stockOpnameEndSession && stockOpnameEndSession !== undefined ? (
        <>
          <div className="flex justify-between items-center">
            <div className="flex text-[#007AF1] font-medium">
              <p
                className="hover:underline hover:cursor-pointer"
                onClick={() => navigate("/inventory/produk-aktif")}
              >
                Inventory
              </p>
              <p className="mx-2">/</p>
              <p className="hover:underline hover:cursor-pointer">
                Penyesuaian Stock
              </p>
            </div>

            <div>
              {stockOpnameEndSession.data !== null &&
              stockOpnameEndSession.data[0]?.status !== 1 &&
              !stockOpnameEndSession.is_title_id_false ? (
                <button
                  className="bg-red-500 font-bold py-2 px-4 rounded-md text-white"
                  onClick={() => mutateHistoryStock(psId)}
                >
                  Akhiri Sesi
                </button>
              ) : null}
            </div>
          </div>
          <div className="flex w-full my-3">
            <TextField
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              label="Nama Produk"
              sx={{ width: "100%", marginRight: 3 }}
            />
            <TextField
              value={employeeName}
              onChange={(e) => setEmployeeName(e.target.value)}
              label="Nama User"
              sx={{ width: "100%" }}
            />
          </div>
          {isStockOpnameEndSessionLoading ? (
            <div className="flex justify-center items-center h-screen">
              <CircularProgress />
            </div>
          ) : (
            <>
              {stockOpnameEndSession &&
              stockOpnameEndSession !== undefined &&
              stockOpnameEndSession?.data !== null ? (
                <div>
                  <TableContainer component={Paper} sx={{ width: "100%" }}>
                    <Table aria-label="simple table">
                      <TableHead
                        sx={{
                          backgroundColor: "#007AF1",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: "white", fontWeight: "bold" }}
                            align="center"
                          >
                            Tanggal Penyesuaian
                          </TableCell>
                          <TableCell
                            sx={{ color: "white", fontWeight: "bold" }}
                            align="center"
                          >
                            Nama Produk
                          </TableCell>
                          <TableCell
                            sx={{ color: "white", fontWeight: "bold" }}
                            align="center"
                          >
                            Total Stock Awal
                          </TableCell>
                          <TableCell
                            sx={{ color: "white", fontWeight: "bold" }}
                            align="center"
                          >
                            Total Stock Sebenarnya
                          </TableCell>
                          <TableCell
                            sx={{ color: "white", fontWeight: "bold" }}
                            align="center"
                          >
                            User
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stockOpnameEndSession.data?.map((data, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">
                              {toDate(data.created_at)}
                            </TableCell>
                            <TableCell align="center" sx={{ color: "#007AF1" }}>
                              <p
                                className="hover:cursor-pointer hover:underline"
                                onClick={() => {
                                  setProductId(data.product_id);
                                  setOpenEndSessionDetailModal(true);
                                }}
                              >
                                {data.product_name}
                              </p>
                            </TableCell>
                            <TableCell align="center">
                              {data.total_stock}
                            </TableCell>
                            <TableCell align="center">
                              {data.total_stock_actual}
                            </TableCell>
                            <TableCell align="center">
                              {data.created_by}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="flex justify-center mt-[10px]">
                    <Pagination
                      count={pageCount}
                      page={offset}
                      onChange={(_, value) => setOffset(value)}
                      shape="rounded"
                      size="large"
                      color="primary"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex justify-center w-full mt-3">
                  <p>Tidak ditemukan</p>
                </div>
              )}
            </>
          )}
          <Dialog
            open={openEndSessionDetailModal}
            onClose={() => setOpenEndSessionDetailModal(false)}
          >
            <DialogContent>
              <DetailStockOpnameModal
                stockOpnameEndSessionDetail={stockOpnameEndSessionDetail}
              />
            </DialogContent>
          </Dialog>
        </>
      ) : null}
    </div>
  );
};

export default StockOpnameRecap;
